export const data = [
    [
      {
        id: 1,
        question: " The first Nigerian Airforce Inspector General of the Police Force was ",
        options: [" Teslim Balogun ", " Louis Edet ", " Ademola Adetokunbo ", ,],
      },
  
      {
        id: 2,
        question:
          " In order to qualify for the post of President in Nigeria, the person must be _____ and above ",
        options: ["35 years", "20 years", "40 years", ,],
      },
  
      {
        id: 3,
        question: " The name Nigeria coined out of _______",
        options: ["Niger Forest", "Niger Area", "Niger River"],
      },
  
      {
        id: 4,
        question: " The last colonial Governor-General of Nigeria was ",
        options: ["Tulma Goldie", "James Robertson", "Huge Clifford"],
      },
  
      {
        id: 5,
        question: " The First World War was between",
        options: ["1911-1914", "1914-1916", "1914-1918"],
      },
  
      {
        id: 6,
        question: " The first African country to introduce e-passport was",
        options: ["South Africa", "Ghana", "Nigeria"],
      },
  
      {
        id: 7,
        question: " The first secretary General of commonwealth was",
        options: ["George Washington", "Tulma Goldie", "Joseph Garba"],
      },
  
      {
        id: 8,
        question: " The first military president of Nigeria was",
        options: ["Sanni Abacha", "Ibrahim Babangida", "Yakubu Gowon"],
      },
  
      {
        id: 9,
        question:
          " Which of the following may be regarded as a regional organization",
        options: ["ECOWAS", "OAU", "UN"],
      },
  
      {
        id: 10,
        question: " What is the full meaning of NPF?",
        options: [
          "Nigeria Police Force",
          "Niger Police Force",
          "Nigeria Police ",
      ],
      },
  
      {
        id: 11,
        question: " What is the Nigeria police force motto?",
        options: [
          " The police is your friend.",
          " The police is your friend.",
          " The police is your friend.",
      ],
      },
  
      {
        id: 12,
        question: " When was Nigeria Police Force(NPF) established?",
        options: [
          "1930",
          "1930",
          "1930",
      ],
      },
  
      {
        id: 13,
        question: " Who is the present Chief of Defence Staff?",
        options: [
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
      ],
      },
  
      {
        id: 14,
        question: " What is the full Meaning of IGP?",
        options: [
          "Inspector General of Police",
          "Inspector General of Police",
          "Inspector General of Police",
      ],
      },
  
      {
        id: 15,
        question: " What is the name of Nigeria Police Force IGP?",
        options: [
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
      ],
      },
  
      {
        id: 16,
        question: " When did Nigeria got her independence?",
        options: [
          "1 October 1960",
          "1 October 1960",
          "1 October 1960",
      ],
      },
  
      {
        id: 17,
        question:
          " Who is the current Speaker of Nigeria House of Representatives?",
        options: [
          " Tajudeen Abbas",
          " Tajudeen Abbas",
          " Tajudeen Abbas",
      ],
      },
  
      {
        id: 18,
        question:
          " Which year was the Nigerian flag designed and who designed it?",
        options: [
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
        ],
      },
  
      {
        id: 19,
        question: " Who is the first Executive president of Nigeria?",
        options: [
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
      ],
      },
  
      {
        id: 20,
        question:
          " Name the government agencies that supervise the affairs of Nigeria police force?",
        options: [
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
      ],
      },
  
      {
        id: 21,
        question: "Name the 7 area Commands of Nigeria police force?",
        options: [
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
      ],
      },
  
      {
        id: 22,
        question: " When did Nigeria become a Republic?",
        options: [
          "1 October 1963.",
          "1 October 1963.",
          "1 October 1963.",
      ],
      },
      {
        id: 23,
        question: " The first secretary General of common wealth was",
        options: [
          "George Washington",
          "Tulma Goldie",
          "Arnold smith",
       
        ],
      },
  
      {
        id: 24,
        question:
          "A formal accusation by a grand jury that charges a person with a crime is known as……",
        options: [
     
          " Insanity plea  ",
          "Indictment  ",
          "   Faux Pas.",
        ],
      },
      {
        id: 25,
        question:
          "The police officer-in-charge of contingents in a state is the…",
        options: [
          "Chief Superintendent of Police",
      
          "Commissioner of Police",
          "Divisional Police officer",
        ],
      },
  
      {
        id: 26,
        question:
          "Which of the following constabularies did not participate in the formation of the Nigerian police?",
        options: [
          "Niger Coast Constabulary",
          "Special Service Constabulary",
          "Royal Niger Company Constabulary",
       
        ],
      },
  
      {
        id: 27,
        question:
          "List of officers fit for appointment to the office of the Inspector General of Police is compiled by the",
        options: [
          "Police Council",
          "Police Corporative",
       
          "Police Authority",
        ],
      },
  
      {
        id: 28,
        question: "Katsina Ala is in which state of Nigeria?",
  
        options: [
          "Cross Rivers State",
          "Benue State",
        
          "Kogi State",
        ],
      },
  
      {
        id: 29,
        question: "What is the length of tenure of the IGP?",
        options: ["3yrs", "4yrs", "5yrs"],
      },
  
      {
        id: 30,
        question:
          "Which of these is not part of the formation list of the Nigerian police?",
        options: [
          "Port Authority Police",
          "Police Cooperative",
          
          "Airforce",
        ],
      },
  
      {
        id: 31,
        question: "How many units does the Nigerian police have?",
        options: [ " 5", " 6", " 7"],
      },
  
      {
        id: 32,
        question:
          "The position to the office of the Inspector General of police is done through",
        options: [
      
          " Nomination",
          " Appointment",
          " Academic Qualification",
        ],
      },
  
      {
        id: 33,
        question: "Find the odd one out",
        options: [
          "Hafiz Ringim",
          "Abubakar Zikman",
          "Suleiman Abba",
     
        ],
      },
  
      {
        id: 34,
        question: "'Who is the first inspector general of police in Nigeria?",
        options: [
          "Kam Salem",
          "Philip Effiong",
          "Muhammadu Dikko Yusufu",
   
        ],
      },
      {
        id: 35,
        question: "cool The first military president of Nigeria was",
        options: [
          " Sanni Abacha",
          " Ibrahim Babangida",
         
          " Yakubu Gowon.",
        ],
      },
      {
        id: 36,
        question: " The first African country to introduce e-passport was",
        options: ["South Africa", "Ghana", "Nigeria"],
      },
  
      {
        id: 37,
        question: " The first secretary General of commonwealth was",
        options: ["George Washington", "Tulma Goldie", "Joseph Garba"],
      },
  
      {
        id: 38,
        question: " The first military president of Nigeria was",
        options: ["Sanni Abacha", "Ibrahim Babangida", "Yakubu Gowon"],
      },
  
      {
        id: 39,
        question:
          " Which of the following may be regarded as a regional organization",
        options: ["ECOWAS", "OAU", "UN"],
      },
  
      {
        id: 40,
        question: " What is the full meaning of NPF?",
        options: [
          "Nigeria Police Force",
          "Niger Police Force",
          "Nigeria Police ",
      ],
      },
  
      {
        id:41,
        question: " What is the Nigeria police force motto?",
        options: [
          " The police is your friend.",
          " The police is your friend.",
          " The police is your friend.",
      ],
      },
  
      {
        id: 42,
        question: " When was Nigeria Police Force(NPF) established?",
        options: [
          "1930",
          "1930",
          "1930",
      ],
      },
  
      {
        id: 43,
        question: " Who is the present Chief of Defence Staff?",
        options: [
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
      ],
      },
  
      {
        id: 44,
        question: " What is the full Meaning of IGP?",
        options: [
          "Inspector General of Police",
          "Inspector General of Police",
          "Inspector General of Police",
      ],
      },
  
      {
        id: 45,
        question: " What is the name of Nigeria Police Force IGP?",
        options: [
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
      ],
      },
  
      {
        id: 46,
        question: " When did Nigeria got her independence?",
        options: [
          "1 October 1960",
          "1 October 1960",
          "1 October 1960",
      ],
      },
  
      {
        id: 47,
        question:
          " Who is the current Speaker of Nigeria House of Representatives?",
        options: [
          " Tajudeen Abbas",
          " Tajudeen Abbas",
          " Tajudeen Abbas",
      ],
      },
  
      {
        id: 48,
        question:
          " Which year was the Nigerian flag designed and who designed it?",
        options: [
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
        ],
      },
  
      {
        id: 49,
        question: " Who is the first Executive president of Nigeria?",
        options: [
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
      ],
      },
  
      {
        id: 50,
        question:
          " Name the government agencies that supervise the affairs of Nigeria police force?",
        options: [
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
      ],
      },
  
      {
        id:51,
        question: "Name the 7 area Commands of Nigeria police force?",
        options: [
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
      ],
      },
  
      {
        id: 52,
        question: " When did Nigeria become a Republic?",
        options: [
          "1 October 1963.",
          "1 October 1963.",
          "1 October 1963.",
      ],
      },
      {
        id: 53,
        question: " The first secretary General of common wealth was",
        options: [
          "George Washington",
          "Tulma Goldie",
          "Arnold smith",
       
        ],
      },
  
      {
        id: 54,
        question:
          "A formal accusation by a grand jury that charges a person with a crime is known as……",
        options: [
     
          " Insanity plea  ",
          "Indictment  ",
          "   Faux Pas.",
        ],
      },
      {
        id: 55,
        question:
          "The police officer-in-charge of contingents in a state is the…",
        options: [
          "Chief Superintendent of Police",
      
          "Commissioner of Police",
          "Divisional Police officer",
        ],
      },
  
      {
        id: 56,
        question:
          "Which of the following constabularies did not participate in the formation of the Nigerian police?",
        options: [
          "Niger Coast Constabulary",
          "Special Service Constabulary",
          "Royal Niger Company Constabulary",
       
        ],
      },
  
      {
        id: 57,
        question:
          "List of officers fit for appointment to the office of the Inspector General of Police is compiled by the",
        options: [
          "Police Council",
          "Police Corporative",
       
          "Police Authority",
        ],
      },
  
      {
        id: 58,
        question: "Katsina Ala is in which state of Nigeria?",
  
        options: [
          "Cross Rivers State",
          "Benue State",
        
          "Kogi State",
        ],
      },
  
      {
        id: 59,
        question: "What is the length of tenure of the IGP?",
        options: ["3yrs", "4yrs", "5yrs"],
      },
  
      {
        id: 60,
        question:
          "Which of these is not part of the formation list of the Nigerian police?",
        options: [
          "Port Authority Police",
          "Police Cooperative",
          
          "Airforce",
        ],
      }, 
    ],
    [
      {
        id: 1,
        question: " The first   General of the Nigeria Airforce Force was ",
        options: [" Teslim Balogun ", " Louis Edet ", " Ademola Adetokunbo ", ,],
      },
  
      {
        id: 2,
        question:
          " In order to qualify for the post of President in Nigeria, the person must be _____ and above ",
        options: ["35 years", "20 years", "40 years", ,],
      },
  
      {
        id: 3,
        question: " The name Nigeria coined out of _______",
        options: ["Niger Forest", "Niger Area", "Niger River"],
      },
  
      {
        id: 4,
        question: " The last colonial Governor-General of Nigeria was ",
        options: ["Tulma Goldie", "James Robertson", "Huge Clifford"],
      },
  
      {
        id: 5,
        question: " The First World War was between",
        options: ["1911-1914", "1914-1916", "1914-1918"],
      },
  
      {
        id: 6,
        question: " The first African country to introduce e-passport was",
        options: ["South Africa", "Ghana", "Nigeria"],
      },
  
      {
        id: 7,
        question: " The first secretary General of commonwealth was",
        options: ["George Washington", "Tulma Goldie", "Joseph Garba"],
      },
  
      {
        id: 8,
        question: " The first military president of Nigeria was",
        options: ["Sanni Abacha", "Ibrahim Babangida", "Yakubu Gowon"],
      },
  
      {
        id: 9,
        question:
          " Which of the following may be regarded as a regional organization",
        options: ["ECOWAS", "OAU", "UN"],
      },
  
      {
        id: 10,
        question: " What is the full meaning of NPF?",
        options: [
          "Nigeria Police Force",
          "Niger Police Force",
          "Nigeria Police ",
      ],
      },
  
      {
        id: 11,
        question: " What is the Nigeria police force motto?",
        options: [
          " The police is your friend.",
          " The police is your friend.",
          " The police is your friend.",
      ],
      },
  
      {
        id: 12,
        question: " When was Nigeria Police Force(NPF) established?",
        options: [
          "1930",
          "1930",
          "1930",
      ],
      },
  
      {
        id: 13,
        question: " Who is the present Chief of Defence Staff?",
        options: [
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
      ],
      },
  
      {
        id: 14,
        question: " What is the full Meaning of IGP?",
        options: [
          "Inspector General of Police",
          "Inspector General of Police",
          "Inspector General of Police",
      ],
      },
  
      {
        id: 15,
        question: " What is the name of Nigeria Police Force IGP?",
        options: [
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
      ],
      },
  
      {
        id: 16,
        question: " When did Nigeria got her independence?",
        options: [
          "1 October 1960",
          "1 October 1960",
          "1 October 1960",
      ],
      },
  
      {
        id: 17,
        question:
          " Who is the current Speaker of Nigeria House of Representatives?",
        options: [
          " Tajudeen Abbas",
          " Tajudeen Abbas",
          " Tajudeen Abbas",
      ],
      },
  
      {
        id: 18,
        question:
          " Which year was the Nigerian flag designed and who designed it?",
        options: [
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
        ],
      },
  
      {
        id: 19,
        question: " Who is the first Executive president of Nigeria?",
        options: [
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
      ],
      },
  
      {
        id: 20,
        question:
          " Name the government agencies that supervise the affairs of Nigeria police force?",
        options: [
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
      ],
      },
  
      {
        id: 21,
        question: "Name the 7 area Commands of Nigeria police force?",
        options: [
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
      ],
      },
  
      {
        id: 22,
        question: " When did Nigeria become a Republic?",
        options: [
          "1 October 1963.",
          "1 October 1963.",
          "1 October 1963.",
      ],
      },
      {
        id: 23,
        question: " The first secretary General of common wealth was",
        options: [
          "George Washington",
          "Tulma Goldie",
          "Arnold smith",
       
        ],
      },
  
      {
        id: 24,
        question:
          "A formal accusation by a grand jury that charges a person with a crime is known as……",
        options: [
     
          " Insanity plea  ",
          "Indictment  ",
          "   Faux Pas.",
        ],
      },
      {
        id: 25,
        question:
          "The police officer-in-charge of contingents in a state is the…",
        options: [
          "Chief Superintendent of Police",
      
          "Commissioner of Police",
          "Divisional Police officer",
        ],
      },
  
      {
        id: 26,
        question:
          "Which of the following constabularies did not participate in the formation of the Nigerian police?",
        options: [
          "Niger Coast Constabulary",
          "Special Service Constabulary",
          "Royal Niger Company Constabulary",
       
        ],
      },
  
      {
        id: 27,
        question:
          "List of officers fit for appointment to the office of the Inspector General of Police is compiled by the",
        options: [
          "Police Council",
          "Police Corporative",
       
          "Police Authority",
        ],
      },
  
      {
        id: 28,
        question: "Katsina Ala is in which state of Nigeria?",
  
        options: [
          "Cross Rivers State",
          "Benue State",
        
          "Kogi State",
        ],
      },
  
      {
        id: 29,
        question: "What is the length of tenure of the IGP?",
        options: ["3yrs", "4yrs", "5yrs"],
      },
  
      {
        id: 30,
        question:
          "Which of these is not part of the formation list of the Nigerian police?",
        options: [
          "Port Authority Police",
          "Police Cooperative",
          
          "Airforce",
        ],
      },
  
      {
        id: 31,
        question: "How many units does the Nigerian police have?",
        options: [ " 5", " 6", " 7"],
      },
  
      {
        id: 32,
        question:
          "The position to the office of the Inspector General of police is done through",
        options: [
      
          " Nomination",
          " Appointment",
          " Academic Qualification",
        ],
      },
  
      {
        id: 33,
        question: "Find the odd one out",
        options: [
          "Hafiz Ringim",
          "Abubakar Zikman",
          "Suleiman Abba",
     
        ],
      },
  
      {
        id: 34,
        question: "'Who is the first inspector general of police in Nigeria?",
        options: [
          "Kam Salem",
          "Philip Effiong",
          "Muhammadu Dikko Yusufu",
   
        ],
      },
      {
        id: 35,
        question: "cool The first military president of Nigeria was",
        options: [
          " Sanni Abacha",
          " Ibrahim Babangida",
         
          " Yakubu Gowon.",
        ],
      },
      {
        id: 36,
        question: " The first African country to introduce e-passport was",
        options: ["South Africa", "Ghana", "Nigeria"],
      },
  
      {
        id: 37,
        question: " The first secretary General of commonwealth was",
        options: ["George Washington", "Tulma Goldie", "Joseph Garba"],
      },
  
      {
        id: 38,
        question: " The first military president of Nigeria was",
        options: ["Sanni Abacha", "Ibrahim Babangida", "Yakubu Gowon"],
      },
  
      {
        id: 39,
        question:
          " Which of the following may be regarded as a regional organization",
        options: ["ECOWAS", "OAU", "UN"],
      },
  
      {
        id: 40,
        question: " What is the full meaning of NPF?",
        options: [
          "Nigeria Police Force",
          "Niger Police Force",
          "Nigeria Police ",
      ],
      },
  
      {
        id:41,
        question: " What is the Nigeria police force motto?",
        options: [
          " The police is your friend.",
          " The police is your friend.",
          " The police is your friend.",
      ],
      },
  
      {
        id: 42,
        question: " When was Nigeria Police Force(NPF) established?",
        options: [
          "1930",
          "1930",
          "1930",
      ],
      },
  
      {
        id: 43,
        question: " Who is the present Chief of Defence Staff?",
        options: [
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
      ],
      },
  
      {
        id: 44,
        question: " What is the full Meaning of IGP?",
        options: [
          "Inspector General of Police",
          "Inspector General of Police",
          "Inspector General of Police",
      ],
      },
  
      {
        id: 45,
        question: " What is the name of Nigeria Police Force IGP?",
        options: [
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
      ],
      },
  
      {
        id: 46,
        question: " When did Nigeria got her independence?",
        options: [
          "1 October 1960",
          "1 October 1960",
          "1 October 1960",
      ],
      },
  
      {
        id: 47,
        question:
          " Who is the current Speaker of Nigeria House of Representatives?",
        options: [
          " Tajudeen Abbas",
          " Tajudeen Abbas",
          " Tajudeen Abbas",
      ],
      },
  
      {
        id: 48,
        question:
          " Which year was the Nigerian flag designed and who designed it?",
        options: [
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
        ],
      },
  
      {
        id: 49,
        question: " Who is the first Executive president of Nigeria?",
        options: [
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
      ],
      },
  
      {
        id: 50,
        question:
          " Name the government agencies that supervise the affairs of Nigeria police force?",
        options: [
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
      ],
      },
  
      {
        id:51,
        question: "Name the 7 area Commands of Nigeria police force?",
        options: [
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
      ],
      },
  
      {
        id: 52,
        question: " When did Nigeria become a Republic?",
        options: [
          "1 October 1963.",
          "1 October 1963.",
          "1 October 1963.",
      ],
      },
      {
        id: 53,
        question: " The first secretary General of common wealth was",
        options: [
          "George Washington",
          "Tulma Goldie",
          "Arnold smith",
       
        ],
      },
  
      {
        id: 54,
        question:
          "A formal accusation by a grand jury that charges a person with a crime is known as……",
        options: [
     
          " Insanity plea  ",
          "Indictment  ",
          "   Faux Pas.",
        ],
      },
      {
        id: 55,
        question:
          "The police officer-in-charge of contingents in a state is the…",
        options: [
          "Chief Superintendent of Police",
      
          "Commissioner of Police",
          "Divisional Police officer",
        ],
      },
  
      {
        id: 56,
        question:
          "Which of the following constabularies did not participate in the formation of the Nigerian police?",
        options: [
          "Niger Coast Constabulary",
          "Special Service Constabulary",
          "Royal Niger Company Constabulary",
       
        ],
      },
  
      {
        id: 57,
        question:
          "List of officers fit for appointment to the office of the Inspector General of Police is compiled by the",
        options: [
          "Police Council",
          "Police Corporative",
       
          "Police Authority",
        ],
      },
  
      {
        id: 58,
        question: "Katsina Ala is in which state of Nigeria?",
  
        options: [
          "Cross Rivers State",
          "Benue State",
        
          "Kogi State",
        ],
      },
  
      {
        id: 59,
        question: "What is the length of tenure of the IGP?",
        options: ["3yrs", "4yrs", "5yrs"],
      },
  
      {
        id: 60,
        question:
          "Which of these is not part of the formation list of the Nigerian police?",
        options: [
          "Port Authority Police",
          "Police Cooperative", 
          "Airforce",
        ],
      }, 
    ],
    [
      {
        id: 1,
        question: " The first Nigeria Airforce was ",
        options: [" Teslim Balogun ", " Louis Edet ", " Ademola Adetokunbo ", ,],
      },
  
      {
        id: 2,
        question:
          " In order to qualify for the post of President in Nigeria, the person must be _____ and above ",
        options: ["35 years", "20 years", "40 years", ,],
      },
  
      {
        id: 3,
        question: " The name Nigeria coined out of _______",
        options: ["Niger Forest", "Niger Area", "Niger River"],
      },
  
      {
        id: 4,
        question: " The last colonial Governor-General of Nigeria was ",
        options: ["Tulma Goldie", "James Robertson", "Huge Clifford"],
      },
  
      {
        id: 5,
        question: " The First World War was between",
        options: ["1911-1914", "1914-1916", "1914-1918"],
      },
  
      {
        id: 6,
        question: " The first African country to introduce e-passport was",
        options: ["South Africa", "Ghana", "Nigeria"],
      },
  
      {
        id: 7,
        question: " The first secretary General of commonwealth was",
        options: ["George Washington", "Tulma Goldie", "Joseph Garba"],
      },
  
      {
        id: 8,
        question: " The first military president of Nigeria was",
        options: ["Sanni Abacha", "Ibrahim Babangida", "Yakubu Gowon"],
      },
  
      {
        id: 9,
        question:
          " Which of the following may be regarded as a regional organization",
        options: ["ECOWAS", "OAU", "UN"],
      },
  
      {
        id: 10,
        question: " What is the full meaning of NPF?",
        options: [
          "Nigeria Police Force",
          "Niger Police Force",
          "Nigeria Police ",
      ],
      },
  
      {
        id: 11,
        question: " What is the Nigeria police force motto?",
        options: [
          " The police is your friend.",
          " The police is your friend.",
          " The police is your friend.",
      ],
      },
  
      {
        id: 12,
        question: " When was Nigeria Police Force(NPF) established?",
        options: [
          "1930",
          "1930",
          "1930",
      ],
      },
  
      {
        id: 13,
        question: " Who is the present Chief of Defence Staff?",
        options: [
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
      ],
      },
  
      {
        id: 14,
        question: " What is the full Meaning of IGP?",
        options: [
          "Inspector General of Police",
          "Inspector General of Police",
          "Inspector General of Police",
      ],
      },
  
      {
        id: 15,
        question: " What is the name of Nigeria Police Force IGP?",
        options: [
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
      ],
      },
  
      {
        id: 16,
        question: " When did Nigeria got her independence?",
        options: [
          "1 October 1960",
          "1 October 1960",
          "1 October 1960",
      ],
      },
  
      {
        id: 17,
        question:
          " Who is the current Speaker of Nigeria House of Representatives?",
        options: [
          " Tajudeen Abbas",
          " Tajudeen Abbas",
          " Tajudeen Abbas",
      ],
      },
  
      {
        id: 18,
        question:
          " Which year was the Nigerian flag designed and who designed it?",
        options: [
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
        ],
      },
  
      {
        id: 19,
        question: " Who is the first Executive president of Nigeria?",
        options: [
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
      ],
      },
  
      {
        id: 20,
        question:
          " Name the government agencies that supervise the affairs of Nigeria police force?",
        options: [
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
      ],
      },
  
      {
        id: 21,
        question: "Name the 7 area Commands of Nigeria police force?",
        options: [
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
      ],
      },
  
      {
        id: 22,
        question: " When did Nigeria become a Republic?",
        options: [
          "1 October 1963.",
          "1 October 1963.",
          "1 October 1963.",
      ],
      },
      {
        id: 23,
        question: " The first secretary General of common wealth was",
        options: [
          "George Washington",
          "Tulma Goldie",
          "Arnold smith",
       
        ],
      },
  
      {
        id: 24,
        question:
          "A formal accusation by a grand jury that charges a person with a crime is known as……",
        options: [
     
          " Insanity plea  ",
          "Indictment  ",
          "   Faux Pas.",
        ],
      },
      {
        id: 25,
        question:
          "The police officer-in-charge of contingents in a state is the…",
        options: [
          "Chief Superintendent of Police",
      
          "Commissioner of Police",
          "Divisional Police officer",
        ],
      },
  
      {
        id: 26,
        question:
          "Which of the following constabularies did not participate in the formation of the Nigerian police?",
        options: [
          "Niger Coast Constabulary",
          "Special Service Constabulary",
          "Royal Niger Company Constabulary",
       
        ],
      },
  
      {
        id: 27,
        question:
          "List of officers fit for appointment to the office of the Inspector General of Police is compiled by the",
        options: [
          "Police Council",
          "Police Corporative",
       
          "Police Authority",
        ],
      },
  
      {
        id: 28,
        question: "Katsina Ala is in which state of Nigeria?",
  
        options: [
          "Cross Rivers State",
          "Benue State",
        
          "Kogi State",
        ],
      },
  
      {
        id: 29,
        question: "What is the length of tenure of the IGP?",
        options: ["3yrs", "4yrs", "5yrs"],
      },
  
      {
        id: 30,
        question:
          "Which of these is not part of the formation list of the Nigerian police?",
        options: [
          "Port Authority Police",
          "Police Cooperative",
          
          "Airforce",
        ],
      },
  
      {
        id: 31,
        question: "How many units does the Nigerian police have?",
        options: [ " 5", " 6", " 7"],
      },
  
      {
        id: 32,
        question:
          "The position to the office of the Inspector General of police is done through",
        options: [
      
          " Nomination",
          " Appointment",
          " Academic Qualification",
        ],
      },
  
      {
        id: 33,
        question: "Find the odd one out",
        options: [
          "Hafiz Ringim",
          "Abubakar Zikman",
          "Suleiman Abba",
     
        ],
      },
  
      {
        id: 34,
        question: "'Who is the first inspector general of police in Nigeria?",
        options: [
          "Kam Salem",
          "Philip Effiong",
          "Muhammadu Dikko Yusufu",
   
        ],
      },
      {
        id: 35,
        question: "cool The first military president of Nigeria was",
        options: [
          " Sanni Abacha",
          " Ibrahim Babangida",
         
          " Yakubu Gowon.",
        ],
      },
      {
        id: 36,
        question: " The first African country to introduce e-passport was",
        options: ["South Africa", "Ghana", "Nigeria"],
      },
  
      {
        id: 37,
        question: " The first secretary General of commonwealth was",
        options: ["George Washington", "Tulma Goldie", "Joseph Garba"],
      },
  
      {
        id: 38,
        question: " The first military president of Nigeria was",
        options: ["Sanni Abacha", "Ibrahim Babangida", "Yakubu Gowon"],
      },
  
      {
        id: 39,
        question:
          " Which of the following may be regarded as a regional organization",
        options: ["ECOWAS", "OAU", "UN"],
      },
  
      {
        id: 40,
        question: " What is the full meaning of NPF?",
        options: [
          "Nigeria Police Force",
          "Niger Police Force",
          "Nigeria Police ",
      ],
      },
  
      {
        id:41,
        question: " What is the Nigeria police force motto?",
        options: [
          " The police is your friend.",
          " The police is your friend.",
          " The police is your friend.",
      ],
      },
  
      {
        id: 42,
        question: " When was Nigeria Police Force(NPF) established?",
        options: [
          "1930",
          "1930",
          "1930",
      ],
      },
  
      {
        id: 43,
        question: " Who is the present Chief of Defence Staff?",
        options: [
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
          "Major Gen. Abayomi Gabriel Olonisakin",
      ],
      },
  
      {
        id: 44,
        question: " What is the full Meaning of IGP?",
        options: [
          "Inspector General of Police",
          "Inspector General of Police",
          "Inspector General of Police",
      ],
      },
  
      {
        id: 45,
        question: " What is the name of Nigeria Police Force IGP?",
        options: [
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
          "IGP Kayode Egbetokun",
      ],
      },
  
      {
        id: 46,
        question: " When did Nigeria got her independence?",
        options: [
          "1 October 1960",
          "1 October 1960",
          "1 October 1960",
      ],
      },
  
      {
        id: 47,
        question:
          " Who is the current Speaker of Nigeria House of Representatives?",
        options: [
          " Tajudeen Abbas",
          " Tajudeen Abbas",
          " Tajudeen Abbas",
      ],
      },
  
      {
        id: 48,
        question:
          " Which year was the Nigerian flag designed and who designed it?",
        options: [
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
          "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
        ],
      },
  
      {
        id: 49,
        question: " Who is the first Executive president of Nigeria?",
        options: [
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
          "Alh. Shehu Shagari.",
      ],
      },
  
      {
        id: 50,
        question:
          " Name the government agencies that supervise the affairs of Nigeria police force?",
        options: [
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
          "PSC,  Ministry of police affairs and NPC",
      ],
      },
  
      {
        id:51,
        question: "Name the 7 area Commands of Nigeria police force?",
        options: [
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
          "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
      ],
      },
  
      {
        id: 52,
        question: " When did Nigeria become a Republic?",
        options: [
          "1 October 1963.",
          "1 October 1963.",
          "1 October 1963.",
      ],
      },
      {
        id: 53,
        question: " The first secretary General of common wealth was",
        options: [
          "George Washington",
          "Tulma Goldie",
          "Arnold smith",
       
        ],
      },
  
      {
        id: 54,
        question:
          "A formal accusation by a grand jury that charges a person with a crime is known as……",
        options: [
     
          " Insanity plea  ",
          "Indictment  ",
          "   Faux Pas.",
        ],
      },
      {
        id: 55,
        question:
          "The police officer-in-charge of contingents in a state is the…",
        options: [
          "Chief Superintendent of Police",
      
          "Commissioner of Police",
          "Divisional Police officer",
        ],
      },
  
      {
        id: 56,
        question:
          "Which of the following constabularies did not participate in the formation of the Nigerian police?",
        options: [
          "Niger Coast Constabulary",
          "Special Service Constabulary",
          "Royal Niger Company Constabulary",
       
        ],
      },
  
      {
        id: 57,
        question:
          "List of officers fit for appointment to the office of the Inspector General of Police is compiled by the",
        options: [
          "Police Council",
          "Police Corporative",
       
          "Police Authority",
        ],
      },
  
      {
        id: 58,
        question: "Katsina Ala is in which state of Nigeria?",
  
        options: [
          "Cross Rivers State",
          "Benue State",
        
          "Kogi State",
        ],
      },
  
      {
        id: 59,
        question: "What is the length of tenure of the IGP?",
        options: ["3yrs", "4yrs", "5yrs"],
      },
  
      {
        id: 60,
        question:
          "Which of these is not part of the formation list of the Nigerian police?",
        options: [
          "Port Authority Police",
          "Police Cooperative",
          
          "Airforce",
        ],
      }, 
    ],
  ];

export const answers = [
    0,1,2,2,1,0,0,1,2,2,
    1,0,0,1,2,2,1,0,0,1,
    2,2,1,0,0,1,2,2,1,0,
    0,1,2,2,1,0,0,1,2,2,
    1,0,0,1,2,2,1,0,0,1,
    2,2,1,0,0,1,2,2,1,0, 
];
export const answersX = [
    0,1,2,2,1,0,0,1,2,2,
    1,0,0,1,2,2,1,0,0,1,
    2,2,1,0,0,1,2,2,1,0,
    0,1,2,2,1,0,0,1,2,2,
    1,0,0,1,2,2,1,0,0,1,
    2,2,1,0,0,1,2,2,1,0,
];
export const answersY = [
    0,1,2,2,1,0,0,1,2,2,
    1,0,0,1,2,2,1,0,0,1,
    2,2,1,0,0,1,2,2,1,0,
    0,1,2,2,1,0,0,1,2,2,
    1,0,0,1,2,2,1,0,0,1,
    2,2,1,0,0,1,2,2,1,0,
];