import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import "../styles/application.css"
// custom hook
import { useFetchQuestion } from '../hooks/FetchQuestion'
import { updateResult, updateResultX, updateResultY } from '../hooks/setResult'


export default function Questions({onChecked, }) {

    const [checked, setChecked] = useState(undefined)
    const {trace} = useSelector(state => state.questions) 
    const result= useSelector(state => state.result.result) 


    // REACT REDUX FUNCTIONAL FETCH AND ARRAY HOLDING FUNCTIONS
    const [{isLoading, serverError}] = useFetchQuestion()
    // const questions =  useSelector(state => state.questions.queue[isPagination ? n-1: state.questions.trace])
    const questions =  useSelector(state => state.questions.queue[state.questions.trace])
    const dispatch = useDispatch()

    // useEffect(() => {
    // dispatch(updateResult({trace, checked}))
    // }, );

    function onSelect(i){
        onChecked(i)
        setChecked(i)
        dispatch(updateResult({trace, checked}))
 
    }

    if(isLoading) return <h3 className='text-light'>isLoading</h3>
    if(serverError) return <h3 className='text-light'> 'Unknown Error'</h3>
    // if(serverError) return <h3 className='text-light'>{serverError || 'Unknown Error'}</h3>
  return (
    <div className='questions fx-cl space3'>
        <p className='theQuerry'>{questions?.question}</p>

        <ul key={questions?.id} className='fx-cl space2'>
            {
                questions?.options.map((options, i) => (
                   
                    <li key={i} className='fx-ac space3'>
                    <div className='radioPosition'>&nbsp;</div>
                <input 
                    type="radio"
                    value={false}
                    name="options"
                    id={`q${i}-option`}
                    onChange={() => onSelect(i)}
                />

                <label className='text-primary' htmlFor={`q${i}-option`}>{options}</label>
                <div className={`check ${result[trace] === i ? 'checked' : ''}`}></div>
            </li>
           ))
            }
            
        </ul>
    </div>
  )
}
export function QuestionsMath({onChecked}) {

    const [checked, setChecked] = useState(undefined)
    const {traceX} = useSelector(state => state.questions) 
    const resultX = useSelector(state => state.result.resultX) 

    // REACT REDUX FUNCTIONAL FETCH AND ARRAY HOLDING FUNCTIONS
    const questionsMth =  useSelector(state => state.questions.queueX[state.questions.traceX])
    const [{isLoading, serverError}] = useFetchQuestion()
    const dispatch = useDispatch()

  
    function onSelect(i){
        onChecked(i)
        setChecked(i)
        dispatch(updateResultX({traceX, checked}))
 
    }

    if(isLoading) return <h3 className='text-light'>isLoading</h3>
    if(serverError) return <h3 className='text-light'> 'Unknown Error'</h3>
    // if(serverError) return <h3 className='text-light'>{serverError || 'Unknown Error'}</h3>
  return (
    <div className='questions fx-cl space3'>
        <p className='theQuerry'>{questionsMth?.question}</p>

        <ul key={questionsMth?.id} className='fx-cl space2'>
            {
                questionsMth?.options.map((q, i) => (
                    <li key={i} className='fx-ac space3'>
                    <div className='radioPosition'>&nbsp;</div>
                    <input 
                        type="radio"
                        value={false}
                        name="options"
                        id={`q${i}-option`}
                        onChange={() => onSelect(i)}
                    />

                <label className='text-primary' htmlFor={`q${i}-option`}>{q}</label>
                <div className={`check ${resultX[traceX] === i ? 'checked' : ''}`}></div>
            </li>
                ))
            }
            
        </ul>
    </div>
  )
}
export function QuestionsCaf({onChecked}) {

    const [checked, setChecked] = useState(undefined)
    const {traceY} = useSelector(state => state.questions) 
    const resultY = useSelector(state => state.result.resultY) 

    // REACT REDUX FUNCTIONAL FETCH AND ARRAY HOLDING FUNCTIONS
    const questionsCaf =  useSelector(state => state.questions.queueY[state.questions.traceY])
    const [{isLoading, serverError}] = useFetchQuestion()
    const dispatch = useDispatch()

    // useEffect(() => {
    // dispatch(updateResultY({traceY, checked}))
    // }, );

    function onSelect(i){
        onChecked(i)
        setChecked(i)
        dispatch(updateResultY({traceY, checked}))
 
    }

    if(isLoading) return <h3 className='text-light'>isLoading</h3>
    if(serverError) return <h3 className='text-light'> 'Unknown Error'</h3>
    // if(serverError) return <h3 className='text-light'>{serverError || 'Unknown Error'}</h3>
  return (
    <div className='questions fx-cl space3'>
        <p className='theQuerry'>{questionsCaf?.question}</p>
        <ul key={questionsCaf?.id} className='fx-cl space2'>
            {
                questionsCaf?.options.map((q, i) => (
                    <>
                    <li key={i} className='fx-ac space3'>
                    <div className='radioPosition' >&nbsp;</div>
                <input 
                    type="radio"
                    value={false}
                    name="options"
                    id={`q${i}-option`}
                    onChange={() => onSelect(i)}
                />

                <label className='text-primary' htmlFor={`q${i}-option`}>{q}</label>
                <div className={`check ${resultY[traceY] === i ? 'checked' : ''}`}></div>
            </li>
            </>))
            }
            
        </ul>
    </div>
  )
}