import { useRef, useState, useEffect} from "react";
import { Provider } from 'react-redux';
import store from "./computer-base-test-storages/navy/redux/store.js";
import { useNavigate } from "react-router-dom";
import { Authentication } from "../../../../Authentication.jsx";
import axios from "axios";

import { useSnackbar } from "notistack";

import {
  englishExamsEntry,
  mathematicsExamsEntry,
  currentAffairsExamsEntry,
} from "./cbtExamsEntry.js";

import {data } from "./computer-base-test-storages/navy/redux/data.js";

import "./styles/cbtExam.css";
// import Counter from "./ex_subComponent/Counter.jsx";
import Loading from "../../../../Loading.jsx";
import Quiz from "./computer-base-test-storages/navy/components/Quiz.js";
import QuizMth from "./computer-base-test-storages/navy/components/QuizMth.js";
import QuizCaf from "./computer-base-test-storages/navy/components/QuizeCaf.js";


import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
 
import FitScreenIcon from '@mui/icons-material/FitScreen';
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';

import { Link } from "react-router-dom";



export default function ApplicationQuize() {
    const {enqueueSnackbar} = useSnackbar();
  const [loading, setLoading] = useState(false)
  const { client } = Authentication();
  const redirect = useNavigate();
  const [errorLogin, setErrorlogin] = useState("");

const [cbtData, setCBTData]= useState({
  duration: 4800,
  current_subj: "english",
  verified: false, 
  submitting: false, 
  submitted: false})
  // Pagination and navigations Functions
  const [currentComp, setCurrentComp] = useState(1);
  const perComponent = 1;
  const lastIndex = currentComp * perComponent;
  const firstIndex = lastIndex - perComponent;

  // English Formulae for fetching data from array and navigating the contents
  let totalEngQuestions = data;
  const displayed = englishExamsEntry.slice(firstIndex, lastIndex);
  const totalCompEng = Math.ceil(totalEngQuestions.length / perComponent);
  const paginationNoEng = [...Array(totalCompEng + 1).keys()].slice(1);

  // Maths Subject Formulae for fetching data from array and navigating the contents
  const displayed2 = mathematicsExamsEntry.slice(firstIndex, lastIndex);
  const totalCompMaths = Math.ceil(mathematicsExamsEntry.length / perComponent);
  const paginationNoMaths = [...Array(totalCompMaths + 1).keys()].slice(1);

  // Current Affairs Formulae for fetching data from array and navigating the contents
  const displayed3 = currentAffairsExamsEntry.slice(firstIndex, lastIndex);
  const totalCompCurAff = Math.ceil(
    currentAffairsExamsEntry.length / perComponent
  );
  const paginationNoCurAff = [...Array(totalCompCurAff + 1).keys()].slice(1);
// time clock
const time = new Date().toLocaleTimeString

  // next and previouse buttons
  function prev() {
    if (currentComp !== 1) {
      setCurrentComp(currentComp - 1);
    }
  }

  function next(event) {
    if (currentComp !== totalCompEng) {
      setCurrentComp(currentComp + 1);
    }
  }
  function next2() {
    if (currentComp !== totalCompMaths) {
      setCurrentComp(currentComp + 1);
    }
  }
  function next3() {
    if (currentComp !== totalCompCurAff) {
      setCurrentComp(currentComp + 1);
    }
  }
  const currentTime = new Date().toLocaleTimeString();
  const [durations, setDurations] = useState(currentTime);


    // _____________ FULLSCREEN FUNCTIONS ____________________//
    const [toggleFullScreen, setToggleFullScreen] =  useState(false)
    function fullScreenSwitch() {
      const fsComponent = document.getElementById("fullscreen");
      const fsFunction = document.fullscreenElement;
      fsComponent.requestFullscreen();
      setToggleFullScreen(true)
    }
    function fullScreenSwitchOff() {
    const fsComponent = document.getElementById('fullscreen')
    const fsFunction = document.fullscreenElement;
    document.exitFullscreen();
    setToggleFullScreen(false)
    }
  
    
    // -----------------------------------------------------------/

  function ArmyVerification() {
    // exams login functions
    const [nin, setNin] = useState("");
    const [pin, setPin] = useState("");

    async function onClickVerify() {
      const id = client.bioData._id;

      setLoading(true)
      if (
        pin === client.bioData.exams_password
      ) {
      await axios.put(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/cbt_exams/live_navy/${id}`)
        .then((response)=> {
          enqueueSnackbar("Nigerian Navy CBT is now on live", {variant: "success"})
        console.log(response)
    }) .catch(error =>{console.log(error)})
        // setVerified(true);
        
      
        setCBTData(prev => ({...prev, verified: true}))
        setLoading(false)
      } else {
        setErrorlogin(true);
        setLoading(false)
         enqueueSnackbar("Invalid Identification PIN", {variant: "error"})
      }
      fullScreenSwitch();
    }

    return (<>
          { client.bioData.categories.navy.status === "activated" ?   
      <div className="verifyingPage NavyVerf">
        <aside className="fx-cl space2 fx-ac">
          <figure className="fx-ac fx-jc">
            <Person2RoundedIcon style={{fontSize: '14rem'}}/>
          </figure>
          <span style={{fontSize: '3rem'}}>{client.bioData.first_Name + " " + client.bioData.other_Name}</span>
   
          <input
            type="password"
            placeholder="Enter your identification code to start"
            value={pin}
            onChange={(e) => {
              setPin(e.target.value);
            }}
          />
            <input
              type="submit"
              value="Start training"
              onClick={() => onClickVerify()}
            />
            {errorLogin ? (
              <p style={{ color: "red", textAlign: "center" }}>
                *invalid identification number or passcode
              </p>
            ) : (
              <span>&nbsp;</span>
            )}
        </aside>
      </div> : 
       <div className="fx-ac fx-jc " style={{height: "100vh"}}> 
            <div className="cbtLoginCTA fx-cl space1">
            <h2 style={{color: "#2d5ecf"}}>This Programme is not activated</h2>
            <p>Please activate this programme by heading to  categories in dashboard </p>
            <Link to="/client_profile" style={{borderRadius: ".5rem", padding: ".6rem 1.2rem", backgroundColor: "#2d5ecf", color: "#fff", textAlign: "center", width:"50%",}}>Back to dashboard</Link>
            </div>
           </div>
      }
      </>
    );
  }

  function NavyCBTExam() {
  
    function English(props) {
      return (
        <div className="ex_discription fx-cl fx-jb space2">
          <>
            {displayed.map((response, index) => {
              return (
                <div className="qAndA fx-cl space1" key={index}>
            
                  <span className="fx-cl space1">
                    <Quiz />
                  </span>
                </div>
              );
            })}
          </>

       
       
        </div>
      );
    }
    function Mathematics(props) {
      return (
        <div className="ex_discription fx-cl fx-jb space2">
        <>
          {displayed.map((response, index) => {
            return (
              <div className="qAndA fx-cl space1" key={index}>
                <span className="fx-cl space1">
                  <QuizMth />
                </span>
              </div>
            );
          })}
        </>

     
     
      </div>
      );
    }
    function CurrentAffairs(props) {
      return (
        <div className="ex_discription fx-cl fx-jb space2">
        <>
          {displayed.map((response, index) => {
            return (
              <div className="qAndA fx-cl space1" key={index}>
                
                <span className="fx-cl space1">
                  <QuizCaf />
                </span>
              </div>
            );
          })}
        </>

     
     
      </div>
      );
    }

    function toggleSubjects() {
      switch (cbtData.current_subj) {
        case "english":
          return <English />;
        case "maths":
          return <Mathematics />;
        case "current_affairs":
          return <CurrentAffairs />;
        default:
          return <English />;
          break;
      }
    }
 
    return (
      <div className="examsPage NavyE fx-cl">
        <div className="ex_header fx-jb">
          <span>Ongoing exams...</span>
          {
  toggleFullScreen ? <button onClick={()=>fullScreenSwitchOff() }><CloseFullscreenIcon  fontSize="large"/></button> : 
  <button onClick={()=>fullScreenSwitch() }><FitScreenIcon fontSize="large"/></button>
} 
          <span>{client.bioData.first_Name + client.bioData.other_Name}</span>
        </div>
        <div className="mainConts">
          <div className="ex_contents nn fx-cl space2">
            <div className="fx-ac space2">
              <span
                className={`ex_subject ${cbtData.current_subj === "english" ? "sujectActive" : ""
                  }`}
                onClick={() => setCBTData(prev => ({...prev,current_subj: "english"}))}
              >
                English
              </span>
              <span
                className={`ex_subject ${cbtData.current_subj === "maths" ? "sujectActive" : ""
                  }`}
                onClick={() => setCBTData(prev => ({...prev,current_subj: "maths"}))}
              >
                Mathematics
              </span>
              <span
                className={`ex_subject ${cbtData.current_subj === "current_affairs" ? "sujectActive" : ""
                  }`}
                onClick={() => setCBTData(prev => ({...prev,current_subj: "current_affairs"}))}
              >
                Current Affairs
              </span>
            </div>
            {toggleSubjects()}
          </div>
          <div className="ex_status fx-cl space2">
            <Counter />
          
            <div className="ex_submitt fx-cl space2">
              <h2>
                Why are the Best joining Police Forces?
                <span> Because they want carreer, not gigs</span>
              </h2>
              <div className="fx-cl space2">
                <div className="fx-ac space1 fs2 sub_ex_disc">
                  <span>
                    <LogoutOutlinedIcon />
                  </span>
                  <p>
                   You have sufficient time to practice and comprehend the contents, 
                  </p>
                </div>
                <div className="fx-ac space1 fs3 sub_ex_disc">
                  <span>
                    <FeedbackOutlinedIcon />
                  </span>
                  <p>
                  The count-down timer will not automatically  submit your exams.
                  </p>
                </div>
                <div className="fx-ac space1 fs3 sub_ex_disc">
                  <span>
                    <SettingsOutlinedIcon />
                  </span>
                  <p>
                    To submit, click on submit button below to submit and see results
                  </p>
                </div>
              </div>
              <div className="fx-ac">
                <button
                  className="ex_submitBtn"
                  onClick={() =>
                    setCBTData((prev) => ({ ...prev, submitting: true }))
                  }
                >
                  Submit exams
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const [jsonExamsType, setExamsType] = useState("Army CBT Joolie");
  const [jsonSubject, setSebj] = useState("Mathematics");
  const [jsonScore, setScore] = useState("80 over 100");
console.log(client.bioData.categories.navy)

  async function submitsExam() {
    const id = client.bioData._id;
    redirect("/navy/result")

    setLoading(true)
    await axios
      .put(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/cbt_exams/submt_result/${id}`,data )
      .then((response) => { 
        setLoading(false)
        // setSubmitted(true);
        setCBTData(prev => ({...prev, submitted: true}))
      }).catch(error=>{
        console.log(error)
      });
  }





  function ConfirmSub() {
    return (
      <section className="sectionSub fx-ac fx-jc space2"> 
      <div
        className="confirmSub fx-cl space2 fx-jc" 
      > 
        <p>
          Are you sure?
        </p>
        <div className="fx-ac space2 fx-jc">
          <button
            onClick={() =>
              setCBTData((prev) => ({ ...prev, submitting: false }))
            }
          >
            Cancel
          </button>
          <button onClick={() => submitsExam()}>Confirm</button>
        </div>
      </div> 
  </section>
    );
  }
  return (
    <Provider store={store}>

    <section className="sectionPoliceExams" id="fullscreen">
      {loading ? <Loading /> : null}
      {cbtData.submitting ? <ConfirmSub /> : null}
      {cbtData.verified ? <NavyCBTExam /> : <ArmyVerification />}
    </section>
    </Provider>
  );
}


// _________________________________________________________________________________________________________________________________________







function Counter({seconds = 4800}, event) {
  const formarTime = (time) => {
    let hour = Math.floor(time / 60 / 60 );
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time - minutes * 60);
  
    
    if (hour <= 9) hour = "0" + hour;
    if (minutes >= 180) minutes = minutes - 180; 
    if (minutes >= 120) minutes = minutes - 120; 
    if (minutes >= 60) minutes = minutes - 60; 
    if (minutes <= 9) minutes = "0" + minutes;
    if (seconds <= 9) seconds = "0" + seconds;
    return hour + ":" + minutes + ':' + seconds;
  };
  






  // event.preventDefault();
  const [countDown, setCountDown] = useState(seconds);
  const timeId = useRef();

  useEffect(() => {
    timeId.current = setInterval(() => {
      setCountDown((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timeId.current);
  }, []);


  useEffect(()=> {
    if (countDown <= 0) {
        clearInterval(timeId.current)
        alert("Masha Allaah, Time up!, ex submitted") 
    }
  }, [countDown])

  return (
    <figure className="ex_clockCont fx-cl fx-ac space1">
      <span className="fx-ac space1">
        <p className="ex_hour">{formarTime(countDown).slice(0, 5)}</p>
        <p className="fx-cl space1 ex_sec">
          <span>Time:</span>
          <span>{formarTime(countDown).slice(6, 8)}s</span>  
        </p>
      </span>
      <span className="ex_date">{new Date().toLocaleDateString()}</span>
    </figure>
  );
}


// _________________________________________________________________________________________________________________________________________