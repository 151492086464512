import React, { createContext, useContext, useState } from "react";
import SiteRoutes from "./SiteRoutes";
import { useNavigate } from "react-router-dom";

const SiteAuth = createContext();
export const Authentication = () => useContext(SiteAuth);

export function AuthenticationComp(props) {
  const redirect = useNavigate();

  const [client, setclient] = useState({
    bioData: "",
    isAuthenticated: true,
  });  
  const [management, setManagement] = useState({
    bioData: "",
    clientsInfo: "",
    isAuthenticated: true,
  });
  const [administration, setAdministration] = useState({
    userName: "",
    isAuthenticated: false,
  });

  const [dbFetchQueue, setDbFetchQueue]=useState({
    data: ""

  })

  // Client login fuctions
  function loginClient(clientData, password) {
    return new Promise((resolve, reject) => {
      if (password === clientData.password) {
        setclient({ bioData: clientData, isAuthenticated: true });
        resolve("MashaAllaah");
        redirect("/client_profile");
      }
    });
  }
  function logoutClient() {
    setclient({ ...client, isAuthenticated: false });
  }

  // Management login fuctions 
  function loginMD( mdData,  userData, password) {
    return new Promise((resolve, reject) => {
      if (password === mdData.password) {
        setManagement({ bioData: mdData, clientsInfo: userData, isAuthenticated: true });
        resolve("MashaAllaah");
        redirect("/profile_md");
      }
    });
  } 

  
  function logoutMD() {
    setManagement({ ...management, isAuthenticated: false });
  }

  // Administratoin login fuctions
  function loginAdmin(dataName, password) {
    return new Promise((resolve, reject) => {
      if (password === "12") {
        setAdministration({ userName: dataName, isAuthenticated: true });
        resolve("MashaAllaah");
      } else reject("**incorrect username or password**");
    });
  }

  function logoutAdmin() {
    setAdministration({ ...administration, isAuthenticated: false });
  }

 
    // Loading Queue from DB to REDUX
    function loadsQue(que){
      setDbFetchQueue({ ...dbFetchQueue, data: que })
    }
    
  return (
    <SiteAuth.Provider
      value={{
        management,
        administration,
        client,
        setclient, 
        loginMD,
        logoutMD,
        loginAdmin,
        logoutAdmin,
        loginClient,
        logoutClient,
        dbFetchQueue,
        setDbFetchQueue,
        loadsQue
      }}
    >
      <SiteRoutes />
    </SiteAuth.Provider>
  );
}
