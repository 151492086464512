import { useState } from "react";
import Loading from "../../Loading";
import axios from "axios";
import "./contactUs.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SendIcon from "@mui/icons-material/Send";
import CallIcon from "@mui/icons-material/Call";
import { Authentication } from "../../Authentication";
import { useNavigate } from "react-router-dom";

export default function ContactCompany() { 
  const redirect = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jsonFullname, setFullName] = useState("");
  const [jsonEmail, setjsonEmail] = useState("");
  const [JsonMessage, setJsonMessage] = useState("");
  const [jsonAddress, setjsonAddress] = useState("");

  const data = {
    jsonFullname,
    jsonEmail,
    JsonMessage,
    jsonAddress,
  };

  async function submit() {
    setLoading(true);
    await axios
      .post(
        `https://api-jood-cbt.onrender.com/api/v1/jood_routes/contact_us`,
        data
      )
      .then((response) => {
        redirect("/");
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <section className="sectioncontactUs fx-ac fx-jc">
      {loading ? <Loading /> : null}
      <div className="contactUsCont fx-jc space3">
        <div className="fx-cl space3 contactUsDiscription">
          <div className="fx-cl fx-ac">
            <figure>
              <LocationOnIcon
                style={{ fontSize: "4.5rem", color: "#1f835e" }}
              />
            </figure>
            <h2>Your address</h2>
            <p>
              address should includes <br />
              Country, state, capital and location
            </p>
          </div>
          <div className="fx-cl fx-ac">
            <figure>
              <CallIcon style={{ fontSize: "4.5rem", color: "#1f835e" }} />
            </figure>
            <h2>Enter your Contact! </h2>
            <p>
              provide email or tel
              <br />
              that we use to get back to you
            </p>
          </div>
          <div className="fx-cl fx-ac">
            <figure>
              <SendIcon style={{ fontSize: "4.5rem", color: "#1f835e" }} />
            </figure>
            <h2>Send your Message</h2>
            <p>
              your message to us
              <br />
              is important,
            </p>
          </div>
        </div>
        <form className="contactUsForm fx-cl space1">
          <h1>Send us Message!!</h1>
          <input
            type="text"
            name="jsonFullname"
            value={jsonFullname}
            onChange={(event) => {
              setFullName(event.target.value);
            }}
            placeholder="Full Name.."
          />
          <input
            type="text"
            name="jsonEmail"
            value={jsonEmail}
            onChange={(event) => {
              setjsonEmail(event.target.value);
            }}
            placeholder="Email address.."
          />
          <textarea
            name="JsonMessage"
            id=""
            cols="30"
            rows="6"
            value={JsonMessage}
            onChange={(event) => {
              setJsonMessage(event.target.value);
            }}
            placeholder="Your message to us.."
          ></textarea>
          <input
            type="text"
            name="jsonAddress"
            value={jsonAddress}
            onChange={(event) => {
              setjsonAddress(event.target.value);
            }}
            placeholder="address.."
          />
          <button onClick={()=>submit}>Submit</button>
        </form>
      </div>
    </section>
  );
}
