import React, { useRef, useState } from "react";
import "./cbttraining.css";

// importing Images
import ArmyLogo from "./cbtimages/army.jpg";
import Navy from "./cbtimages/navy.png";
import Airforce from "./cbtimages/airforce.png";
import Police from "./cbtimages/police.png";
import Jamb from "./cbtimages/jamb.png";
import Waec from "./cbtimages/waec.png";
import Neco from "./cbtimages/neco.png";

import { Link } from "react-router-dom";
// Swiper Slider __________________________________________________________________________________

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

// Swiper Slider __________________________________________________________________________________

function CBTCardSlides(props) {
  return (
    <Swiper
      slidesPerView={4}
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      navigation={{
        clickable: true,
      }}
      modules={[Pagination, Navigation]}
      className="mySwiper space2"
    > 
      <SwiperSlide>
        <Link to="/jamb_exams">
          <span className="flogo">
            <img src={Jamb} alt="army logo" />
          </span>
          <h2>JAMB C.B.T Training</h2>
          <p>
            Students Preparing for JAMB shuold take their CBT Training here!
          </p>
        </Link>
      </SwiperSlide>
      <SwiperSlide>
        <Link to="/police_exams">
          <span className="flogo">
            <img src={Police} alt="army logo" />
          </span>
          <h2>Nigerian Police   CBT</h2>
          <p>
          Applicants into Nigirian Police should take thier computer Base Training here!

          </p>
        </Link>
      </SwiperSlide>
      <SwiperSlide>
        <Link to="/army_exams">
          <span className="flogo">
            <img
              src={ArmyLogo}
              alt="army logo"
              style={{ borderRadius: ".8rem" }}
            />
          </span>
          <h2>Nigerian Army CBT</h2>
          <p>
            Applicants into Nigirian Army should take thier computer Base Training here!
          </p>
        </Link>
      </SwiperSlide>
     
      <SwiperSlide>
        <Link to="/navy_exams">
          <span className="flogo">
            <img src={Navy} alt="army logo" />
          </span>
          <h2>Nigerian Navy CBT</h2>
          <p>
          Applicants into Nigirian Navy should take thier computer Base Training here!
          </p>
        </Link>
      </SwiperSlide>
      <SwiperSlide>
        <Link to="/airforce_exams">
          <span className="flogo">
            <img src={Airforce} alt="army logo" />
          </span>
          <h2>Nigeria Airforce CBT</h2>
          <p>
          Applicants into Nigirian Airforce should take thier computer Base Training here!
          </p>
      
        </Link>
      </SwiperSlide> 
      <SwiperSlide>
        <Link to="/neco_exams">
          <span className="flogo">
            <img src={Neco} alt="army logo" />
          </span>
          <h2>NECO  C.B.T Training</h2>
          <p>
            SSCE Students for NECO and GCE NECO CBT training software
          </p>
        </Link>
      </SwiperSlide>
      <SwiperSlide>
        <Link to="/waec_exams">
          <span className="flogo">
            <img src={Waec} alt="army logo" />
          </span>
          <h2>WAEC  C.B.T Training</h2>
          <p>
          WASSCE Students for WAEC and GCE WAEC CBT training software
          </p>
        </Link>
      </SwiperSlide> 
      <SwiperSlide>
        <Link to="/jamb_cbt">
          <span className="flogo">
            <img src={Jamb} alt="army logo" />
          </span>
          <h2>Ongiong Developement</h2>
          <p>
            Students Preparing for JAMB shuold take their CBT Training here!
          </p>
        </Link>
      </SwiperSlide>
    </Swiper>
    //
  );
}
// ________________________________________________________________________________________________________
export default function CBTtraining() {
  function SelectForce() {
    return (
      <div className="fx-cl space3 ">
        <div className="cbtHeads ">
          <h2>The Nigerian Armed Forces</h2>
          <h3>Computer Base Test. Training</h3>
          <p>
            <strong>Welcome Back!!</strong> here you are going to take all the
            neccessary ICT training required to become a successfull <br />{" "}
            Nigerian armed force, please select your force to begins the ICT
            training for Armed forces CBT.
          </p>
        </div>
        <div className="forceSelect cbtSlideConterTwo ">
        <CBTCardSlides />
          {/* <Link to="/army_exams">
            <span className="flogo">
              <img
                src={ArmyLogo}
                alt="army logo"
                style={{ borderRadius: ".8rem" }}
              />
            </span>
            <h2>Nigerian Army CBT</h2>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia,
              odit cumque?
            </p>
          </Link>
          <Link to="/navy_exams">
            <span className="flogo">
              <img src={Navy} alt="army logo" />
            </span>
            <h2>Nigerian Navy CBT</h2>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia,
              odit cumque?
            </p>
          </Link>
          <Link to="/airforce_exams">
            <span className="flogo">
              <img src={Airforce} alt="army logo" />
            </span>
            <h2>Nigeria Airforce CBT</h2>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia,
              odit cumque?
            </p>
          </Link>
          <Link to="/police_exams">
            <span className="flogo">
              <img src={Police} alt="army logo" />
            </span>
            <h2>Nigerian Police Force CBT</h2>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia,
              odit cumque?
            </p>
          </Link> */}
        </div>
      </div>
    );
  }
  return (
    <section className="sectionCBT">
      <div className="cbtContainer">
        <Link className="rdtDasb" to="/client_profile">
          Dashboard
        </Link>

        <SelectForce />
      </div>
    </section>
  );
}
