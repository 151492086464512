import React from "react"; 
import { Link } from "react-router-dom";


// icons
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";


function ReportProblems() {
  return (
    <section className="sectionReportProblems">
     Report problems page
    </section>
  );
}

export default ReportProblems;