import { useState } from "react";
import { Link } from "react-router-dom"; 
 




import VideoSettingsIcon from '@mui/icons-material/VideoSettings'; 
import { Authentication } from "../../Authentication.jsx";
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';


function Comp1() {return (<div style={{backgroundColor: "pink"}}>  this is Component Dashboard  </div> )}
function Comp2() {return (<div style={{backgroundColor: "grey"}}>  this is Component Account  </div> )}
function Comp3() {return (<div style={{backgroundColor: "brown"}}>  this is Component Funding  </div> )}
function Comp4() {return (<div style={{backgroundColor: "gold"}}>  this is Component Banking  </div> )}
function Comp5() {return (<div style={{backgroundColor: "yellowgreen"}}>  this is Component Activity  </div> )}
function Comp6() {return (<div style={{backgroundColor: "blue"}}>  this is Component Members  </div> )}



export default function AdminProfile() {
    const [active, setActive] = useState("dashboard") 
    function AsideItem(props) {
        return(
            <span style={{width: "90%"}} > 
                <Link to={props.link} className={` itemsPf cb fx-ac  space1 ${active == `${props.hooks}` ? "active-prl-tab": null}`} 
                onClick={()=>{setActive(`${props.active}`)}} style={{padding: ".7rem 1.8rem" }}>
                {props.icon}
                <span>{props.name}</span>
                </Link>
                </span>
        )
    }

    function switchComponents() {
        switch (active) {
            case "account" : 
                return <Comp2 />
            case "funding" : 
                return "asdf"
            case "banking" : 
                return <Comp4 />
            case "activity" : 
                return <Comp5 />
            case "members" : 
                return <Comp6 />
            default : 
                return <Comp1 />
            ; break
        }
    }

    const {logoutClient} = Authentication();
 async  function logout() {
    await logoutClient()
 }
    return( 
        <section className="sectionAdminProfile">
         <div className="instructorProfileCont " >
          <div className="profileHeader fx-ac fx-jb"> 
            <span className="fx-ac fx-jc space1"> 
            &nbsp;
            </span>
            <span className="fx-ac fx-jc space3"> 
                <span className="fx-ac fx-jc space1"> 
                <CastForEducationOutlinedIcon  fontSize="large" />
                <Link to="/lesson_presentation">Lesson </Link>
                </span>
                <span className="notifBtn fx-ac fx-jc space1"> 
                <NotificationsActiveOutlinedIcon fontSize="large"/> 
                <figure>4</figure>
                </span>
                <span className="fx-ac fx-jc space1"> 
                <figure className="dp">
                    &nbsp;
                </figure>
                <Link to="/lesson_presentation">username</Link>
                </span>
            </span>
          </div>
          <div className="profileAside fx-cl fx-as space1">
                <AsideItem name="Dashboard" icon={<VideoSettingsIcon />} hooks="dashboard" active="dashboard"/>
                <AsideItem name="Account" icon={<VideoSettingsIcon />} hooks="account" active="account"/>
                <AsideItem name="Funding" icon={<VideoSettingsIcon />} hooks="funding" active="funding"/>
                <AsideItem name="Banking" icon={<VideoSettingsIcon />} hooks="banking" active="banking"/>
                <AsideItem name="Activity log" icon={<VideoSettingsIcon />} hooks="activity" active="activity"/>
                <AsideItem name="Members" icon={<VideoSettingsIcon />} hooks="members" active="members"/>
          
                <div className="profileAsideSec fs2 cwb fx-cl fx-as space1">
                    <AsideItem name="setting" icon={<SettingsOutlinedIcon />}  active="sesstting"/>
                    <AsideItem name="records" icon={<VideoSettingsIcon />} active="records"/>
                    <AsideItem name="feedback" icon={<FeedbackOutlinedIcon />}  active="feedback"/>
                    <AsideItem name="Logout" icon={<LogoutOutlinedIcon />} active="logout" onClick={logoutClient} />
                </div>
          </div>
          <div className="profileContents">
            {switchComponents()}
          </div>
         </div>
        </section>
    )
} 












