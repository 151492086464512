import React, { useRef, useState } from "react";
import { NavigationBar } from "./Header";
import { Link } from "react-router-dom";

// importing images
import HeroImage from "./header/headerImage/ccc.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "./header/homeSwiper.css";

// import required modules
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";

function HeroImageSlides() {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <>
      <Swiper
        effect={"fade"}
        spaceBetween={30}
        // centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="heroBgSlider a "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider b "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider c "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider cc "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider d "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider e "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider f "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider g "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider h "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider i "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider j "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider k "></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider l"></div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="heroBgSlider m "></div>
        </SwiperSlide>
         
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            {/* <circle cx="24" cy="24" r="20"></circle> */}
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
    </>
  );
}

// Mui icon
function HeroComp() {
  return (
    <div className="hero_container fx-cl fx-ac space4">
      <h1 className="siteHeader fx-cl fx-ac ">
        <span>CBT Software</span>
        <span>Become Expert in Operating!</span>
      </h1>

      <p className="hero_discription">
        Get yourself ready for all kind of Computer Base Test and Examinations.
        
        Signup for CBT Training along with the mostly asked question of all the
        categories. 
      </p>
      <figure className="fx-ac space2">
        <Link to='/client_login' className="homeLink cw" >
          Sign in
        </Link>
        <Link to='/client_reg_form'
          className="homeLink b cw " 
         
        >
          Get started!
        </Link >
      </figure>
      <aside className="hero_card_container flexcentercn">
        <figure className="hero_card">
          <img src={HeroImage} alt="asdf" />
        </figure>
      </aside>
    </div>
  );
}

export default function HeroSection() {
  return (
    <section className="section_hero ">
      <NavigationBar />
      <HeroComp />
      <div className="heroBgSlider">
        <HeroImageSlides />
      </div>
    </section>
  );
}
