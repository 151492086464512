import { useState } from "react"
import {Link} from "react-router-dom"  
import CloseIcon from '@mui/icons-material/Close';  
import MenuIcon from '@mui/icons-material/Menu';
export function NavigationBar() {
    const [mobileNav, setMobileNav] = useState(false)
    return (
        <div className="headerContainer">
            <span className="toggleNav" >

            {mobileNav ? <button onClick={()=> setMobileNav(false)}><CloseIcon fontSize="large"/></button>:
            <button onClick={()=> setMobileNav(true)}><MenuIcon fontSize="large"/></button>}
            </span>
         {mobileNav ? 
            <ul className="mobileNav fx-cl space2">
                <li>
                    <span>Jood</span>
                </li>
               
                <li>
                    <Link>JAMB</Link>
                </li>
                <li>
                    <Link>Forces</Link>
                </li>
                <li>
                    <Link>GCE</Link>
                </li>
                <li>
                    <Link>Feature</Link>
                </li>
                <li>
                <aside className="fx-cl space1">
               <Link to="/terms">About Us</Link> 
               <Link to="/client_login">Log in</Link>
               <Link to='/client_reg_form' className="navBtn">Signup</Link>
            </aside>
                </li>
                 
            </ul> 
            :
            null
            }
        
        <div className="navigation_bar flexcenterbn"> 
            <ul className="flexcenterbn">
                <li>
                    <span>Jood</span>
                    <span className="navBarTag">COM</span>
                </li>
               
                <li>
                     <a href="#jamb">JAMB</a>
                    <span className="navBarTag">NEW</span>
                </li>
                <li>
                    <a href="#zPattern">Forces</a>
                    <span className="navBarTag">NEW</span>
                </li>
                <li>
                    <a href="#gce">GCE</a>
                </li>
                <li>
                  <a href="#feature">Feature</a>
                </li>
                 
            </ul>
            <aside className="flexcenterbn">
               <Link to="/terms">About Us</Link> 
               <Link to="/client_login">Log in</Link>
               <Link to='/client_reg_form' className="navBtn">Signup</Link>
            </aside>
        </div>
        </div>
    )
}
