// Public routes
import HomePage from "./components/public_directory/Homepage";
import {ClientLogin} from "./components/public_directory/Login";
import {AboutCompany} from "./components/public_directory/AboutCompany";
import ContactCompany from "./components/public_directory/Contact_company";  
import { ClientRegistration } from "./components/public_directory/ClientRegistration";
import { ClientRegAffi } from "./components/public_directory/ClietRegAffi.jsx";
import {TermsOfService} from "./components/public_directory/TermsOfService";
import {Guidelines} from "./components/public_directory/Guidlines";
import ManagementLogin  from "./components/public_directory/management_login"; 
import AdminLogin from "./components/public_directory/admin_login";
import ManagementLoginCate from "./components/public_directory/ManagementLoginCate";
import ReportProblems from "./components/public_directory/ReportProblem";
import ComingSoon from "./ComingSoon.jsx";
// Management routes
import ProfileMD from "./components/management_directory/profileMD";
import {WorkingDirectory} from "./components/management_directory/Working_directory";
import { Employment } from "./components/management_directory/Employment";
import FeedBack from "./components/management_directory/FeedBack";
// Administration routes
import AdminProfile from "./components/admin_directory/admin_profile.js";
import AdminChat from "./components/admin_directory/group_chat.js";
import Fees from "./components/clients_directory/profile/Fees";
// Clients routes
import ClientProfile from "./components/clients_directory/ClientProfile";
import CBTtraining from "./components/clients_directory/CBTtraining";
import ArmyExams from "./components/clients_directory/profileImages/cbtExams/ArmyExams";
import NavyExams from "./components/clients_directory/profileImages/cbtExams/NavyExams";
import AirforceExams from "./components/clients_directory/profileImages/cbtExams/AirforceExams";
import PoliceExams from "./components/clients_directory/profileImages/cbtExams/PoliceExams";
import NECOExams from "./components/clients_directory/profileImages/cbtExams/NECO.jsx";
import WAECExams from "./components/clients_directory/profileImages/cbtExams/WAEC.jsx";
import JambExams from "./components/clients_directory/profileImages/cbtExams/JAMB.jsx";
import JambCBT from "./components/clients_directory/profileImages/cbtExams/JambCBT.jsx"; 
import Payments from "./components/clients_directory/profile/Fees";
import ResultArmy from "./components/clients_directory/profileImages/cbtExams/computer-base-test-storages/army/components/Result.js";
import ResultNavy from "./components/clients_directory/profileImages/cbtExams/computer-base-test-storages/navy/components/Result.js";
import ResultAirforce from "./components/clients_directory/profileImages/cbtExams/computer-base-test-storages/airforce/components/Result.js";
import ResultPolice from "./components/clients_directory/profileImages/cbtExams/computer-base-test-storages/police/components/Result.js";
import ResultNeco from "./components/clients_directory/profileImages/cbtExams/computer-base-test-storages/neco/components/Result.js";
import ResultJamb from "./components/clients_directory/profileImages/cbtExams/computer-base-test-storages/jamb/components/Result.js";
import ResultWaec from "./components/clients_directory/profileImages/cbtExams/computer-base-test-storages/waec/components/Result.js";
const CompanyRoutes = [
    // public components
    {path: "/",                         name: "Home",               element: <HomePage />,                  isPublic: true},
    {path: "/management_login",         name: "ManagementLogin",    element: <ManagementLogin />,           isPublic: true}, 
    {path: "/client_login",             name: "client_login",       element: <ClientLogin />,                     isPublic: true},
    {path: "/mng_login_category",       name: "mng_loginCate",      element: <ManagementLoginCate />,           isPublic: true},
    {path: "/admin_login",              name: "admin_login",        element: <AdminLogin />,                isPublic: true}, 
    {path: "/client_reg_form",          name: "client_reg",         element: <ClientRegAffi />,              isPublic: true},  
    {path: "/registration_under_affiliation",      name: "client_reg",         element: <ClientRegAffi />,              isPublic: true},  
    {path: "/guidlines",                name: "Guidlines",          element: <Guidelines />,                isPublic: true},
    {path: "/terms",                    name: "TermsOfService",     element: <TermsOfService />,            isPublic: true},
    {path: "/contact_company",          name: "contact_company",    element: <ContactCompany />,            isPublic: true},
    {path: "/about_company",            name: "AboutUs",            element: <AboutCompany />,              isPublic: true},
    {path: "/report_problem",           name: "Report-issue",       element: <ReportProblems />,            isPublic: true},
    {path: "/coming_soon",              name: "Report-issue",       element: <ComingSoon />,            isPublic: true},
    
    // Management components
    {path: "/profile_md",        name: "MDProfile",      element: <ProfileMD />,         isManagement: true},
    {path: "/directoriate",      name: "directoriate",   element: <WorkingDirectory />,  isManagement: true},
    {path: "/employment",        name: "employment",     element: <Employment />,        isManagement: true},
    
    
    // administraton components
    {path: "/admin_profile",         name: "Admin_Profile",     element: <AdminProfile />,      isAdministration: true},
    {path: "/admin_chat",            name: "AdminChat",         element: <AdminChat />,         isAdministration: true},
    
    // clients components
    {path: "/client_profile",        name: "ClientProfele",  element: <ClientProfile />,        isClient: true},
    {path: "/fees",        name: "pay",  element: <Fees />,        isClient: true},
    {path: "/cbt",        name: "cbtTraining",  element: <CBTtraining />,        isClient: true},
    {path: "/army_exams",        name: "cbtTraining",  element: <ArmyExams />,        isClient: true},
    {path: "/navy_exams",        name: "cbtTraining",  element: <NavyExams />,        isClient: true},
    {path: "/airforce_exams",        name: "cbtTraining",  element: <AirforceExams />,        isClient: true},
    {path: "/police_exams",        name: "cbtTraining",  element: <PoliceExams />,        isClient: true},
    {path: "/neco_exams",        name: "cbtTraining",  element: <NECOExams />,        isClient: true},
    {path: "/waec_exams",        name: "cbtTraining",  element: <WAECExams />,        isClient: true},
    {path: "/jamb_exams",        name: "cbtTraining",  element: <JambExams />,        isClient: true},
    {path: "/jamb_cbt",        name: "cbtTraining",  element: <JambCBT />,        isClient: true},
    {path: "/army/result",        name: "cbtTraining",  element: <ResultArmy />,        isClient: true},
    {path: "/navy/result",        name: "cbtTraining",  element: <ResultNavy />,        isClient: true},
    {path: "/airforce/result",        name: "cbtTraining",  element: <ResultAirforce />,        isClient: true},
    {path: "/police/result",        name: "cbtTraining",  element: <ResultPolice />,        isClient: true},
    {path: "/neco/result",        name: "cbtTraining",  element: <ResultNeco />,        isClient: true},
    {path: "/jamb/result",        name: "cbtTraining",  element: <ResultJamb />,        isClient: true},
    {path: "/waec/result",        name: "cbtTraining",  element: <ResultWaec />,        isClient: true},
    {path: "/feedback",        name: "feeds_back",     element: <FeedBack />,        isClient: true},

// total ???pages
]

export default CompanyRoutes;