 import * as Action from '../redux/result_reducer'
 import {postServerData} from '../helper/helper'

export const PushAnswer = (result) => async (dispatch) =>{
    try{
        await dispatch(Action.pushResultAction(result))
    }
    catch(error){
        console.log(error);
    }
}
export const PushAnswerX = (resultX) => async (dispatch) =>{
    try{
        await dispatch(Action.pushResultActionX(resultX))
    }
    catch(error){
        console.log(error);
    }
}
export const PushAnswerY = (resultY) => async (dispatch) =>{
    try{
        await dispatch(Action.pushResultActionY(resultY))
    }
    catch(error){
        console.log(error);
    }
}
export const PushAnswerZ = (resultZ) => async (dispatch) =>{
    try{
        await dispatch(Action.pushResultActionY(resultZ))
    }
    catch(error){
        console.log(error);
    }
}

export const updateResult = (index) => async (dispatch) =>{
    try{
        dispatch(Action.updateResultAction(index))
    }
    catch(error){
        console.log(error);
    }
}
export const updateResultX = (index) => async (dispatch) =>{
    try{
        dispatch(Action.updateResultActionX(index))
    }
    catch(error){
        console.log(error);
    }
}
export const updateResultY = (index) => async (dispatch) =>{
    try{
        dispatch(Action.updateResultActionY(index))
    }
    catch(error){
        console.log(error);
    }
}
export const updateResultZ = (index) => async (dispatch) =>{
    try{
        dispatch(Action.updateResultActionY(index))
    }
    catch(error){
        console.log(error);
    }
}

// insert user data 
export const usePublishResult = (resultData) => {
    const { result, username } = resultData;
    (async () => {
        try {
            // if(result !== [] && !username) throw new Error("Couldn't get Result");
            // await postServerData(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/result`, resultData, data => data)
        } catch (error) {
            console.log(error)
        }
    })();
}