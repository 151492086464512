//  fetch question hook to fetch api data and set value to store

import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
// import { getServerData } from "../helper/helper"
import {data, answers, answersX, answersY, answersZ } from "../redux/data"
import { Authentication } from "../../../../../../../Authentication"
// redux action 
import * as Action from "../redux/question_reducer"

/** fetch question hook to fetch api data and set value to store */
export const useFetchQuestion = () =>{
    const {dbFetchQueue} = Authentication();
    const dispatch =  useDispatch();
    const [getData, setGetData] = useState({isLoading : false, apiData : [], serverError : null})
    console.log(dbFetchQueue.data) 

    useEffect(() => {
        setGetData(prev => ({...prev, isLoading : true})); 
        // async function fetch backend data
        (async () => {
            try{ 
                const question = await dbFetchQueue.data;
                console.log(question.subjectOne.length)

                if(question.subjectOne.length > 0){
                    setGetData(prev => ({...prev, isLoading : false}));
                    setGetData(prev => ({...prev, apiData : question}));

                    // dispatch an action
                     dispatch(Action.startExamAction({ question, answers: question.subjectOne[0]?.answers, answersX: question.subjectTwo[0]?.answers, answersY: question.subjectThree[0]?.answers, answersZ: question.subjectFour[0]?.answers, }))
                }
                else{
                    throw new Error('NO Question Available')
                }
            }
            catch(error){
                setGetData(prev => ({...prev, isLoading : false}))
                setGetData(prev => ({...prev, serverError : error}))
            }
        })();

    },[dispatch]);

     
    return [getData,setGetData];
}
  
// move action dispatch function
export const MovePaginationQuestion = (e) => async (dispatch) =>{
    try{
    console.log(e)
     // dispatch an action
        dispatch(Action.movePaginationAction({e}))   
    }
    catch(error){
        console.log(error);
    }
}
export const MovePaginationQuestionX = (e) => async (dispatch) =>{
    try{
console.log(e)
           // dispatch an action

        dispatch(Action.movePaginationActionX({e}))   
    }
    catch(error){
        console.log(error);
    }
}
export const MovePaginationQuestionY = (e) => async (dispatch) =>{
    try{
console.log(e)
           // dispatch an action

        dispatch(Action.movePaginationActionY({e}))   
    }
    catch(error){
        console.log(error);
    }
}
export const MovePaginationQuestionZ = (e) => async (dispatch) =>{
    try{
console.log(e)
           // dispatch an action

        dispatch(Action.movePaginationActionZ({e}))   
    }
    catch(error){
        console.log(error);
    }
}
// move action dispatch function
export const MoveNextQuestion = () => async (dispatch) =>{
    try{
        // icrease trace value by 1
        dispatch(Action.moveNextAction())   
    }
    catch(error){
        console.log(error);
    }
}
export const MoveNextQuestionX = () => async (dispatch) =>{
    try{
        // icrease trace value by 1
        dispatch(Action.moveNextActionX())   
    }
    catch(error){
        console.log(error);
    }
}
export const MoveNextQuestionY = () => async (dispatch) =>{
    try{
        // icrease trace value by 1
        dispatch(Action.moveNextActionY())   
    }
    catch(error){
        console.log(error);
    }
}
export const MoveNextQuestionZ = () => async (dispatch) =>{
    try{
        // icrease trace value by 1
        dispatch(Action.moveNextActionZ())   
    }
    catch(error){
        console.log(error);
    }
}

// prev action dispatch function
export const MovePrevQuestion = () => async (dispatch) =>{
    try{
        // decrease trace value by 1
        dispatch(Action.movePrevAction())
    }
    catch(error){
        console.log(error);
    }
}
export const MovePrevQuestionX = () => async (dispatch) =>{
    try{
        // decrease trace value by 1
        dispatch(Action.movePrevActionX())
    }
    catch(error){
        console.log(error);
    }
}
export const MovePrevQuestionY = () => async (dispatch) =>{
    try{
        // decrease trace value by 1
        dispatch(Action.movePrevActionY())
    }
    catch(error){
        console.log(error);
    }
}
export const MovePrevQuestionZ = () => async (dispatch) =>{
    try{
        // decrease trace value by 1
        dispatch(Action.movePrevActionZ())
    }
    catch(error){
        console.log(error);
    }
}