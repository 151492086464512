
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./public_routes_css/registrations.css";
import Loading from "../../Loading.jsx";
import { useSnackbar } from "notistack";

// importing logo
import Logo from "./jood.png";
import amico from "./public_routes_css/publicImages/tree.svg";
// importing icons
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import "./public_routes_css/registrations.css";
import axios from "axios";

export const ClientRegAffi = () => {
  const {enqueueSnackbar} = useSnackbar()
  const [loading, setloading] = useState(false);
  const [regForm, setRegForm] = useState("a");
  const redirect = useNavigate();

  // ____________________Form Data Hooks___________________
  const [jsonTitle, setTitle] = useState("");
  const [jsonFname, setjsonFname] = useState("");
  const [jsonOname, setjsonOname] = useState("");
  const [jsonIdentificationCode, setjsonIdentificationCode] = useState("");
  const [jsonGender, setGender] = useState("");
  const [jsonState, setState] = useState("");
  const [jsonEmail, setEmail] = useState("");
  const [jsonPassword, setjsonPassword] = useState("");
  const [jsonTel, setTel] = useState("");
  const [jsonAbout, setjsonAbout] = useState(""); 
  const [jsonRef, setjsonRef] = useState(""); 
  // _______________________________________________________
  async function postRegistration() {
    setloading(true);
    const data = {
      jsonTitle,
      jsonFname,
      jsonOname,
      jsonGender,
      jsonState,
      jsonEmail,
      jsonPassword,
      jsonTel,
      jsonAbout,
      jsonIdentificationCode,
      jsonRef
    }; 
    await axios
      .post(
        `https://api-jood-cbt.onrender.com/api/v1/jood_routes/registration_under_affiliation`,
        data
      ) 
      .then((response) => {

        setloading(false);
        enqueueSnackbar("You have registered successfully!", {variant: "success"})
        setRegForm("successful");
        redirect("/client_login");
        console.log(response.data);
      })
      .catch((err) => {
        setloading(true);
        console.log(err);
        // enqueueSnackbar("Network error!", {variant: "error"})
        setloading(false);
      });
  }

  function switchBtn() {
    switch (regForm) {
      case "b":
        return (
          <Link
            style={regBtn}
            className=" cw fs3  pd-btlx"
            onClick={() => {
              setRegForm("c");
            }}
          >
            Continue
          </Link>
        );
      case "c":
        return (
          <button
            style={regBtn}
            className=" cw fs3  pd-btlx pd6"
            onClick={postRegistration}
          >
            submit
          </button>
        );
      default:
        return (
          <Link
            style={regBtn}
            className=" cw fs3  pd-btlx"
            onClick={() => {
              setRegForm("b");
            }}
          >
            Next
          </Link>
        );
        break;
    }
  }
  const regBtn = {
    backgroundColor: "navy",
    width: "100%",
    textAlign: "center",
    borderRadius: ".4rem",
  };

  return (
    <section className="sectionRegistratoin ">
      {regForm === "successful" ? (
        <div style={{ height: "100vh", backgroundColor: "#fff" }}>
          Register Successfully
        </div>
      ) : (
        <div className="regCont g pd5">
          <div className="regDisc pd2 fx-jc fx-cl fx-ac ">
            <figure className="logo">
              <img src={Logo} alt="netclass" />
            </figure>
            <div>
              <img src={amico} alt="svg" />
            </div>
            <h3 className="regH3">Welcome to CBT Training!</h3>
          </div>
          <div className="registerformCont  ">
            <div className="regFormCont fx-cl fx-jb">
              <div className="fx-cl fx-ac space3">
                <span className="regFormSub">CBT Training</span>
                <div>
                  <h3>Create your account</h3>
                </div>
                {regForm === "a" ? (
                  <figure className="RegForm fx-cl space2">
                    <section className="formTitle  ">
                      <div>
                        <select
                          name="jsonTitle"
                          onChange={(event) => setTitle(event.target.value)}
                        >
                          <option value="" hidden>
                            Title
                          </option>
                          <option value="Professor">Professor</option>
                          <option value="Dr">Dr.</option>
                          <option value="Doctor">Doctor</option>
                          <option value="Comrade">Comrade</option>
                          <option value="Mr,">Mr</option>
                          <option value="Miss">Miss</option>
                        </select>
                      </div>
                      <div>
                        <select
                          name="jsonGender"
                          onChange={(event) => setGender(event.target.value)}
                        >
                          <option value="" hidden>
                            Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                    </section>

                    <div>
                      <Person2OutlinedIcon
                        style={{ fontSize: "2.4rem", color: "#999" }}
                      />
                      <input
                        value={jsonFname}
                        onChange={(event) => setjsonFname(event.target.value)}
                        name="jsonFname"
                        type="text"
                        placeholder="first name..."
                      />
                    </div>
                    <div>
                      <Person2OutlinedIcon
                        style={{ fontSize: "2.4rem", color: "#999" }}
                      />
                      <input
                        value={jsonOname}
                        onChange={(event) => setjsonOname(event.target.value)}
                        name="jsonOname"
                        type="text"
                        placeholder="other name..."
                      />
                    </div>
                    <div>
                      <select
                        className="regstateOfRes"
                        name="jsonState"
                        onChange={(event) => setState(event.target.value)}
                      >
                        <option disabled selected>
                          --Select State of resident--
                        </option>
                        <option value="Abia">Abia</option>
                        <option value="Adamawa">Adamawa</option>
                        <option value="Akwa Ibom">Akwa Ibom</option>
                        <option value="Anambra">Anambra</option>
                        <option value="Bauchi">Bauchi</option>
                        <option value="Bayelsa">Bayelsa</option>
                        <option value="Benue">Benue</option>
                        <option value="Borno">Borno</option>
                        <option value="Cross River">Cross River</option>
                        <option value="Delta">Delta</option>
                        <option value="Ebonyi">Ebonyi</option>
                        <option value="Edo">Edo</option>
                        <option value="Ekiti">Ekiti</option>
                        <option value="Enugu">Enugu</option>
                        <option value="FCT">Federal Capital Territory</option>
                        <option value="Gombe">Gombe</option>
                        <option value="Imo">Imo</option>
                        <option value="Jigawa">Jigawa</option>
                        <option value="Kaduna">Kaduna</option>
                        <option value="Kano">Kano</option>
                        <option value="Katsina">Katsina</option>
                        <option value="Kebbi">Kebbi</option>
                        <option value="Kogi">Kogi</option>
                        <option value="Kwara">Kwara</option>
                        <option value="Lagos">Lagos</option>
                        <option value="Nasarawa">Nasarawa</option>
                        <option value="Niger">Niger</option>
                        <option value="Ogun">Ogun</option>
                        <option value="Ondo">Ondo</option>
                        <option value="Osun">Osun</option>
                        <option value="Oyo">Oyo</option>
                        <option value="Plateau">Plateau</option>
                        <option value="Rivers">Rivers</option>
                        <option value="Sokoto">Sokoto</option>
                        <option value="Taraba">Taraba</option>
                        <option value="Yobe">Yobe</option>
                        <option value="Zamfara">Zamfara</option>
                      </select>
                    </div>
                  </figure>
                ) : null}
                {regForm === "b" ? (
                  <figure className="RegForm fx-cl space2">
                    <div>
                      <Person2OutlinedIcon
                        style={{ fontSize: "2.4rem", color: "#999" }}
                      />
                      <input
                        value={jsonEmail}
                        onChange={(event) => setEmail(event.target.value)}
                        name="jsonEmail"
                        type="email"
                        placeholder="email address..."
                      />
                    </div>
                    <div>
                      <Person2OutlinedIcon
                        style={{ fontSize: "2.4rem", color: "#999" }}
                      />
                      <input
                        value={jsonTel}
                        onChange={(event) => setTel(event.target.value)}
                        name="jsonTel"
                        type="number"
                        placeholder="phone number..."
                      />
                    </div>
                    <div>
                      <LockOutlinedIcon
                        style={{ fontSize: "2.4rem", color: "#999" }}
                      />
                      <input
                        value={jsonPassword}
                        onChange={(event) =>
                          setjsonPassword(event.target.value)
                        }
                        name="jsonPassword"
                        type="password"
                        placeholder="password"
                      />
                    </div>
                    <div>
                      <LockOutlinedIcon
                        style={{ fontSize: "2.4rem", color: "#999" }}
                      />
                      <input
                        name="confirmPword"
                        type="password"
                        placeholder="confirm password"
                      />
                    </div>
                  </figure>
                ) : null}
                {regForm === "c" ? (
                  <figure className="RegForm fx-cl space2">
                    <p style={{fontSize: "1.5rem"}}>Please create your PIN for sigin into the training programm software below </p> 
                    <div>
                      <Person2OutlinedIcon
                        style={{ fontSize: "2.4rem", color: "#999" }}
                      />
                      <input
                        value={jsonIdentificationCode}
                        onChange={(event) =>
                          setjsonIdentificationCode(event.target.value)
                        }
                        name="jsonIdentificationCode"
                        type="password"
                        placeholder="create your cbt PIN..."
                      />
                    </div>
                    <div>
                      
                      <input
                        value={jsonRef}
                        onChange={(event) =>
                          setjsonRef(event.target.value)
                        }
                        name="jsonRef"
                        type="text"
                        placeholder="enter your invitation code if any (optional)"
                      />
                    </div>
                  </figure>
                ) : null}
                <div className="fx-jc" style={{ width: "100%" }}>
                  {switchBtn()}
                </div>
              </div>
              <p className="fs1 fx-cl space">
                <span className="fx-ac space">
                  <input type="checkbox" name="agreed" id="" />
                  <span>
                    By signing up, you agree to the terms of services and policy
                  </span>
                </span>
                <span>
                  Already have an account?{" "}
                  <Link
                    to="/client_login"
                    style={{ color: "blue", fontWeight: "600" }}
                  >
                    Sign in instead
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      {loading ? <Loading /> : null}
    </section>
  );
};
