import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StyleIcon from "@mui/icons-material/Style";
import "./payment.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Authentication } from "../../../Authentication"; 
import { useSnackbar } from "notistack";
import Loading from "../../../Loading";
import ClientProfile from "../ClientProfile";

function Fees(req, res) {
  const {enqueueSnackbar} = useSnackbar()
  const { client } = Authentication()
  const redirect = useNavigate();
  const [paid, setPaid] = React.useState(false); 
  const [loading, setLoading] = useState(false)
  function upDateAp() {
    setPaid(true);
  }

  function TransferCardDetails() {
    return (
      <div className="airtimePay">
        <div className="aPheading"> 
          <span className="apNetwork">
            {/* <h4>Transfer payment</h4> */}
            <p>Transfer</p>
          </span>
        </div>
        <p></p>
        <figure className="aPCard">
          <div>
            <h4>ACCOUNT NUMBER</h4>
            <p>017-449-2622</p>
          </div>
          <div>
            <h4>Bank</h4>
            <p>GT Bank </p>
          </div>
          <div>
            <h4>Bank Account Name</h4>
            <p>Usman Umar Dikko</p>
          </div>
           
          <div className="aPbottom">
          Transfer 950 Naira to the Bank details provided below to complete the activation of the selected category
          </div>
        </figure>
        <Link className="aPBtn" onClick={() => upDateAp()} style={{
          backgroundColor: "red"
        }}>
          I Complete this transfer
        </Link>
      </div>
    );
  }

  function AirtimeEnt() {
    const [jsonCategory, setCategory] = useState('')
    const [jsonBank, setBank] = useState('')
    const [jsonAccountName, setacctName] = useState('')
    const [jsonAmount, setAmount] = useState('1200')

    const id = client.bioData._id
    const data = {
      jsonCategory,
      jsonBank,
      jsonAccountName,
      jsonAmount
    }

async function submitPayment() {
  setLoading(true)
  await axios.post(`https://api-jood-cbt.onrender.com/api/v1/client_routes/client_BankTransfer/${id}`, data)
  .then((response) => {
    setPaid(true)
    setLoading(false)
    enqueueSnackbar("Submitted successfully, your service will be activated soon", {variant: "success"})
    redirect("/client_profile")
        }).catch((error) => { 
          console.log(error) 
          enqueueSnackbar("Error please try again", {variant: "error"})
        })
    }
    return (
      <div className="AirtimeEntCont">
        <h2 className="paymenttitle">
          <StyleIcon style={{ fontSize: "3.4rem" }} />
          <span>Confirmation details</span>
        </h2>


        <div className="aPheading">
          <span>
            <form action="php">
              <label htmlFor="jsonBank">Bank name:</label>
              <input type="text" name="" id="aPAmount" value={jsonBank} onChange={(event) => { setBank(event.target.value) }} />
            </form>
          </span>
          <span className="apNetwork">
            <label htmlFor="network">Account name</label>
            <input type="text" name="jsonAccountName" id="aPAmount" value={jsonAccountName} onChange={(event) => { setacctName(event.target.value) }} />
          </span>
        </div>
        <p style={{ fontSize: '1.2rem', textAlign: 'center' }}>Note: the bank name and account name are nacessary,
          they are used to verfies the made by the client, are you sure that they are correct?</p>
        <Link>
          <button onClick={() => submitPayment()}>Proceed </button>
        </Link>
      </div>
    );
  }

  return (
    <div className="paymentContsB">
      {loading ? <Loading/> : null}
      {paid ? <AirtimeEnt /> : <TransferCardDetails />}

      <div className="jambFeeProcess">
      <h2 className="paymenttitle">
          <StyleIcon style={{ fontSize: "3.4rem" }} />
          <span>Course Selection</span>
        </h2> 
        <p>please select the courses you are going to use for this training programme</p>
        <div className="optionJambSubj fx-cl">
          <div className="fx-ac subjContJamb">

          <select name="jambSubjOne" id="">
            <option value="english">English Language</option> 
          </select>

          <select name="jambSubjTwo" id="">
            <option value="">Select second subj</option>
            <option value="mathematics">Mathematics</option>
            <option value="physic">Physics</option>
            <option value="chemistry">Chemistry</option>
            <option value="islamic_studies">Islamic Studies</option>
            <option value="biology">Biology</option>
            <option value="computer_science">Computer Science</option>  
          </select>
          <select name="jambSubjThree" id="">
          <option value="">Select second third subj</option>
            <option value="mathematics">Mathematics</option>
            <option value="physic">Physics</option>
            <option value="chemistry">Chemistry</option>
            <option value="islamic_studies">Islamic Studies</option>
            <option value="biology">Biology</option>
            <option value="computer_science">Computer Science</option>  
          </select>
          <select name="jambSubjFour" id="">
          <option value="">Select fourth subj</option>
            <option value="mathematics">Mathematics</option>
            <option value="physic">Physics</option>
            <option value="chemistry">Chemistry</option>
            <option value="islamic_studies">Islamic Studies</option>
            <option value="biology">Biology</option>
            <option value="computer_science">Computer Science</option>  
          </select> 
          </div>
          <button onClick={() => upDateAp() }>Proceed </button> 
        </div> 
      </div>
    </div>
  );
}

export default Fees;
