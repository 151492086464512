import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// custom hook
import { useFetchQuestion } from "../hooks/FetchQuestion";
import { updateResult, updateResultX, updateResultY } from "../hooks/setResult";

export default function FirstSubjQueue({ onChecked }) {
  const [checked, setChecked] = useState(undefined);
  const { trace } = useSelector((state) => state.questions);
  const result = useSelector((state) => state.result.result);

  // REACT REDUX FUNCTIONAL FETCH AND ARRAY HOLDING FUNCTIONS
  const [{ isLoading, serverError }] = useFetchQuestion();
  // const questions =  useSelector(state => state.questions.queue[isPagination ? n-1: state.questions.trace])
  const dispatch = useDispatch();
  const questions = useSelector((state) =>  state.questions.queue[state.questions.trace]);

  // const questions =  useSelector(state => state.questions ) 
  console.log(questions);
  // useEffect(() => {
  // dispatch(updateResult({trace, checked}))
  // }, );
  function onSelect(i) {
    onChecked(i);
    setChecked(i);
    dispatch(updateResult({ trace, checked }));
  }

  if (isLoading) return <h3 className="text-light">isLoading</h3>;
  if (serverError) return <h3 className="text-light"> 'Unknown Error'</h3>;
  // if(serverError) return <h3 className='text-light'>{serverError || 'Unknown Error'}</h3>
  return (

    <div className="questions jambQue fx-cl space3">
      <p className="theQuerry">{questions?.question}</p>

      <ul key={questions?.id} className="fx-cl space2">
        { 
        questions?.option.map((options, i) => (
          <li key={i} className="fx-ac  ">
            <span style={{fontSize: "1.4rem",fontWeight:"bolder"}}> {options.letter} </span>
            <div className="radioPosition">&nbsp;</div>
            <input
              type="radio"
              value={false}
              name="options"
              id={`q${i}-option`}
              onChange={() => onSelect(i)}
            />
            <label className="text-primary lable" htmlFor={`q${i}-option`} style={{position: "relative"}}>
                <div className={`check withLatter ${result[trace] === i ? "checked" : ""}`} ></div>
            <span>
            {options.values} 
            </span>

            </label>
            
          </li>
        ))}
      </ul>
    </div>
  );
}
export function SecondSubjQueue({ onChecked }) {
  const [checked, setChecked] = useState(undefined);
  const { traceX } = useSelector((state) => state.questions);
  const resultX = useSelector((state) => state.result.resultX);


  // REACT REDUX FUNCTIONAL FETCH AND ARRAY HOLDING FUNCTIONS
  const questionsMth = useSelector(
    (state) => state.questions.queueX[state.questions.traceX]
  );
  const [{ isLoading, serverError }] = useFetchQuestion();
  const dispatch = useDispatch();

  function onSelect(i) {
    onChecked(i);
    setChecked(i);
    dispatch(updateResultX({ traceX, checked }));
  }

  if (isLoading) return <h3 className="text-light">isLoading</h3>;
  if (serverError) return <h3 className="text-light"> 'Unknown Error'</h3>;
  // if(serverError) return <h3 className='text-light'>{serverError || 'Unknown Error'}</h3>
  return (
    <div className="questions fx-cl space3">
      <p className="theQuerry">{questionsMth?.question}</p>

      <ul key={questionsMth?.id} className="fx-cl space2">
        {questionsMth?.option.map((q, i) => (
          <li key={i} className="fx-ac space3">
            <div className="radioPosition">&nbsp;</div>
            <input
              type="radio"
              value={false}
              name="options"
              id={`q${i}-option`}
              onChange={() => onSelect(i)}
            />

            <label className="text-primary" htmlFor={`q${i}-option`}>
              {q.values}
            </label>
            <div
              className={`check ${resultX[traceX] === i ? "checked" : ""}`}
            ></div>
          </li>
        ))}
      </ul>
    </div>
  );
}
export function ThirdSubjQueue({ onChecked }) {
  const [checked, setChecked] = useState(undefined);
  const { traceY } = useSelector((state) => state.questions);
  const resultY = useSelector((state) => state.result.resultY);

  // REACT REDUX FUNCTIONAL FETCH AND ARRAY HOLDING FUNCTIONS
  const questionsCaf = useSelector(
    (state) => state.questions.queueY[state.questions.traceY]
  );
  const [{ isLoading, serverError }] = useFetchQuestion();
  const dispatch = useDispatch();

  // useEffect(() => {
  // dispatch(updateResultY({traceY, checked}))
  // }, );

  function onSelect(i) {
    onChecked(i);
    setChecked(i);
    dispatch(updateResultY({ traceY, checked }));
  }

  if (isLoading) return <h3 className="text-light">isLoading</h3>;
  if (serverError) return <h3 className="text-light"> 'Unknown Error'</h3>;
  // if(serverError) return <h3 className='text-light'>{serverError || 'Unknown Error'}</h3>
  return (
    <div className="questions fx-cl space3">
      <p className="theQuerry">{questionsCaf?.question}</p>
      <ul key={questionsCaf?.id} className="fx-cl space2">
        {questionsCaf?.option.map((q, i) => (
          <>
            <li key={i} className="fx-ac space3">
              <div className="radioPosition">&nbsp;</div>
              <input
                type="radio"
                value={false}
                name="options"
                id={`q${i}-option`}
                onChange={() => onSelect(i)}
              />

              <label className="text-primary" htmlFor={`q${i}-option`}>
                {q.values}
              </label>
              <div
                className={`check ${resultY[traceY] === i ? "checked" : ""}`}
              ></div>
            </li>
          </>
        ))}
      </ul>
    </div>
  );
}
export function FourtSubjQueue({ onChecked }) {
  const [checked, setChecked] = useState(undefined);
  const { traceZ } = useSelector((state) => state.questions);
  const resultZ = useSelector((state) => state.result.resultZ);

  // REACT REDUX FUNCTIONAL FETCH AND ARRAY HOLDING FUNCTIONS
  const questionsCaf = useSelector(
    (state) => state.questions.queueZ[state.questions.traceZ]
  );
  const [{ isLoading, serverError }] = useFetchQuestion();
  const dispatch = useDispatch();

  // useEffect(() => {
  // dispatch(updateResultY({traceY, checked}))
  // }, );

  function onSelect(i) {
    onChecked(i);
    setChecked(i);
    dispatch(updateResultY({ traceZ, checked }));
  }

  if (isLoading) return <h3 className="text-light">isLoading</h3>;
  if (serverError) return <h3 className="text-light"> 'Unknown Error'</h3>;
  // if(serverError) return <h3 className='text-light'>{serverError || 'Unknown Error'}</h3>
  return (
    <div className="questions fx-cl space3">
      <p className="theQuerry">{questionsCaf?.question}</p>
      <ul key={questionsCaf?.id} className="fx-cl space2">
        {questionsCaf?.option.map((q, i) => (
          <>
            <li key={i} className="fx-ac space3">
              <div className="radioPosition">&nbsp;</div>
              <input
                type="radio"
                value={false}
                name="options"
                id={`q${i}-option`}
                onChange={() => onSelect(i)}
              />

              <label className="text-primary" htmlFor={`q${i}-option`}>
                {q.values}
              </label>
              <div
                className={`check ${resultZ[traceZ] === i ? "checked" : ""}`}
              ></div>
            </li>
          </>
        ))}
      </ul>
    </div>
  );
}
