import { useEffect, useState, useReducer } from "react"
import axios from "axios"
import "./profile.comps.css" 

// Components
import Loading from "../../../Loading";
// Material UI Icons
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RuleIcon from '@mui/icons-material/Rule';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';

let requestSwitchNew = [];
let requestSwitchApproved = [];
let requestSwitchPending = [];
let requestSwitchRejected = [];
export default function Busery() {
  const [length, setLength] = useState("")
  const [requestSwitch, setRequestSwitch] = useState("")
  const [loading, setLoading] = useState("")

  

  async function apiGetPayments() {
    await axios.get(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/approving_activation`)
      .then(response => {
        requestSwitchNew = response.data.new_request
        requestSwitchApproved = response.data.approved
        requestSwitchPending = response.data.pending
        requestSwitchRejected = response.data.rejected
        console.log(requestSwitchNew)

      }).catch(error => {
        console.log(error)
      })
  }
  const [clientId, setClientId] = useState('65983de89b2f2ebfc1178e37');
  const [jsonCategory, setjsonCategory] = useState('categories.army.status');
  const [jsonStatus, setjsonStatus] = useState('not-active');
  const [jsonApproveNotes, setjsonApproveNotes] = useState('successfull');



  useEffect(() => {
    apiGetPayments();
  }, [])


  // _________________________________ Components

  function NewRequest() {
   
    return (
      <div className="newRequest">
        <div className="BuserysCardsCont g g3 space3">
          {requestSwitchNew.map((response) => {
              return (
                <figure className="buseryCard fx-cl space">
                  <div className="fx-ac space1 fx-jb">
                    <span style={{ color: "#6693fa" }}>{response.category}</span>
                    <h6 className="priceAcc">{response.amount}</h6>
                  </div>
                  <div className="fx-ac fx-jb space3">
                    <div className="fx-cl space">{response.lesson_hub}
                      <div className="fx-ac space"><span><AccountBalanceRoundedIcon /></span> <span> {response.bank}</span></div>
                      <div className="fx-ac space"><span><SupervisorAccountIcon /></span> <span>{response.account_name}</span></div>
                      <div className="fx-ac space1">
                        <div className="fx-ac space"><span><CalendarMonthIcon /></span> <span>{response.date}</span></div>
                        <div className="fx-ac space"><span><AccessTimeIcon /></span> <span>{response.time}</span></div>
                      </div>
                    </div>

                  </div>
                  <figure className="fx-ac fx-jc space1">
                    <button style={{ color: "#00d285" }}><RemoveRedEyeOutlinedIcon /></button>
                    <button  onClick={
                          async function apiPostApproves() { 
                            await axios.put(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/approving_activation/reject/${response.clientId}/${response._id}`)
                            .then(response => {
                              apiGetPayments();
                              }).catch(error => {
                                console.log(error)
                              })
                          }
                        } style={{ color: "#f35e6a" }}><SubtitlesOffIcon /></button>
                    <button  onClick={
                          async function apiPostApproves() { 
                            await axios.put(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/approving_activation/pending/${response.clientId}/${response._id}`)
                            .then(response => {
                              apiGetPayments();
                              }).catch(error => {
                                console.log(error)
                              })
                          }
                        } style={{ color: "#6693fa" }}><DownloadingOutlinedIcon /></button>
                    <div>
                      <button className="fx-ac space" style={{ backgroundColor: "#00d285", padding: '.6rem 1.4rem', borderRadius: ".2rem", color: '#fff' }}
                        onClick={
                          async function apiPostApproves() { 
                            await axios.put(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/approving_activation/${response.lesson_hub}/${response.clientId}/${response._id}`)
                            .then(response => {
                              apiGetPayments();
                              }).catch(error => {
                                console.log(error)
                              })
                          }
                        }>accept</button>
                    </div>
                  </figure>
                </figure>

              )
            })
          }


        </div>
      </div>
    )
  }
  function ApprovedRequest() {
    return (
      <div className="approvedRequest">
        <div className="BuserysCardsCont g g3 space3">
          {
            requestSwitchApproved.map((response) => {
              return (
                <figure className="buseryCard fx-cl space">
                  <div className="fx-ac space1 fx-jb">
                    <span style={{ color: "#6693fa" }}>{response.category}</span>
                    <h3 style={{color: 'grey'}} >N:{response.amount}</h3>
                  </div>
                  <div className="fx-ac fx-jb space3">
                    <div className="fx-cl space">
                      <div className="fx-ac space"><span><AccountBalanceRoundedIcon /></span> <span> {response.bank}</span></div>
                      <div className="fx-ac space"><span><SupervisorAccountIcon /></span> <span>{response.account_name}</span></div>
                      <div className="fx-ac space1">
                        <div className="fx-ac space"><span><CalendarMonthIcon /></span> <span>{response.date}</span></div>
                        <div className="fx-ac space"><span><AccessTimeIcon /></span> <span>{response.time}</span></div>
                      </div>
                    </div>

                  </div>
                  <figure className="fx-ac fx-jc space1">
                    <div>
                      <span className="fx-ac space" style={{ backgroundColor: "#00d285", padding: '.6rem 1.4rem', borderRadius: ".2rem", color: '#fff' }}
                         >Activated successfully</span>
                    </div>
                    <button style={{ color: "#00d285" }}><RemoveRedEyeOutlinedIcon /></button>

                  </figure>
                </figure>

              )})} 
        </div>
      </div>
    )
  }

  function PendingRequest() {
    return (
      <div className="pendingRequest">
        <div className="BuserysCardsCont g g3 space3">
          {
            requestSwitchPending.map((response) => {
              return (
                <figure className="buseryCard fx-cl space">
                  <div className="fx-ac space1 fx-jb">
                    <span style={{ color: "#6693fa" }}>{response.category}</span>
                    <h6 className="priceAcc">{response.amount}</h6>
                  </div>
                  <div className="fx-ac fx-jb space3">
                    <div className="fx-cl space">
                      <div className="fx-ac space"><span><AccountBalanceRoundedIcon /></span> <span> {response.bank}</span></div>
                      <div className="fx-ac space"><span><SupervisorAccountIcon /></span> <span>{response.account_name}</span></div>
                      <div className="fx-ac space1">
                        <div className="fx-ac space"><span><CalendarMonthIcon /></span> <span>{response.date}</span></div>
                        <div className="fx-ac space"><span><AccessTimeIcon /></span> <span>{response.time}</span></div>
                      </div>
                    </div>

                  </div>
                  <figure className="fx-ac fx-jc space1">
                    <button style={{ color: "#00d285" }}><RemoveRedEyeOutlinedIcon /></button>
                    <button onClick={
                          async function apiPostApproves() { 
                            await axios.put(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/approving_activation/reject/${response.clientId}/${response._id}`)
                            .then(response => {
                              apiGetPayments();
                              }).catch(error => {
                                console.log(error)
                              })
                          }
                        } style={{ color: "#f35e6a" }}><SubtitlesOffIcon /></button>
                     
                    <div>
                      <button className="fx-ac space" style={{ backgroundColor: "#00d285", padding: '.6rem 1.4rem', borderRadius: ".2rem", color: '#fff' }}
                        onClick={
                          async function apiPostApproves() { 
                            await axios.put(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/approving_activation/${response.lesson_hub}/${response.clientId}/${response._id}`)
                            .then(response => {
                              apiGetPayments();
                              }).catch(error => {
                                console.log(error)
                              })
                          }
                        }>accept</button>
                      </div>
                  </figure>
                </figure>

              )
            })
          }


        </div>
      </div>
    )
  }
  function Rejected() {
    return (
      <div className="Rejected">
        <div className="BuserysCardsCont g g3 space3">
          {
            requestSwitchRejected.map((response) => {
              return (
                <figure className="buseryCard fx-cl space">
                  <div className="fx-ac space1 fx-jb">
                    <span style={{ color: "#6693fa" }}>{response.category}</span>
                    <h6 className="priceAcc">{response.amount}</h6>
                  </div>
                  <div className="fx-ac fx-jb space3">
                    <div className="fx-cl space">
                      <div className="fx-ac space"><span><AccountBalanceRoundedIcon /></span> <span> {response.bank}</span></div>
                      <div className="fx-ac space"><span><SupervisorAccountIcon /></span> <span>{response.account_name}</span></div>
                      <div className="fx-ac space1">
                        <div className="fx-ac space"><span><CalendarMonthIcon /></span> <span>{response.date}</span></div>
                        <div className="fx-ac space"><span><AccessTimeIcon /></span> <span>{response.time}</span></div>
                      </div>
                    </div>

                  </div>
                  <figure className="fx-ac fx-jc space1">
                    <button style={{ color: "#00d285" }}><RemoveRedEyeOutlinedIcon /></button>
                    <button  onClick={
                          async function apiPostApproves() { 
                            await axios.put(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/approving_activation/pending/${response.clientId}/${response._id}`)
                            .then(response => {
                              apiGetPayments();
                              }).catch(error => {
                                console.log(error)
                              })
                          }
                        } style={{ color: "#6693fa" }}><DownloadingOutlinedIcon /></button>
                    <div>
                      <button className="fx-ac space" style={{ backgroundColor: "#ff4059", padding: '.6rem 1.4rem', borderRadius: ".2rem", color: '#fff' }}><span> Request rejected</span></button>
                    </div>
                  </figure>
                </figure>

              )
            })
          }


        </div>
      </div>
    )
  }

  const toggleRequestComp = () => {
    switch (requestSwitch) {
      case "new": return <NewRequest />;
      case "approved": return <ApprovedRequest />;
      case "pending": return <PendingRequest />;
      case "rejected": return <Rejected />;
      default: return <NewRequest />;
        break;
    }
  }

  return (
    <div>
      <div className="forceCont fx-cl space3 "> 
        <div className="buserySearchCont fx-ac space2">
          <select name="" id="">
            <option value="By name">By name</option>
            <option value="By location">By location</option>
            <option value="By email">By email</option>
            <option value="By username">By username</option>
            <option value="By phone">By phone</option>
          </select>
          <input type="search" name="BuserySearch" id="" />
          <button><TravelExploreRoundedIcon style={{ fontSize: '2.4rem' }} /></button>
        </div>
        <div className="fx-ac space2 fx-jc BuseryBtn">
          <button onClick={() => {setRequestSwitch("new");apiGetPayments()}} className="fx-ac space" style={{ background: "linear-gradient(270deg,#ff9417 -7.62%,#ffbd40 91.9%)", padding: '.6rem 1.4rem', borderRadius: ".6rem", color: '#fff' }}><span>New Request</span></button>
          <button onClick={() => setRequestSwitch("pending")} className="fx-ac space" style={{ backgroundColor: "#6693fa",  }}><span>Pendings</span></button>
          <button onClick={() => setRequestSwitch("approved")} className="fx-ac space" style={{ backgroundColor: "#00d285" }}><span>Approved</span></button>
          <button onClick={() => setRequestSwitch("rejected")} className="fx-ac space" style={{ backgroundColor: "#f35e6a"}}><span>Rejecteds</span></button>
        </div>

        <div style={{widows: "95%"}}>
          {toggleRequestComp()}
        </div>
      </div>
    </div>
  );
} 