import { Link } from 'react-router-dom';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ScreenRotationOutlinedIcon from '@mui/icons-material/ScreenRotationOutlined';
export default function Featured() {
  return (
    <section className="sectionFeature" id="feature">
      <div className="featuredCont">
        <div className="g g3 space4" style={{rowGap: "4.5rem"}}>
          <div className="fx-cl space1">
            <AccessTimeOutlinedIcon  style={{fontSize: '3.4rem', color: "#2d5ecf"}}/> 
            <h4>Convinient time</h4>
            <p>
              Get confortable while going through the programme, decide by your self when to submit 
              your programme
            </p>
          </div> 
          <div className="fx-cl space1">
            <QuestionMarkOutlinedIcon  style={{fontSize: '3.4rem', color: "#2d5ecf"}}/> 
            <h4>
              Best Past Question
            </h4>
            <p>
              In every category you select, we provide the great experience of the past...
              all the questions are coming from the  real past questions.
            </p>
          </div> 
          <div className="fx-cl space1">
            <MonetizationOnOutlinedIcon  style={{fontSize: '3.4rem', color: "#2d5ecf"}}/> 
            <h4>Affordable Programm</h4>
            <p>
              Our training fee cost you less, the activation of the computer base test software 
              cost you as low as N950 only.
            </p>
          </div> 
          <div className="fx-cl space1">
            <CategoryOutlinedIcon  style={{fontSize: '3.4rem', color: "#2d5ecf"}}/> 
            <h4>We are Working for you</h4>
            <p>
              We provide for you the best CBT programm in different categories, 
              JAMB, NECO, WAEC, ARMY, NAVY, AIRFORCE, POLICE 
            </p>
          </div> 
          <div className="fx-cl space1">
            <ScreenRotationOutlinedIcon  style={{fontSize: '3.4rem', color: "#2d5ecf"}}/> 
            <h4>Can be used on Mobile Phones</h4>
            <p>
              Take your <Link  to="/client_reg_form">training programm</Link> even on mobile devices, with the exact experience as that of computer system
            </p>
          </div> 
          
           
        </div>
      </div>
    </section>
  );
}
