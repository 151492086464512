import {Link} from "react-router-dom" 
import Img2 from "./home_pictures/waec.png" 



export default function HomeInfoComp() {
    return(
        <section id="gce" className="secPattern fx-jc fx-ac" style={{backgroundColor: '#f1f1f1', padding: '4rem'}}>
            <div className="homeInfoCont ptrn g g2">
                 
                <div className="fx-ac fx-jc">
                    <img style={{width: "60%"}} src={Img2}  alt="asdf" />
                </div>
                <div className="fx-cl ">
                    <h3 className="fs5 cblue">GCE NECO, WAEC</h3>
                    <h2 className="fs7">GCE NECO and WAEC on Live</h2>
                    <p className="infoPag fs5">
                        Get ready for the WAEC AND NECO GCE as the examinations goes 
                        on Computer Base Test "CBT". Register now and start your CBT training programm
                    </p> 
                    <Link to="/client_reg_form" className="homeLink cw ">Register</Link>
                </div> 
            </div>
        </section>
    )
}
 
