import React, { useRef, useState, useReducer, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./client_styles/profile.css";
import "./client_styles/profile.swiper.css";
import axios from "axios";
import { useSnackbar } from "notistack";
// importing components
import { Authentication } from "../../Authentication.jsx";
import {
  englishMaterialEntry,
  mathsMaterialEntry,
  currMaterialEntry,
} from "./material/learningMaterial.js";
import Loading from "../../Loading.jsx";

// importing Logos
import CompLogo from "./profileImages/logo/final-dash-logo.png";
import ArLogo from "./profileImages/logo/army.jpg";
import NvLogo from "./profileImages/logo/navy.png";
import AfLogo from "./profileImages/logo/airforce.png";
import Affiliation from "./profileImages/affiliation.png";
import PLLogo from "./profileImages/logo/police.png";
import Jamb from "./profileImages/logo/jamb.png";
import Neco from "./profileImages/logo/neco.png";
import Waec from "./profileImages/logo/waec.png";
import Naira from "./profileImages/cbtExams/cbtImage/nn.png";

// importing made icons png
import i1 from "./profileLogoImg/i1.png";
import i2 from "./profileLogoImg/i2.png";
import i3 from "./profileLogoImg/i3.png";
// Icons import
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import StarIcon from "@mui/icons-material/Star";
import StyleIcon from "@mui/icons-material/Style";

import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import GroupIcon from "@mui/icons-material/Group";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
// ?_______________?
import CastForEducationOutlinedIcon from "@mui/icons-material/CastForEducationOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// Swiper Slider __________________________________________________________________________________

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
let affiliateData;
// Swiper Slider __________________________________________________________________________________

export default function ClientProfile() {
  const { enqueueSnackbar } = useSnackbar();
  const { logoutClient, client } = Authentication();
  const redirect = useNavigate();
  const [active, setActive] = useState("done");
  const [paying, setPaying] = useState("");
  const [loading, setLoading] = useState(false);
  const [optionalSubj, setOptionalSubj] = useState(false);

  const [btnPaymentRequest, setRequestPaymentBtn] = useReducer(
    (request, response) => {
      return { ...request, ...response };
    },
    {
      category: "CATE",
      lessonHub: " ",
      jambSubjOne: "english",
      jambSubjTwo: "",
      jambSubjThree: "",
      jambSubjFour: "",
    }
  );

  async function affiliationApply() {
    await axios
      .post(
        `https://api-jood-cbt.onrender.com/api/v1/jood_routes/affliation_application/${client.bioData._id}`
      )
      .then((response) => {
        console.log(affiliateData);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function affiliationData() {
    await axios
      .get(
        `https://api-jood-cbt.onrender.com/api/v1/jood_routes/affliation_data/${client.bioData.affiliation.key}`
      )
      .then((response) => {
        affiliateData = response.data.data;
        // console.log("login success!!!");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    affiliationData();
  }, []);

  function AsideItem(props) {
    return (
      <span style={{ width: "90%" }}>
        <Link
          to={props.link}
          className={` itemsPf cb fx-ac  space1 ${
            active == `${props.hooks}` ? "active-prl-tab" : null
          }`}
          onClick={() => {
            setActive(`${props.active}`);
          }}
          style={{ padding: ".7rem 1.8rem" }}
        >
          {props.icon}
          {props.img}

          <span>{props.name}</span>
        </Link>
      </span>
    );
  }
  function AsideBottomItem(props) {
    return (
      <span style={{ width: "90%" }}>
        <Link
          to={props.link}
          className={` itemsPf cb fx-ac  space1`}
          style={{ padding: ".7rem 1.8rem" }}
        >
          {props.icon}
          {props.img}
          <span>{props.name}</span>
        </Link>
      </span>
    );
  }

  function switchComponents() {
    switch (active) {
      case "account":
        return <AccountComp />;
      case "affiliate":
        return <AffiliateComp />;

      case "category":
        return <Forcescategories />;
      case "activity":
        return <ActivityComp />;
      case "material":
        return <MaterialDucs />;
      case "setting":
        return <AcctSetting />;
      default:
        return <DashBoardComp />;
        break;
    }
  }

  async function logout() {
    const id = client.bioData._id;
    setLoading(true);
    await axios
      .put(
        `https://api-jood-cbt.onrender.com/api/v1/jood_routes/logout_state/${id}`
      )
      .then((response) => {
        logoutClient();
        redirect("/client_login");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // toggle affi comp

  let affiState = client.bioData.affiliation.status;
  function AffiComp(props) {
    return (
      <div className="bannerClient fx-ac ">
        <figure className="fx-cl fx-ac space1 bannerIcon">
          <div style={{ width: "7rem" }}>
            <img src={Affiliation} alt="airforce Logo" />
          </div>
        </figure>
        <div className="bannerDisc fx-cl space1">
          <h3>{props.header}</h3>
          <div className="fx-cl ">
            <p>{props.disc}</p>
            <button
              style={{
                borderRadius: ".4rem",
                padding: ".4rem .9rem",
                display: `${props.display}`,
              }}
              className="bnBtn"
              onClick={() => affiliationApply()}
            >
              {props.btn}
            </button>
            <Link
              style={{
                backgroundColor: "#fafaff",
                fontSize: "1rem",
                display: `${props.display2}`,
                borderRadius: ".4rem",
                padding: ".4rem 1.2rem",
              }}
            >
              {props.link}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  function toggleAffiComps() {
    switch (affiState) {
      case "inactive":
        return (
          <AffiComp
            display2="none"
            header="Becom an affiliate"
            btn="Get it now"
            disc="Make money easely while working with Us!, grab your link and share to the people and get paid   "
          />
        );
      case "applied":
        return (
          <AffiComp
            display="none"
            header="Application sent"
            disc="Thank you for applying into Our affiliate programm, we are working on it, we will activate your affiliation soon..."
          />
        );

      case "approved":
        return (
          <AffiComp
            header={`Affiliation @N${client.bioData.affiliation.cost}/person`}
            display="none"
            disc="Share your link to customers and start getting paid on every single active user. Copy your link:"
            link={`https://cbt-jood.netlify.app/client_reg_form/${client.bioData.affiliation.key}`}
          />
        );
      default:
        return null;
        break;
    }
  }
  // Components
  function DashBoardComp() {
    return (
      <div className="fx-cl space2">
        <div className="fx-ac dashBHead">
          <figure className="fx-cl ">
            <div className="fx-ac space1">
              <aside>
                <img src={ArLogo} alt="army Logo" />
              </aside>
              <h2>Army</h2>
            </div>
            <span>Status: {client.bioData.categories.army.status}</span>
          </figure>
          <figure className="fx-cl ">
            <div className="fx-ac space1">
              <aside>
                <img src={NvLogo} alt="navy Logo" />
              </aside>
              <h2>Navy</h2>
            </div>
            <span>Status: {client.bioData.categories.navy.status}</span>
          </figure>
          <figure className="fx-cl ">
            <div className="fx-ac space1">
              <aside>
                <img src={AfLogo} alt="airforce Logo" />
              </aside>
              <h2>Airforce</h2>
            </div>
            <span>Status: {client.bioData.categories.airforce.status}</span>
          </figure>
          <figure className="fx-cl ">
            <div className="fx-ac space1">
              <aside>
                <img src={PLLogo} alt="police Logo" />
              </aside>
              <h2>Police</h2>
            </div>
            <span>Status: {client.bioData.categories.police.status}</span>
          </figure>
        </div>
        <div className="fx-ac space3 statusContainer">
          <div className="statusBar fx-cl space3">
            <h3>General High Score</h3>
            <div className="highScore fx-cl ">
              <div className="highScoreChart g g3" style={{ width: "90%" }}>
                <div
                  className="g g2"
                  style={{ alignItems: "end", position: "relative" }}
                >
                  <div style={{ borderRight: "1px solid #ccc", height: "50%" }}>
                    &nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <figure style={{ backgroundColor: "blue" }}>00</figure>
                </div>
                <div
                  className="g g2"
                  style={{
                    borderLeft: "1px solid #ccc",
                    borderRight: "1px solid #ccc",
                    alignItems: "end",
                    position: "relative",
                  }}
                >
                  <div style={{ borderRight: "1px solid #ccc", height: "50%" }}>
                    &nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <figure style={{ backgroundColor: "blue" }}>00</figure>
                </div>
                <div
                  className="g g2"
                  style={{ alignItems: "end", position: "relative" }}
                >
                  <div style={{ borderRight: "1px solid #ccc", height: "50%" }}>
                    &nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <figure style={{ backgroundColor: "blue" }}>00</figure>
                  <figure className="last" style={{ backgroundColor: "green" }}>
                    00
                  </figure>
                </div>
              </div>

              <div className="g g4">
                <span>Army</span>
                <span>Navy</span>
                <span>Airforce</span>
                <span style={{ textAlign: "center" }}>Police</span>
              </div>
            </div>
          </div>
          {toggleAffiComps()}
        </div>
        <div className="g g3 profileCardsConts">
          <div className="dashBCard a fx-cl ">
            <h6>Discovery</h6>
            <div className="fx-ac space1">
              <figure>
                <img src={CompLogo} alt="" />
              </figure>
              <div className="fx-cl  space">
                <p className="fx-ac space">
                  <span>
                    <em>Last test</em>: --- : ---
                  </span>
                </p>
                <p className="fx-ac space">
                  <span>
                    <em>Status</em>: ----
                  </span>
                </p>
              </div>
            </div>
            <div className="fx-ac sts space1">
              <figure>&nbsp;</figure>
              <div className="fx-cl  space">
                <p className="fx-ac space">
                  <span>
                    <strong>0</strong> New Point
                  </span>
                </p>
                <p className="fx-ac space">
                  <span>
                    <strong>0</strong> Active Categories
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="dashBCard cn fx-cl">
            <h6>Key/Hash algorithm</h6>
            <div className="fx-ac space1">
              <figure>
                <img src={CompLogo} alt="" />
              </figure>
              <div className="fx-cl  space">
                <p className="fx-ac space">
                  <div>&nbsp;</div>
                  <span>Hash</span>
                </p>
                <p className="fx-ac space">
                  <div>&nbsp;</div>
                  <span> Keys</span>
                </p>
              </div>
            </div>
          </div>

          <div className="dashBCard c fx-cl">
            <h6>By key size</h6>
            <div className="fx-ac space1">
              <figure>
                <img src={CompLogo} alt="" />
              </figure>
              <div className="fx-cl  space">
                <p className="fx-ac space">
                  <aside>&nbsp;</aside>
                  <span>Hash</span>
                </p>
                <p className="fx-ac space">
                  <aside>&nbsp;</aside>
                  <span> Keys</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function AccountComp() {
    return (
      <div>
        <div
          className="fx-ac  space2"
          style={{
            backgroundColor: "#fafaff",
            padding: "3.2rem",
            borderRadius: "1.2rem",
            alignItems: "flex-start",
          }}
        >
          <div className="accountleft fx-cl space1">
            <div className="fx-cl space2 imgCont">
              <figure className="accountImage">
                {client.bioData.first_Name.slice(0, 1)}
              </figure>
              <button className="fx-ac space1">
                <SettingsOutlinedIcon /> <span>Edit account</span>
              </button>
            </div>
            <div className="fx-cl space2">
              <span className="fx-ac space1 fs1">
                <span>WORKS</span>
                <div style={{ borderBottom: "1px solid #ddd", width: "100%" }}>
                  &nbsp;
                </div>
              </span>

              <div className="fx-cl space">
                <h3 className="fx-ac fx-jb space">
                  <span>Computer Stuff!</span>
                  <Link className="acctLink">Primary</Link>
                </h3>
                <span className="fs1 asDisc">
                  190 Fodio Street <br /> Wamakkao Local Government Sokoto
                </span>
              </div>
              <div className="fx-cl space">
                <h3 className="fx-ac fx-jb space">
                  <span>Training Stuff!</span>
                  <Link className="acctLink">Secondary</Link>
                </h3>
                <span className="fs1 asDisc">
                  190 Fodio Street <br /> Wamakkao Local Government Sokoto
                </span>
              </div>
            </div>
            <div className="fx-cl space2">
              <span className="fx-ac space1 fs1">
                <span>Skills</span>
                <div style={{ borderBottom: "1px solid #ddd", width: "100%" }}>
                  &nbsp;
                </div>
              </span>

              <div className="fx-cl space fs1">
                <p className="fx-ac fx-jb space">
                  <span>Some skills over!</span>
                </p>
              </div>
            </div>
          </div>
          <div className="fx-cl space3">
            <ul className="fx-jb">
              <li className="fx-cl">
                <span className="fs6">
                  {" "}
                  {client.bioData.first_Name} {client.bioData.other_Name}{" "}
                </span>
                <span style={{ fontSize: "1.1rem", color: "#2d5ecf" }}>
                  Nigerian Airforce
                </span>
              </li>
            </ul>
            <ul className="fx-jb">
              <li className="fx-cl">
                <span style={{ fontSize: "1.2rem", color: "#999" }}>
                  RANKING
                </span>
                <span className="fs4 fx-ac space">
                  <strong>8,1</strong>{" "}
                  <span className="fx-ac space" style={{ color: "#6693fa" }}>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon style={{ color: "#ccc" }} />
                    <StarIcon style={{ color: "#ccc" }} />
                  </span>
                </span>
              </li>
            </ul>
            <ul className="fx-cl space2 userDbData">
              <li>
                <span className="fx-ac space1 fs1">
                  <span style={{ fontSize: "1.1rem", color: "#999" }}>
                    CONTACT-INFORMATION
                  </span>
                </span>
              </li>
              <li>
                <span>Other name</span> <span>{client.bioData.other_Name}</span>
              </li>
              <li>
                <span>Email address</span>{" "}
                <span style={{ color: "#2d5ecf" }}>{client.bioData.email}</span>
              </li>
              <li>
                <span>Phone No.</span>{" "}
                <span style={{ color: "#2d5ecf" }}>
                  {client.bioData.phoneNumber}
                </span>
              </li>
            </ul>

            <ul className="fx-cl space2 userDbData">
              <li>
                <span className="fx-ac space1 fs1">
                  <span style={{ fontSize: "1.1rem", color: "#999" }}>
                    BASIC-INFORMATION
                  </span>
                </span>
              </li>
              <li>
                <span>Title</span> <span>{client.bioData.title}</span>
              </li>
              <li>
                <span>State</span> <span>{client.bioData.location}</span>
              </li>
              <li className="fx-ac space2">
                <span>Gender</span> <span>{client.bioData.gender}</span>
              </li>
            </ul>
          </div>
          <ul className="fx-jb">
            <li> &nbsp;</li>
            <li className="fx-cl">
              <Link
                style={{
                  fontSize: "1.1rem",
                  color: "#2d5ecf",
                  backgroundColor: "#d6ecfc",
                  padding: ".5rem .9rem",
                }}
              >
                Edit settings
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  function Comp3() {
    return (
      <div style={{ backgroundColor: "brown" }}>this is Component Funding</div>
    );
  }
  function Forcescategories() {
    function ActivationCard(props) {
      return (
        <div className="payCard">
          <figure className="fx-cl space3 fx-ac">
            <div className="lg">
              <img src={props.image} alt="armyLogo" />
            </div>
            <p>{props.text}</p>
            <div className="btnPay fx-ac space2">
              <button onClick={props.function}>Activate now</button>
            </div>
          </figure>
        </div>
      );
    }

    function PaymentCard(props) {
      return (
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          navigation={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper space2"
        >
          <SwiperSlide>
            <ActivationCard
              text="The Activation fee for Army CBT is N950 only, click the button to proceed"
              image={ArLogo}
              function={() => {
                setRequestPaymentBtn({
                  category: "Nigerian Army Compter Training",
                  lessonHub: "army",
                });
                setPaying("details");
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ActivationCard
              text="The Activation fee for Navy CBT is N950 only, click the button to proceed"
              image={NvLogo}
              function={() => {
                setRequestPaymentBtn({
                  category: "Nigerian Navy  Compter Training",
                  lessonHub: "navy",
                });
                setPaying("details");
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ActivationCard
              text="The Activation fee for the  Airforce CBT is N950 only, click the button to proceed"
              image={AfLogo}
              function={() => {
                setRequestPaymentBtn({
                  category: "Nigerian Airforce Compter Training",
                  lessonHub: "airforce",
                });
                setPaying("details");
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ActivationCard
              text="The Activation fee for the  Police CBT is N950 only, click the button to proceed"
              image={PLLogo}
              function={() => {
                setRequestPaymentBtn({
                  category: "Nigerian Police Force Compter Training",
                  lessonHub: "police",
                });
                setPaying("details");
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ActivationCard
              text="The Activation fee for the JAMB CBT is N950 only, click the button to proceed"
              image={Jamb}
              function={() => {
                setRequestPaymentBtn({
                  category: "CBT Joint Admission and Matriculation Board",
                  lessonHub: "jamb",
                });
                setPaying("jambCourse");
                setOptionalSubj(true);
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ActivationCard
              text="The Activation fee for the NECO CBT is N950 only, click the button to proceed"
              image={Neco}
              function={() => {
                setRequestPaymentBtn({
                  category: "NECO Computer Base Training",
                  lessonHub: "neco",
                });
                setPaying("details");
              }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ActivationCard
              text="The Activation fee for the WAWC CBT is N950 only, click the button to proceed"
              image={Waec}
              function={() => {
                setRequestPaymentBtn({
                  category: "West African Examination Council",
                  lessonHub: "waec",
                });
                setPaying("details");
              }}
            />
          </SwiperSlide>
        </Swiper>
        //
      );
    }
    function Fees(req, res) {
      const { client } = Authentication();
      const redirect = useNavigate();
      const [paid, setPaid] = React.useState("");
      function upDateAp() {
        setPaid(true);
      }

      function TransferCardDetails() {
        return (
          <div className="airtimePay">
            <div className="aPheading">
              <div className="apNetwork fx-cl space2">
                <p
                  style={{ color: "rgb(230, 126, 34)", fontWeight: "900" }}
                  className="fx-ac fx-jc"
                >
                  Transfer{" "}
                </p>
                <div className="fx-ac ">
                  <span style={{ width: "3.4rem", height: "3.4rem" }}>
                    <img src={Naira} alt="" />
                  </span>
                  <h3
                    className="fx-ac fx-jc"
                    style={{
                      fontSize: "10rem",
                      fontWeight: "900",
                      fontFamily: "serif",
                    }}
                  >
                    950
                  </h3>
                </div>
              </div>
            </div>
            <figure className="aPCard fx-cl space2">
              <div className="fx-ac space2">
                <figure>
                  <GroupIcon style={{ fontSize: "3.4rem", color: "#cf711f" }} />
                </figure>
                <div className="fx-cl ">
                  <h4>Account Number:</h4>
                  <p>225-823-6626</p>
                </div>
              </div>
              <div className="fx-ac space2">
                <figure>
                  <AccountBalanceOutlinedIcon
                    style={{ fontSize: "3.4rem", color: "#cf711f" }}
                  />
                </figure>
                <div className="fx-cl ">
                  <h4>Bank Name:</h4>
                  <p>Zenith Bank Plc</p>
                </div>
              </div>
              <div className="fx-ac space2">
                <figure>
                  <AccountBalanceWalletOutlinedIcon
                    style={{ fontSize: "3.4rem", color: "#cf711f" }}
                  />
                </figure>
                <div className="fx-cl ">
                  <h4>Account Name:</h4>
                  <p>Usman Dikko Umar</p>
                </div>
              </div>
              <div className="aPbottom">
                Transfer to the information provided above to complete the
                activation
              </div>
              <button
                className="aPBtn"
                onClick={() => setPaying("cheque")}
                style={{
                  backgroundColor: "#e67e22",
                  padding: ".8rem 1.4rem",
                  borderRadius: ".5rem",
                  color: "#fff",
                }}
              >
                I complete this transfer
              </button>
            </figure>
          </div>
        );
      }

     

      function JambCourseSelection() {
        return (
          <div className="jambFeeProcess">
            <h2 className="paymenttitle">
              <StyleIcon style={{ fontSize: "3.4rem" }} />
              <span>Course Selection</span>
            </h2>
            <p>
              please select the courses you are going to use for this training
              programme
            </p>
            <div className="optionJambSubj fx-cl">
              <div className="fx-ac subjContJamb">
                <select
                  value={btnPaymentRequest.jambSubjOne}
                  name="jambSubjOne"
                  id=""
                >
                  <option value="Use of English">English Language</option>
                </select>
                <select
                  value={btnPaymentRequest.jambSubjTwo}
                  onChange={(event) => {
                    setRequestPaymentBtn({ jambSubjTwo: event.target.value });
                  }}
                  name="jambSubjTwo"
                  id=""
                >
                  <option value="">Select second subj</option>
                  <option value="Acounting">Mathematics</option>
                  <option value="Mathematics">Mathematics</option>
                  <option value="Physic">Physics</option>
                  <option value="Chemistry">Chemistry</option>
                  <option value="Literature-in-English">Islamic Studies</option>
                  <option value="Biology">Biology</option>
                  <option value="Geography">Computer Science</option>
                  <option value="Government ">Computer Science</option>
                  <option value="Economics ">Computer Science</option>
                </select>
                <select
                  value={btnPaymentRequest.jambSubjThree}
                  onChange={(event) => {
                    setRequestPaymentBtn({ jambSubjThree: event.target.value });
                  }}
                  name="jambSubjThree"
                  id=""
                >
                  <option value="">Select second third subj</option>
                  <option value="Acounting">Mathematics</option>
                  <option value="Mathematics">Mathematics</option>
                  <option value="Physic">Physics</option>
                  <option value="Chemistry">Chemistry</option>
                  <option value="Literature-in-English">Islamic Studies</option>
                  <option value="Biology">Biology</option>
                  <option value="Geography">Computer Science</option>
                  <option value="Government ">Computer Science</option>
                  <option value="Economics ">Computer Science</option>
                </select>
                <select
                  value={btnPaymentRequest.jambSubjFour}
                  onChange={(event) => {
                    setRequestPaymentBtn({ jambSubjFour: event.target.value });
                  }}
                  name="jambSubjFour"
                  id=""
                >
                  <option value="">Select fourth subj</option>
                  <option value="Acounting">Mathematics</option>
                  <option value="Mathematics">Mathematics</option>
                  <option value="Physic">Physics</option>
                  <option value="Chemistry">Chemistry</option>
                  <option value="Literature-in-English">Islamic Studies</option>
                  <option value="Biology">Biology</option>
                  <option value="Geography">Computer Science</option>
                  <option value="Government ">Computer Science</option>
                  <option value="Economics ">Computer Science</option>
                </select>
              </div>
              <button onClick={() => setPaying("details")}>Proceed</button>
            </div>
          </div>
        );
      }
      function ChequeComp() {
        const [requestPayment, setRequestPayment] = useReducer(
          (request, response) => {
            return { ...request, ...response };
          },
          {
            bank: "",
            account: "",
            amount: "950",
          
          }
        );
        const id = client.bioData._id;
        const jsonCategory = btnPaymentRequest.category;
        const jsonLessonHub = btnPaymentRequest.lessonHub;
        const jambSubjOne = btnPaymentRequest.jambSubjOne;
        const jambSubjTwo = btnPaymentRequest.jambSubjTwo;
        const jambSubjThree = btnPaymentRequest.jambSubjThree;
        const jambSubjFour = btnPaymentRequest.jambSubjFour;
        const jsonBank = requestPayment.bank;
        const jsonAccountName = requestPayment.account;
        const jsonAmount = requestPayment.amount;

        async function submitPayment() {
          const data = {
            jsonCategory,
            jsonLessonHub,
            jsonBank,
            jsonAccountName,
            jsonAmount,
          };
          setLoading(true);
          await axios
            .post(
              `https://api-jood-cbt.onrender.com/api/v1/jood_routes/client_BankTransfer/${id}`,
              data
            )
            .then((response) => {
              enqueueSnackbar(
                "Success!, Your transaction will be reviewed soon, please check back in a moment",
                { variant: "success" }
              );
              redirect("/client_profile");
              setPaid(false);
              setPaying(false);
              setLoading(false);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        async function submitPaymentJamb() {
          const data = {
            jsonCategory,
            jsonLessonHub,
            jsonBank,
            jsonAccountName,
            jsonAmount,
            jambSubjOne,
            jambSubjTwo,
            jambSubjThree,
            jambSubjFour,
          };
          setLoading(true);
          await axios
            .post(
              `https://api-jood-cbt.onrender.com/api/v1/jood_routes/client_BankTransfer/jamb/${id}`,
              data
            )
            .then((response) => {
              enqueueSnackbar(
                "Success!, Your transaction will be reviewed soon, please check back in a moment",
                { variant: "success" }
              );
              redirect("/client_profile");
              setPaid(false);
              setPaying(false);
              setLoading(false);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        return (
          <div className="ChequeCompCont">
            <h2 className="paymenttitle">
              <StyleIcon style={{ fontSize: "3.4rem" }} />
              <span>Confirmation details</span>
            </h2>
            <div className="aPheading">
              <span>
                <form action="php">
                  <label htmlFor="jsonBank">Bank name:</label>
                  <input
                    type="text"
                    name=""
                    placeholder="Enter the Bank name you use to transfer..."
                    id="aPAmount"
                    value={requestPayment.bank}
                    onChange={(event) => {
                      setRequestPayment({ bank: event.target.value });
                    }}
                  />
                </form>
              </span>
              <span className="apNetwork">
                <label htmlFor="network">Account name:</label>
                <input
                  type="text"
                  placeholder="Enter the account name you use to transfer..."
                  name="jsonAccountName"
                  id="aPAmount"
                  value={requestPayment.account}
                  onChange={(event) => {
                    setRequestPayment({ account: event.target.value });
                  }}
                />
              </span>
            </div>
            <p style={{ fontSize: "1.2rem" }}>
              Note: Please make sure that you provide the above information
              correctly before proceeding!
            </p>
            {optionalSubj ? (
              <button onClick={() => submitPaymentJamb()}>
                Activate jamb course{" "}
              </button>
            ) : (
              <button onClick={() => submitPayment()}>Proceed </button>
            )}
          </div>
        );
      }

      function togglePyComps() {
        switch (paying) {
          case "jambCourse":
            return <JambCourseSelection />;
          case "cheque":
            return <ChequeComp />;
          case "details":
            return <TransferCardDetails />;
          default:
            return <TransferCardDetails />;
            break;
        }
      }

      return <div className="paymentContsB">{togglePyComps()}</div>;
    }
    return (
      <div>
        <div className="forceCont ">
          <div
            className="forceImageSlide fx-ac jx-jc space2"
            style={{ padding: "2.4rem" }}
          >
            {paying ? <Fees /> : <PaymentCard />}
          </div>
        </div>
      </div>
    );
  }
  function ActivityComp() {
    return (
      <div style={{ backgroundColor: "yellowgreen" }}>
        this is Component Activity
      </div>
    );
  }
  function MaterialDucs(props) {
    const [mtSubj, setMtsubj] = React.useState("english");
    console.log(mtSubj);
    function toggleMtSub() {
      switch (mtSubj) {
        case "english":
          return <MtDataEng />;
        case "maths":
          return <MtDataMath />;
        case "current_affairs":
          return <MtDataCur />;
        default:
          return null;
          break;
      }
    }

    function MtDataEng() {
      return (
        <tbody className="fx-cl">
          {/* {englishMaterialEntry.map((e, i) => {
            return (
              
              <tr
                className="fx-ac "
                key={i}
              >
                <td>{i + 1}</td>
                <td>{e.question}</td>
                <td>{e.answer}</td>
              </tr>
            );
          })} */}
          <tr>
            <td></td>

            <td>Materials will be available soon!</td>
          </tr>
        </tbody>
      );
    }
    function MtDataMath() {
      return (
        <tbody className="fx-cl ">
          {/* {mathsMaterialEntry.map((e, i) => {
            return (
              <tr
                className="fx-ac "
                key={i}
              >
                <td>{i + 1}</td>
                <td>{e.question}</td>
                <td>{e.answer}</td>
              </tr>
            );
          })} */}
          <tr>
            <td></td>
            <td>Materials will be available soon!</td>
          </tr>
        </tbody>
      );
    }
    function MtDataCur() {
      return (
        <tbody className="fx-cl">
          {/* {currMaterialEntry.map((e, i) => {
            return (
              <tr
                className="fx-ac "
                key={i}
              >
                <td>{i + 1}</td>
                <td>{e.question}</td>
                <td>{e.answer}</td>
              </tr>
            );
          })} */}
          <tr>
            <td></td>
            <td>Materials will be available soon!</td>
          </tr>
        </tbody>
      );
    }
    return (
      <div className="fx-cl">
        <head>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi, autem!
          Aperiam, recusandae. Enim rerum laboriosam aliquam, ex deserunt nemo
          nam omnis facere.
        </head>
        <div className="materialCont fx-cl space2">
          <div className="materialSubj fx-ac space2 fx-jc">
            <button onClick={() => setMtsubj("english")}>English</button>
            <button onClick={() => setMtsubj("maths")}>Mathematics</button>
            <button onClick={() => setMtsubj("current_affairs")}>
              Current affairs
            </button>
          </div>
          <div className="materialTable">
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Question</th>
                  <th>Answer</th>
                </tr>
              </thead>
              {toggleMtSub()}
            </table>
          </div>
        </div>
      </div>
    );
  }

  function AffiliateComp(props) {
    return (
      <div className="fx-cl">
        <head>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi, autem!
          Aperiam, recusandae. Enim rerum laboriosam aliquam, ex deserunt nemo
          nam omnis facere.
        </head>
        <div className="materialCont fx-cl space2">
          <div className="affiliationTable">
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Clients</th>
                  <th>Location</th>
                  <th>Date</th>
                  <th>Income</th>
                  <th>Status</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {affiliateData.map((services) => {
                  return (
                    <tr>
                      <th>01</th>
                      <td>{services.first_Name}</td>
                      <td>{services.location}</td>
                      <td>{services.registeredDate}</td>
                      <td>N200</td>
                      <td>{services.account_status}</td>
                      <td>
                        {services.cleared == true ? "withdrawn" : "pending"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  function AcctSetting(props) {
    const [jsonPassword, setjsonPassword] = useState("");
    const [jsonEmail, setjsonEmail] = useState("");
    const [jsonState, setjsonState] = useState("");
    const [jsonTel, setjsonTel] = useState("");
    const [jsonExamsPassword, setjsonExamsPassword] = useState("");
    const [jsonExamsId, setjsonExamsId] = useState("");
    const [jsonUsername, setjsonUsername] = useState("");
    const [newMail, setNewMail] = useState("");
    const [updated, setUpdated] = useState("");

    async function submitAcctUpdate() {
      const _id = client.bioData._id;
      const data = {
        jsonPassword,
        jsonEmail,
      };
      await axios
        .put(
          `https://api-jood-cbt.onrender.com/api/v1/client_routes/profile_setting/${_id}`,
          data
        )
        .then((response) => {
          setUpdated(true);
          console.log(updated);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return (
      <div className="fx-cl " style={{ backgroundColor: "#fafaff" }}>
        <h1>Account SEtting</h1>
        <form className="fx-cl space2">
          <input
            type="password"
            name="jsonPassword"
            placeholder="password.."
            value={jsonPassword}
            onChange={(event) => setjsonPassword(event.target.value)}
          />
          <input
            type="email"
            name="jsonEmail"
            placeholder="new email address.."
            value={jsonEmail}
            onChange={(event) => setjsonEmail(event.target.value)}
          />
          <button
            onClick={() => submitAcctUpdate()}
            style={{ width: "20%", backgroundColor: "yellow" }}
          >
            update account
          </button>
        </form>
      </div>
    );
  }

  const [profileNav, setProfileNav] = useState(false);
  return (
    <section className="sectionInstructorProfile">
      <figure className="toggleProfileNav">
        {profileNav ? (
          <button
            style={{ color: "#fff" }}
            onClick={() => setProfileNav(false)}
          >
            <CloseIcon fontSize="large" />
          </button>
        ) : (
          <button style={{ color: "#333" }} onClick={() => setProfileNav(true)}>
            <MenuIcon fontSize="large" />
          </button>
        )}
      </figure>
      {profileNav ? (
        <div className="profileAsideResponsive fx-cl fx-as space1">
          <div className="profileAsideSec fs2 cwb fx-cl fx-as ">
            <div className="fx-cl space2" style={{ width: "100%" }}>
              <figure>
                <button className="fx-ac profBtn  space1">
                  <TipsAndUpdatesTwoToneIcon fontSize="large" />
                  <span>Video tutorial</span>
                </button>
              </figure>
            </div>
            <AsideItem
              name="Dashboard"
              icon={<DashboardRoundedIcon fontSize="large" />}
              hooks="dashboard"
              active="dashboard"
            />
            <AsideItem
              name="Categories"
              icon={<CategoryRoundedIcon fontSize="large" />}
              hooks="category"
              active="category"
            />
            <AsideItem
              name="Materials"
              icon={<AssignmentRoundedIcon fontSize="large" />}
              hooks="material"
              active="material"
            />
            <AsideItem
              name="Account"
              icon={<PeopleAltRoundedIcon fontSize="large" />}
              hooks="account"
              active="account"
            />

            <figure className="prfLinksto fx-ac space2 fx-jb">
              <span>Enventory</span>
              <span>Edits</span>
            </figure>
            <AsideItem
              name="Setting"
              img={<img src={i3} alt="icon" />}
              active="setting"
            />

            <AsideItem
              name="Affiliation"
              img={<img src={i2} alt="icon" />}
              active="affiliate"
            />
            {/* <AsideBottomItem
         name="Records"
         img={<img src={i2} alt="icon" />}
         active="records"
       /> */}
            <AsideBottomItem
              name="Feedback"
              img={<img src={i1} alt="icon" />}
              active="feedback"
              link="/feedback"
            />

            <button
              className=" logout_profile fx-ac space1"
              onClick={() => logout()}
            >
              <LogoutOutlinedIcon />
              <span>Logout</span>
            </button>
          </div>
        </div>
      ) : null}
      {loading ? <Loading /> : null}
      <div className="instructorProfileCont ">
        <div className="profileAside fx-cl fx-as space1">
          <div className="profileAsideSec fs2 cwb fx-cl fx-as ">
            <div className="fx-cl space2" style={{ width: "100%" }}>
              <h2 className="compLogo">CompLogo</h2>
              <figure>
                <button className="fx-ac profBtn  space1">
                  <TipsAndUpdatesTwoToneIcon fontSize="large" />
                  <span>Video tutorial</span>
                </button>
              </figure>
            </div>
            <AsideItem
              name="Dashboard"
              icon={<DashboardRoundedIcon fontSize="large" />}
              hooks="dashboard"
              active="dashboard"
            />
            <AsideItem
              name="Categories"
              icon={<CategoryRoundedIcon fontSize="large" />}
              hooks="category"
              active="category"
            />
            <AsideItem
              name="Materials"
              icon={<AssignmentRoundedIcon fontSize="large" />}
              hooks="material"
              active="material"
            />
            <AsideItem
              name="Account"
              icon={<PeopleAltRoundedIcon fontSize="large" />}
              hooks="account"
              active="account"
            />

            <figure className="prfLinksto fx-ac space2 fx-jb">
              <span>Enventory</span>
              <span>Edits</span>
            </figure>
            <AsideItem
              name="Setting"
              img={<img src={i3} alt="icon" />}
              active="setting"
            />

            <AsideItem
              name="Affiliation"
              img={<img src={i2} alt="icon" />}
              active="affiliate"
            />
            {/* <AsideBottomItem
              name="Records"
              img={<img src={i2} alt="icon" />}
              active="records"
            /> */}
            <AsideBottomItem
              name="Feedback"
              img={<img src={i1} alt="icon" />}
              active="feedback"
              link="/feedback"
            />

            <button
              className=" logout_profile fx-ac space1"
              onClick={() => logout()}
            >
              <LogoutOutlinedIcon />
              <span>Logout</span>
            </button>
          </div>
        </div>
        <div className="profileHeader fx-ac fx-jb">
          <span className="fx-ac fx-jc space1">
            <span>
              <Link to="/cbt" className="fx-ac fx-jc space1">
                <CastForEducationOutlinedIcon fontSize="large" />
                <span>Computer Base Test</span>
              </Link>
            </span>
          </span>
          <span className="fx-ac fx-jc space3">
            <span className="notifBtn fx-ac fx-jc space1">
              <NotificationsActiveOutlinedIcon fontSize="large" />
              <figure>0</figure>
            </span>
            <span className="fx-ac fx-jc space1">
              <Link
                className="fx-ac space1"
                onClick={() => setActive("account")}
              >
                <p>
                  {client.bioData.first_Name + " " + client.bioData.other_Name}
                </p>
                <figure className="dp fx-ac">
                  {client.bioData.first_Name.slice(0, 1)}
                </figure>
              </Link>
            </span>
          </span>
        </div>
        <div className="profileContents">{switchComponents()}</div>
      </div>
    </section>
  );
}
