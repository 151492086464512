import React, { useState } from "react";
import { Authentication } from "../../Authentication.jsx"
import { Link, useNavigate } from "react-router-dom"; 
// importing the backend connection component  
// importing logo
import Logo from "./final-dash-logo.png"
// importing icons
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';  
// import AdminDataServices from "../adaminDataServices.js"; 


export const Employment = () => {

    const [regForm, setRegForm] = useState("a");

    // ____________________Form Data Hooks___________________
    // const [jsonOname, setjsonOname] = useState("");
    // const [admissionNumber, setAdmissionNumber] = useState("");
    // const [institutionalName, setInstitutionalName] = useState("");
    // const [address, setAddress] = useState("");
    const [jsonFname, setjsonFname] = useState("");
    const [jsonQualification, setQualification] = useState("");
    const [jsonTitle, setTitle] = useState("");
    const [jsonProgrammes, setProgrammes] = useState("");
    const [jsonContry, setCountry] = useState("");
    const [jsonState, setState] = useState("");
    const [jsonGender, setGender] = useState("");
    const [jsonEmail, setEmail] = useState("");
    const [jsonTel, setTel] = useState("");
    const [jsonPassword, setjsonPassword] = useState(""); 
    const [jsonUserName, setUserName] = useState("");
    const [jsonAbout, setjsonAbout] = useState("");
// _______________________________________________________

  
function submitRegForm(){
    const data = {
        jsonFname,
        jsonEmail,
        jsonPassword
    }
    // AdminDataServices.employeStaff(data)
    // .then((response) => {
    //     setRegForm("successful")
    //     console.log(response.data)
    // }) .catch (err => console.log(err))
}
    

    function switchBtn() {
        switch (regForm) { 
            case "b" :
                return  <Link style={regBtn} className=" cw fs3 br6 pd-btlx" onClick={() => {setRegForm("c")}}>Continue</Link> ;
            case "c" : 
                return <button style={regBtn} className=" cw fs3 br6 pd-btlx pd6" onClick={submitRegForm}>submit</button> ; 
            default : 
                return  <Link style={regBtn} className=" cw fs3 br6 pd-btlx" onClick={() => {setRegForm("b")}}>Next</Link>  
            ;
            break;
        }
    }
    const regBtn = {backgroundColor: "#0db8a8", width: "45%", textAlign: 'center', }

    return (
        <section className="sectionRegistratoin ">
            { regForm === "successful" ? <div style={{height: "100vh", backgroundColor: "#fff"}}>Register Successfully</div> :
            <div className="g pd5">
            <div className="regDisc pd2 fx-jc fx-cl fx-ac space2">
                <figure className="logo"> 
                    <img src={Logo} alt="netclass" />
                </figure>
                <h3 className="fs8">Academic Registration</h3>
                <p className="fs4"> Lorem ipsum dolor sit amet consectetur adipisicing. <br />
                dolor sit amet consectetur adipisicing.</p> 
                <Link to="/learner_login" style={regBtn} className=" bd-w cw fs3 br6 pd-btlx">SIGN IN</Link>
            </div><div className="loginformCont pd2  ">  
<div className="regFormCont fx-ac fx-jc">
    <form  className="fx-cl fx-ac space3"
        style={{ width: "75%", padding: "2.4rem"}}>
        <h3>Company Employment Form</h3> 
        {regForm ==="a" ?
         <figure className="RegForm fx-cl space2">
         <section className="formTitle fx-ac space2">
             <span>
                 <select name="jsonTitle" onChange={event => setTitle(event.target.value)}>
                     <option value="" hidden >Title</option>
                     <option value="Professor">Professor</option>
                     <option value="Dr">Dr.</option>
                     <option value="Doctor">Doctor</option>
                     <option value="Comrade">Comrade</option>
                     <option value="Mr,">Mr</option>
                     <option value="Miss">Miss</option> 
                 </select>
             </span>
             <span>
                 <select name="jsonGender" onChange={event => setGender(event.target.value)}> 
                     <option value="" hidden>Gender</option>
                     <option value="Male">Male</option>
                     <option value="Female">Female</option>
                     <option value="others">Others</option> 
                 </select>
             </span>
         </section>

     <div>
         <Person2OutlinedIcon style={{fontSize: "2.4rem", color: "#999"}} />
         <input value={jsonFname} onChange={event => setjsonFname(event.target.value)} name="jsonFname" type="text" placeholder="Employee..."/>
     </div>
     <div > <Person2OutlinedIcon style={{fontSize: "2.4rem", color: "#999"}} />
         <input value={jsonEmail} onChange={event => setEmail(event.target.value)} name="jsonEmail" type="email" placeholder="email address..."/>
     </div> 
     <div >
         <LockOutlinedIcon  style={{fontSize: "2.4rem", color: "#999"}}/>     
         <input value={jsonPassword} onChange={event => setjsonPassword(event.target.value)} name="jsonPassword" type="password" placeholder="password"/>
     </div> 
 </figure>
        : null} 
        {regForm ==="b" ?
        <figure className="RegForm fx-cl space2">
        <section className="formTitle fx-ac space2">
                <span>
                    <select name="jsonContry" onChange={event => setCountry(event.target.value)}>
                        <option value="" hidden>Country</option>
                        <option value="naijeriya">Nigeria</option>
                        <option value="saudia">Madina</option> 
                    </select>
                </span>
                <span>
                    <select name="jsonState" onChange={event => setState(event.target.value)}>
                        <option value="">State</option>
                        <option value="abuja">Abuja</option>
                        <option value="kwara">kwara</option> 
                    </select>
                </span>
            </section>
        <div>
            <Person2OutlinedIcon style={{fontSize: "2.4rem", color: "#999"}} />
            <input value={jsonQualification} onChange={event => setQualification(event.target.value)} name="jsonQualification" type="text" placeholder="Qualification..."/>
        </div>
        <div > <Person2OutlinedIcon style={{fontSize: "2.4rem", color: "#999"}} />
            <input value={jsonProgrammes} onChange={event => setProgrammes(event.target.value)} name="jsonProgrammes" type="text" placeholder="Programm..."/>
        </div> 
        <div >
            <LockOutlinedIcon  style={{fontSize: "2.4rem", color: "#999"}}/>     
            <input value={jsonUserName} onChange={event => setUserName(event.target.value)} name="jsonUserName" type="text" placeholder="create user name.."/>
        </div> 
    </figure>
        : null}
        {regForm ==="c" ?
        <figure className="RegForm fx-cl space2">
        <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae repellat quasi animi aliquid saepe repellendus?</h1>
        <textarea  value={jsonAbout} onChange={event => setjsonAbout(event.target.value)} className="bd-f3 br1 fs2" name="jsonAbout" id="" cols="30" rows="10" placeholder="About your mission..." ></textarea>
    <div style={{backgroundColor: "#fafaff"}}>
        <Person2OutlinedIcon style={{fontSize: "2.4rem", color: "#999"}} />
        <input value={jsonTel} onChange={event => setTel(event.target.value)} name="jsonTel" type="number" placeholder="phone number..."/>
    </div>
</figure>
        : null}
        <div className="fx-jc" style={{width: "100%"}}>
            {switchBtn()}
        </div>
        
    </form>
       
</div>
 </div> 
 </div>  } 
            
        </section>
    )
}