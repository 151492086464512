 
import { createContext, useContext, useState } from 'react';
import {AuthenticationComp} from './Authentication'; 
import {SnackbarProvider} from "notistack"
import './App.css';

const NightMode = createContext();
export const Nigtmode = () => useContext(NightMode);

function App() {
  const [isnightmode, setIsnightmode] = useState(false);
  return (
    <NightMode.Provider value={{isnightmode}}>
    <SnackbarProvider>

      <section className="App"> 
        <AuthenticationComp /> 
      </section>
      
    </SnackbarProvider>
    </NightMode.Provider>
  );
}

export default App;
