import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"; 
import "./md_styles/md_profile.css"
// importing components
import { Authentication } from "../../Authentication.jsx";
import FilterSearch from "./profile_comp/FilterSearch";
import Busery from "./profile_comp/Busery";
import Loading from "../../Loading";

 
// Icons import
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import StarIcon from '@mui/icons-material/Star';

// ?_______________? 
import CastForEducationOutlinedIcon from "@mui/icons-material/CastForEducationOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"; 
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
 
export default function ProfileMD() {
  const [loading, setLoading] = useState(false)
  const redirect = useNavigate();
  const [active, setActive] = useState("dashboard"); 
  

    const {management, logoutMD} = Authentication();
    const ceoData = management.bioData;
    const companyData = management.clientsInfo;
    console.log(ceoData)
    console.log(companyData)
    
  
 
 function AsideItem(props) {
    return (
      <span style={{ width: "90%" }}>
        <Link
          to={props.link}
          className={` itemsPf cb fx-ac  space1 ${
            active == `${props.hooks}` ? "active-prl-tab" : null
          }`}
          onClick={() => {
            setActive(`${props.active}`);
          }}
          style={{ padding: ".7rem 1.8rem" }}
        >
          {props.icon}
          {props.img}

          <span>{props.name}</span>
        </Link>
      </span>
    );
  }

  function AsideBottomItem(props) {
    return (
      <span style={{ width: "90%" }}>
        <Link
          to={props.link}
          className={` itemsPf cb fx-ac  space1`}
          style={{ padding: ".7rem 1.8rem" }}
        >
          {props.icon}
          {props.img}
          <span>{props.name}</span>
        </Link>
      </span>
    );
  }

  function switchComponents() {
    switch (active) {
      case "account":
        return <AccountComp />; 
      case "filters":
        return <FilterSearch />; 
      case "busery":
        return <Busery />; 
      case "activity":
        return <ActivityComp />;  
      case "setting":
        return <AcctSetting />;
      default:
        return <DashBoardComp />;
        break;
    }
  }
 
  function logout() {
    logoutMD();
    redirect("/management_login");
  }
  // Components
  function DashBoardComp() {
    return (
      <div className="fx-cl space2">
        <div className="fx-ac dashBHead">
          <figure className="fx-cl ">
            <div className="fx-ac space1">
              <span><DashboardRoundedIcon />

              </span>
              <h2>All Clients</h2>
            </div>
            <span>Users: {companyData.all_clients}</span>
          </figure>
          <figure className="fx-cl ">
            <div className="fx-ac space1">
              <span>
              <DashboardRoundedIcon />
              </span>
              <h2>Active Clients</h2>
            </div>
            <span>Status: {companyData.activated_accounts} </span>
          </figure>
          <figure className="fx-cl ">
            <div className="fx-ac space1">
              <span><DashboardRoundedIcon />
              </span>
              <h2>Clients on Live</h2>
            </div>
            <span>Status: {companyData.clients_on_live}</span>
          </figure>
          <figure className="fx-cl ">
            <div className="fx-ac space1">
              <span><DashboardRoundedIcon />
              </span>
              <h2>Pending Clients</h2>
            </div>
            <span>Status: {companyData.all_clients - companyData.activated_accounts} </span>
          </figure>
        </div>
        <div className="fx-ac space3 statusContainer">
          <div className="statusBar fx-cl space3">
            <h3>General High Score</h3>
            <div className="highScore fx-cl ">
              <div className="highScoreChart g g3" style={{ width: "90%" }}>
                <div
                  className="g g2"
                  style={{ alignItems: "end", position: "relative" }}
                >
                  <div style={{ borderRight: "1px solid #ccc", height: "50%" }}>
                    &nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <figure style={{ backgroundColor: "blue" }}>{companyData.cbtLives.army}</figure>
                </div>
                <div
                  className="g g2"
                  style={{
                    borderLeft: "1px solid #ccc",
                    borderRight: "1px solid #ccc",
                    alignItems: "end",
                    position: "relative",
                  }}
                >
                  <div style={{ borderRight: "1px solid #ccc", height: "50%" }}>
                    &nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <figure style={{ backgroundColor: "blue" }}>{companyData.cbtLives.navy}</figure>
                </div>
                <div
                  className="g g2"
                  style={{ alignItems: "end", position: "relative" }}
                >
                  <div style={{ borderRight: "1px solid #ccc", height: "50%" }}>
                    &nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <figure style={{ backgroundColor: "blue" }}>{companyData.cbtLives.airforce}</figure>
                  <figure style={{ backgroundColor: "blue" }}>{companyData.cbtLives.jamb}</figure>
                  <figure style={{ backgroundColor: "blue" }}>{companyData.cbtLives.neco}</figure>
                  <figure style={{ backgroundColor: "blue" }}>{companyData.cbtLives.waec}</figure>
                  <figure className="last" style={{ backgroundColor: "green" }}>
                  {companyData.cbtLives.police}
                  </figure>
                </div>
              </div>

              <div className="g g4">
                <span>Army</span>
                <span>Navy</span>
                <span>Airforce</span>
                <span style={{ textAlign: "center" }}>Police</span>
              </div>
            </div>
          </div>
          <div className="bannerClient fx-ac ">
            <figure className="fx-cl fx-ac space1 bannerIcon">
              <div style={{ width: "7rem" }}>{ceoData.fisrt_Name.slice(0,1)}
              </div>
              <Link className="bnBtn">Get it now</Link>
            </figure>
            <div className="bannerDisc fx-cl space1">
              <h3>Vulnarable Certificates</h3>
              <div className="fx-cl ">
                <p>My list is on the way</p>
                <p>My list is on the way</p>
                <p>My list is on the way</p>
                <p>My list is on the way</p>
              </div>
            </div>
          </div>
        </div>
        <div className="g g3 profileCardsConts">
          <div className="dashBCard a fx-cl ">
            <h6>Discovery</h6>
            <div className="fx-ac space1">
              <figure>{ceoData.fisrt_Name.slice(0,1)}
              </figure>
              <div className="fx-cl  space">
                <p className="fx-ac space">
                  <span>
                    <em>Last scan</em>: November 2023
                  </span>
                </p>
                <p className="fx-ac space">
                  <span>
                    <em>Status</em>: Finished
                  </span>
                </p>
              </div>
            </div>
            <div className="fx-ac sts space1">
              <figure>&nbsp;</figure>
              <div className="fx-cl  space">
                <p className="fx-ac space">
                  <span>
                    <strong>4</strong> New Certificates
                  </span>
                </p>
                <p className="fx-ac space">
                  <span>
                    <strong>1</strong> New Network
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="dashBCard cn fx-cl">
            <h6>Key/Hash algorithm</h6>
            <div className="fx-ac space1">
              <figure>asfasdf
              </figure>
              <div className="fx-cl  space">
                <p className="fx-ac space">
                  <span>&nbsp;</span>
                  <span>Hash</span>
                </p>
                <p className="fx-ac space">
                  <span>&nbsp;</span>
                  <span> Keys</span>
                </p>
              </div>
            </div>
          </div>

          <div className="dashBCard c fx-cl">
            <h6>By key size</h6>
            <div className="fx-ac space1">
              <figure>3333
              </figure>
              <div className="fx-cl  space">
                <p className="fx-ac space">
                  <span>&nbsp;</span>
                  <span>Hash</span>
                </p>
                <p className="fx-ac space">
                  <span>&nbsp;</span>
                  <span> Keys</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  function AccountComp() {
    return (
      <div>
        <div
          className="fx-ac  space2"
          style={{
            backgroundColor: "#fafaff",
            padding: "3.2rem",
            borderRadius: "1.2rem",
            alignItems: 'flex-start'
          }}
        >
          <div className="accountleft fx-cl space1">
            <div className="fx-cl space2 imgCont">
              <figure className="accountImage">{ceoData.fisrt_Name.slice(0,1)}</figure>
              <button className="fx-ac space1">
                <SettingsOutlinedIcon /> <span>Edit account</span>
              </button>
            </div>
            <div className="fx-cl space2">
              <span className="fx-ac space1 fs1">
                <span>WORKS</span>
                <div style={{ borderBottom: "1px solid #ddd", width: "100%" }}>
                  &nbsp;
                </div>
              </span>

              <div className="fx-cl space">
                <h3 className="fx-ac fx-jb space">
                  <span>Computer Stuff!</span>
                  <Link className="acctLink">Primary</Link>
                </h3>
                <span className="fs1 asDisc">
                  190 Fodio Street <br /> Wamakkao Local Government Sokoto
                </span>
              </div>
              <div className="fx-cl space">
                <h3 className="fx-ac fx-jb space">
                  <span>Training Stuff!</span>
                  <Link className="acctLink">Secondary</Link>
                </h3>
                <span className="fs1 asDisc">
                  190 Fodio Street <br /> Wamakkao Local Government Sokoto
                </span>
              </div>
            </div>
            <div className="fx-cl space2">
              <span className="fx-ac space1 fs1">
                <span>Skills</span>
                <div style={{ borderBottom: "1px solid #ddd", width: "100%" }}>
                  &nbsp;
                </div>
              </span>

              <div className="fx-cl space fs1">
                <p className="fx-ac fx-jb space">
                  <span>Some skills over!</span>
                </p>
              </div>
            </div>
          </div>
          <div className="fx-cl space3">
            <ul className="fx-jb">
              <li className="fx-cl"> 
              <span className="fs6"> {ceoData.fisrt_Name + " "+ ceoData.other_Name} </span> 
              <span style={{ fontSize: "1.1rem", color: "#2d5ecf" }}>Nigerian Airforce</span> 
              </li> 
            </ul>
            <ul className="fx-jb">
              <li className="fx-cl"> 
              <span style={{ fontSize: "1.2rem", color: "#999" }}>RANKING</span> 
              <span className="fs4 fx-ac space"><strong>8,1</strong> <span className="fx-ac space" style={{color: '#6693fa'}}>
                <StarIcon/>
                <StarIcon/>
                <StarIcon/>
                <StarIcon style={{color: '#ccc'}}/>
                <StarIcon style={{color: '#ccc'}}/>
                </span></span> 
              </li> 
            </ul>
            <ul className="fx-cl space2 userDbData">
              <li>
                <span className="fx-ac space1 fs1">
                  <span style={{ fontSize: "1.1rem", color: "#999" }}>
                    CONTACT-INFORMATION
                  </span>
                </span>
              </li>
              <li>
                <span>Other name</span> <span>{ceoData.other_Name}</span>
              </li>
              <li>
                <span>Email address</span> <span style={{color: '#2d5ecf'}}>{ceoData.email}</span>
              </li>
              <li>
                <span >Phone No.</span> <span style={{color: '#2d5ecf'}}>{ceoData.phoneNumber}</span>
              </li>
            </ul>

            <ul className="fx-cl space2 userDbData">
              <li>
                <span className="fx-ac space1 fs1">
                  <span style={{ fontSize: "1.1rem", color: "#999" }}>
                    BASIC-INFORMATION
                  </span>
                </span>
              </li>
              <li>
                <span>Title</span> <span>{ceoData.title}</span>
              </li>
              <li>
                <span>State</span> <span>{ceoData.location}</span>
              </li>
              <li className="fx-ac space2">
                <span>Gender</span> <span>{ceoData.gender}</span>
              </li>
            </ul>
          </div>
          <ul className="fx-jb">
              <li  > &nbsp;   
              </li>
              <li className="fx-cl"> 
              <Link style={{ fontSize: "1.1rem", color: "#2d5ecf", backgroundColor: '#d6ecfc', padding: '.5rem .9rem' }}>Edit settings</Link> 
              </li>
            </ul>
        </div>
      </div>
    );
  }
  function Comp3() {
    return (
      <div style={{ backgroundColor: "brown" }}>this is Component Funding</div>
    );
  }
 
 
  function ActivityComp() {
    return (
      <div style={{ backgroundColor: "yellowgreen" }}>
        this is Component Activity
      </div>
    );
  }

  function AcctSetting(props) {
   
    const [jsonPassword, setjsonPassword] = useState('')
    const [jsonEmail, setjsonEmail] = useState('')
    const [jsonState, setjsonState] = useState('')
    const [jsonTel, setjsonTel] = useState('')
    const [jsonExamsPassword, setjsonExamsPassword] = useState('')
    const [jsonExamsId, setjsonExamsId] = useState('')
    const [jsonUsername, setjsonUsername] = useState('')
    const [newMail, setNewMail] = useState('')
    const [updated, setUpdated] = useState('')

    async function submitAcctUpdate(){
      const _id ="dasdfasdf"
      const data = {
        jsonPassword,jsonEmail 
      }
      await axios.put(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/profile_setting/${_id}`) 
      .then((response)=> {
        setUpdated(true)
        console.log(updated)
      }) .catch(error =>{
        console.log(error)
      })
    }
    return (
      <div className="fx-cl " style={{backgroundColor: '#fafaff'}}>
         <h1>Account SEtting</h1>
         <form className="fx-cl space2"> 

          <input type="password" name="jsonPassword" placeholder="password.." 
          value={jsonPassword} onChange={(event)=> setjsonPassword(event.target.value)}/>
          <input type="email" name="jsonEmail" placeholder="new email address.." 
          value={jsonEmail} onChange={(event)=> setjsonEmail(event.target.value)}/>
          <button onClick={()=>submitAcctUpdate()} style={{width: '20%', backgroundColor: "yellow"}}>update account</button>
         </form>
      </div>
    );
  }
  return (
    <section className="sectionMDProfile">
      {
      loading ? <Loading /> : 
      <div className="instructorProfileCont ">
        <div className="profileAside fx-cl fx-as space1">
          <div className="profileAsideSec fs2 cwb fx-cl fx-as ">
            <div className="fx-cl space2" style={{ width: "100%" }}>
              <h2 className="compLogo">CompLogo</h2>
              <figure>
                <button className="fx-ac profBtn  space1">
                  <TipsAndUpdatesTwoToneIcon fontSize="large" />
                  <span>Video tutorial</span>
                </button>
              </figure>
            </div>
            <AsideItem
              name="Dashboard"
              icon={<DashboardRoundedIcon fontSize="large" />}
              hooks="dashboard"
              active="dashboard"
            />
            <AsideItem
              name="Filters"
              icon={<CategoryRoundedIcon fontSize="large" />}
              hooks="filters"
              active="filters"
            />
            <AsideItem
              name="Busery"
              icon={<AssignmentRoundedIcon fontSize="large" />}
              hooks="busery"
              active="busery"
            />
            <AsideItem
              name="Account"
              icon={<PeopleAltRoundedIcon fontSize="large" />}
              hooks="account"
              active="account"
            />

            <figure className="prfLinksto fx-ac space2 fx-jb">
              <span>Enventory</span>
              <span>Edits</span>
            </figure>
            <AsideItem
              name="Setting" 
              active="setting"
              onClick={()=>setActive("setting")}
            />
            <AsideBottomItem
              name="Records" 
              active="records"
            />
            <AsideBottomItem
              name="Feedback" 
              active="feedback" 
              link="/feedback"
            />

            <button
              className=" logout_profile fx-ac space1"
              onClick={() => logout()}
            >
              <LogoutOutlinedIcon />
              <span>Logout</span>
            </button>
          </div>
        </div>
        <div className="profileHeader fx-ac fx-jb">
          <span className="fx-ac fx-jc space1">
            <span>
              <Link to="/cbt" className="fx-ac fx-jc space1">
                <CastForEducationOutlinedIcon fontSize="large" />
                <span>Computer Base Test</span>
              </Link>
            </span>
          </span>
          <span className="fx-ac fx-jc space3">
            <span className="notifBtn fx-ac fx-jc space1">
              <NotificationsActiveOutlinedIcon fontSize="large" />
              <figure>2</figure>
            </span>
            <span className="fx-ac fx-jc space1">
              <Link
                className="fx-ac space1"
                onClick={() => setActive("account")}
              >
                <p>{ceoData.fisrt_Name}</p>
                <figure className="dp fx-ac">
                {ceoData.fisrt_Name.slice(0,1)}
                </figure>



                
              </Link>
            </span>
          </span>
        </div>
        <div className="profileContents">{switchComponents()}</div>
      </div> 
      }
    </section>
 
  );
}





