import { Link } from "react-router-dom";
import "./public_routes_css/home_page.css"
// imports of the components
import HeroSection from "./homepage/HeroSection";
import HomeHeader from "./homepage/HomeHeader";
import HomeInformation from "./homepage/HomeInformation"; 
import CTAComp, {CTAComp2} from "./homepage/CTAComp"; 
import HomeInfoComp from "./homepage/HomeInfoComp";
import Featured from "./homepage/Featured";
import Footer from "./homepage/Footer";  
import { Nigtmode } from "../../App.js"; 

function HomePage() {
    const {isnightmode} = Nigtmode(); 
    

    return (
        <section className="sectionHomePage">   
        <div className="homebody"> 
        <HeroSection />
        <HomeHeader /> 
        <CTAComp2/>
        <HomeInformation />   
        <CTAComp/>
        <HomeInfoComp /> 
        <Featured /> 
        <Footer />
        </div>
        </section>
    )
}

export default HomePage;