import { Link } from "react-router-dom";

export default function ManagementLoginCate() {
    return(
        <section className="sectionLoginCate">
            <h2>Management login Categories here</h2>
            <span><Link to="/management_login">Management</Link></span> <br />
            <span><Link to="/admin_login">Staff </Link></span>
        </section>
    )
}