import { useState } from "react"
import Loading from "../../Loading"
import axios from "axios"
import "./feedback.css"
import { Authentication } from "../../Authentication"
import { useNavigate } from "react-router-dom" 



export default function FeedBack() {
  const redirect = useNavigate()
  const [loading, setLoading] = useState(false)
  const {client} = Authentication()
  const [jsonFullname, setFullName] = useState('')
  const [jsonEmail, setjsonEmail] = useState('')
  const [JsonMessage, setJsonMessage] = useState('')
  const data = {
    jsonFullname,
    jsonEmail,
    JsonMessage
  } 
  async function submitFeedBack() {
    setLoading(true)
    
    await axios.post(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/feedback/${client.bioData._id}`, data)
    .then((response) => {
        setLoading(false)
       redirect("/client_profile")
      }).catch(err => console.log(err))
  }

  return (
    <section className="sectionFeedback fx-ac fx-jc">
        {loading ? <Loading/> : null}
      <div className="feedBackCont fx-jc space3">
        <div className="fx-cl space3 feedBackDiscription">
          <h2>Small Pricing Plan for your Creative Bussiness </h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea pariatur doloremque qui alias? Illo tempora mollitia incidunt dolore dolorum ut, repellat libero?</p>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea pariatur doloremque qui alias? Illo tempora mollitia incidunt dolore dolorum ut, repellat libero?</p>
          <button>say somtheing</button>
        </div>  
      <form action="" className="feedbackForm fx-cl space1">
        <h1>Give us feed back!!</h1>
        <input type="text" name="jsonFullname" value={jsonFullname} onChange={(event) => {
          setFullName(event.target.value)
        }} placeholder="Full Name.."  />
        <input type="text" name="jsonEmail" value={jsonEmail} onChange={(event) => {
          setjsonEmail(event.target.value)
        }} placeholder="Email address.." />
        <textarea name="JsonMessage" id="" cols="30" rows="7" value={JsonMessage} onChange={(event) => {
          setJsonMessage(event.target.value)
        }} placeholder="Your message to us.."></textarea>
        
        <button onClick={() => submitFeedBack()}>Submit</button>
      </form>
      </div>
    </section>
  )
} 