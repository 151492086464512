import {Link} from "react-router-dom"
import Neco from "./home_pictures/logos/neco.png"
import Img2 from "./home_pictures/cbt.png"
import Img3 from "./home_pictures/ac.png"
import PLC from "./home_pictures/logos/police.png"



export default function HomeInformation() {
    return(
        <section className="fx-jc fx-ac" id="zPattern">
            <div className="homeInfoCont g g2">
                <div className="fx-cl ">
                    <h3 className="fs5 cblue">The Nigerian Police Force</h3>
                    <h2 className="fs7">Get yourself Ready for <br />CBT exams</h2>
                    <p className="infoPag fs5">
                        The Applicants into the Nigerian Police Force now have the CBT sofware to 
                        make their salves ready for the Police CBT Exams, start your training programme.
                    </p>
                    <Link to="/client_reg_form" className="homeLink cw ">Let's begins</Link>
                </div>
                <div className="fx-ac fx-jc">
                    <img style={{width: "60%"}} src={PLC}  alt="asdf" />
                </div>
                <div className="fx-ac fx-jc">
                    <img style={{
                        width: "99%", borderRadius: "1.2rem", border: "5px solid #fff", 
                        boxShadow: "1rem 1rem 2rem 0 rgba(0, 0, 0, 0.35)"

                }} src={Img2}  alt="asdf" />
                </div>
                <div className="fx-cl ">
                    <h3 className="fs5 cblue">FORCES APPLICATIONS</h3>
                    <h2 className="fs7">Armed Forces Exams Ready!!</h2>
                    <p className="infoPag fs5">
                       Applicants into the Armed forces and other uniform agency can 
                       take a perfect training on how to use the software for the entrance 
                       test and screening examinations along with the questoins of those forces
                        including, Army, Navy,  Airforce, Police and others
                    </p>
                    <Link to="/client_reg_form" className="homeLink cw ">Register now</Link>
                </div>
                
                
                <div className="fx-cl ">
                    <h3 className="fs5 cblue">SSCE into the Technology</h3>
                    <h2 className="fs7">O-Levels Computer Base Test</h2>
                    <p className="infoPag fs5">
                     WAEC, NECO and other SSCE are currently converting to the Computer Base Test Examinations.
                     Learn operating Computer Base Test Software by taking your training with us,
                     we provides you with the mostly asked questions of the SSEC Exams which you will be using 
                     during the training proramms
                    </p>
                    <Link to="/client_reg_form" className="homeLink cw ">Let's get started</Link>
                </div>
                <div className="fx-ac fx-jc">
                    <img style={{width: "60%"}} src={Neco}  alt="asdf" />
                </div>
               
            </div>
        </section>
    )
}
 
