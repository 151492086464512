import { useReducer, useState } from "react";
import { Authentication } from "../../Authentication.jsx";
import Loading from "../../Loading.jsx";
import { Link, useNavigate } from "react-router-dom";
import "./public_routes_css/login.css"; 
import "../public_directory/public_routes_css/loginStyles.css"

// importing logo
import Logo from "./jood.png";
// importing icons
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios"; 

let userData;
let ceoData;

export default function ManagementLogin() {
    // const [mDData, setmDData] = useState('myData'); 
    const { loginMD } = Authentication();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage]= useState('');
    const [networkError, setNetworkError] = useState('')
    const [mDLoginData, setmDLoginData] = useReducer(
      (reqest, response) => {
        return { ...reqest, ...response };
      },
      { userName: "", password: "" }
    );

    const [loading, setloading] = useState(false)
    const data = {
      mdLgUsername: mDLoginData.userName,
      mdLgPassword: mDLoginData.password,
    };  
      

    async function executeLogin() { 
      setloading(true)
   
      await axios
        .get(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/login_data`, data)  
        .then(async (response) => { 
       await axios.get(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/all_clients`) 
        .then((clientResponse => { 
          userData = clientResponse.data.data; 
          console.log(userData)
        })) .catch(e => console.log(e))
          setloading(false)
          ceoData = response.data.info;
          loginMD(ceoData, userData,  mDLoginData.password); 
          console.log(ceoData)
          if(ceoData == "*"){
            setErrorMessage(ceoData)
            console.log(errorMessage)
          } else return null;
          })
        .catch((err) => {
          setloading(true)
          setNetworkError(err.message + ": please check your connection and try again")
          setError(true)
          setloading(false)
        }
        );
        
    };
  
   
    
    return (
      <section className="sectionLogin sectionMDLogin">
        <div className="loginCont fx-ac">
          <div className="loginDiscription arm fx-cl space1 ">
            <div className="fx-cl fx-ac">
              <figure>
                <img src={Logo} alt="netclass" />
              </figure>
              <h2>Company <br /> Management Route</h2>
            </div>
            <p>
              Note: this page is only for the Company management, client or admin
               staffs are not allow here, if you find your self here, click here to 
               go back to <Link to="/" style={{color: "green", fontSize: "1.2rem", fontWeight: "900"}}>Home page</Link>
            </p>
             
          </div>
          <div className="loginformCont">
            <div className="loginForm">
              <div
                className="fx-cl fx-ac fx-jc space1"
                style={{ textAlign: "center", marginBottom: "2.4rem" }}
              >
                <span style={{ fontWeight: "600", color: '#333' }}>www.joods.com</span>
                <h4 className="fs6">The General Manager</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Explicabo, assumenda molestias.
                </p>
              </div>
              <div className="loginInputWrap">
                <Person2OutlinedIcon
                  style={{ fontSize: "2.4rem", color: "#fff" }}
                />
                <input
                  name="mdLgUsername"
                  value={mDLoginData.userName}
                  onChange={(event) =>
                    setmDLoginData({ userName: event.target.value })
                  }
                  type="text"
                  placeholder="enter username"
                />
              </div>
              <div className="loginInputWrap">
                <LockOutlinedIcon style={{ fontSize: "2.4rem", color: "#fff" }} />
                <input
                  name="mdLgPassword"
                  value={mDLoginData.password}
                  onChange={(event) =>
                    setmDLoginData({ password: event.target.value })
                  }
                  type="password"
                  placeholder="enter password"
                />
              </div>
              <div>
                <button onClick={()=>executeLogin()}>Login</button>
                {error ? (
                  <div style={{ color: "#f35e6a", textAlign: "center", fontSize:'1.1rem', fontWeight:'600' }}>{networkError}</div>
                ) : (
                  <div style={{ color: "#f35e6a", textAlign: "center", fontSize:'1.1rem', fontWeight:'600' }}>{errorMessage.slice(0,1) == "*" ? errorMessage: null}</div>
                )}
              </div>
              <span style={{ textAlign: "center" }}>
                By signing up, you agree to the Terms of Service and Privacy
                Policy, including Cookie Use.
              </span>
              <div className="formButtom">
                <p className="fs1" style={{ textAlign: "center" }}>
                  don't have an account? &nbsp;
                  <span>
                    <Link to="/mD_reg_form" className="lgSnp">
                      <strong>Sigup now</strong>
                    </Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
          {loading ? <Loading/> : null}
        </div>
      </section>
    );
  };
