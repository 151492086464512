import { createSlice } from "@reduxjs/toolkit" 


export const resultReducer = createSlice({
    name : 'result',
    initialState : {
        userId : null,
        result : [],
        resultX : [],
        resultY : [],
    },
    reducers : {
        setUserId : (state,action) =>{
            state.userId = action.payload
        },
        pushResultAction : (state ,  action) =>{
            state.result.push(action.payload)
        },
        pushResultActionX : (state ,  action) =>{
            state.resultX.push(action.payload)
        },
        pushResultActionY : (state ,  action) =>{
            state.resultY.push(action.payload)
        },
        updateResultAction : (state, action) => {
            const {trace, checked} = action.payload;
            state.result.fill(checked,trace, trace + 1)
        },
        updateResultActionX : (state, action) => {
            const {traceX, checked} = action.payload;
            state.resultX.fill(checked,traceX, traceX + 1)
        },
        updateResultActionY : (state, action) => {
            const {traceY, checked} = action.payload;
            state.resultY.fill(checked,traceY, traceY + 1)
        },
        resetResultAction : () => {
            return {
                userId : null,
                result : [],
                resultX : [],
                resultY : [],
            }
        }
    }
}) 


 
export const {
    setUserId, 
    pushResultAction,
    pushResultActionX,
    pushResultActionY,
    updateResultAction,
    updateResultActionX,
    updateResultActionY,
    resetResultAction,
} = resultReducer.actions

export default resultReducer.reducer;