import React from 'react';
import ReactDOM from 'react-dom/client'; 
import './index.css'; 
import App from './App'; 
import {BrowserRouter} from "react-router-dom"

// redux store
import store from './components/clients_directory/profileImages/cbtExams/computer-base-test-storages/army/redux/store'
// import storearm from './components/clients_directory/profileImages/cbtExams/computer-base-test-storages/airforce/redux/store'
import { Provider } from 'react-redux';

// ____________________________________________________________
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </Provider>
); 
