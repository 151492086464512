import { createSlice } from "@reduxjs/toolkit"

// createing reducer with intial value
export const questionReducer = createSlice({
    name : 'questions',
    initialState : {
        current_subj: 0,
        // store questions and answers
        queue :[],
        queueX :[],
        queueY :[],
        answers : [],
        answersX : [],
        answersY : [],
     

        // trace is used to track user questions if user click next it increase trace value to 1
        trace : 0, 
        traceX : 0, 
        traceY : 0, 
    },
    // reducers allows you to specfiy and despatch an action and action allows you to change the value of store 
    reducers : {
        // using the action you can acces the user input
        // inside state you have cuurent state
        startExamAction : (state,action) => {
            let {question,answers, answersX, answersY} = action.payload
            return{
                // update intial state
                ...state,
                queue : question[0],
                queueX : question[1],
                queueY : question[2],
                answers : answers,
                answersX : answersX,
                answersY : answersY,
    
            }
        },
        movePaginationAction : (state, action) =>{
            let {e} = action.payload
            console.log(e)
            return{
                ...state,
                trace : e-1
            }
        },
        movePaginationActionX : (state, action) =>{
            let {e} = action.payload
            console.log(e)
            return{
                ...state,
                traceX : e-1
            }
        },
        movePaginationActionY : (state, action) =>{
            let {e} = action.payload
            console.log(e)
            return{
                ...state,
                traceY : e-1
            }
        },
        moveNextAction : (state) =>{
            return{
                ...state,
                trace : state.trace +1
            }
        },
        moveNextActionX : (state) =>{
            return{
                ...state,
                traceX : state.traceX +1
            }
        },
        moveNextActionY : (state) =>{
            return{
                ...state,
                traceY : state.traceY + 1
            }
        },
        movePrevAction : (state) =>{
            return{
                ...state,
                trace : state.trace - 1
            }
        },
        movePrevActionX : (state) =>{
            return{
                ...state,
                traceX : state.traceX - 1
            }
        },
        movePrevActionY : (state) =>{
            return{
                ...state,
                traceY : state.traceY - 1
            }
        },
        resetAllAction : () =>{
            return{
                queue :[],
                queueX :[],
                queueY :[],
                answers : [],
                answersX : [],
                answersY : [],
                trace : 0, 
                traceX : 0, 
                traceY : 0, 
            } 
        } 

    }
})

export const {startExamAction,
     moveNextAction,
     movePrevAction,
     moveNextActionX,
     movePrevActionX,
     moveNextActionY,
     movePrevActionY,
     resetAllAction,
     movePaginationAction,
     movePaginationActionX,
     movePaginationActionY,
    } = questionReducer.actions;

export default questionReducer.reducer;