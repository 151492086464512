
import loadingImage from "./roller.png"
// import loadingImage from "./jood.png"
import "./loading.css"

export default function Loading(){
    return(
        <section className="sectionLoading fx-ac fx-jc" style={{position: 'absolute', backgroundColor: 'rgba(0,0,89,0.35)', width: "100%", height:"100%", zIndex: "9999999"}}>
            <div className="fx-cl fx-ac" >
                <figure className="asdf fx-ac fx-jc" style={{width: "12rem", height: "12rem"}}><img src={loadingImage} alt="loading" style={{width: "80%"}}/></figure>
            <h1 style={{  color: "#fff", fontSize: "1.8rem", fontWeight: '800'}}>Loading contents...</h1></div>
            </section>
    )
}