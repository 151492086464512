import { useEffect, useState } from "react"
import axios from "axios"
import "./profile.comps.css"
import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RuleIcon from '@mui/icons-material/Rule';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff'; 

export default function FilterSearch(props) {
  const [filtersData, setFiltersData] = useState("")



  async function GetAprroval() {
    await axios.get(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/filterClents`)
      .then((response) => {
        setFiltersData(response.data)
        console.log(filtersData)
      }).catch(error => {
        console.log(error)
      })
  }

  useEffect((callback) => {
    GetAprroval();
  }, [])


  const FilterCard = () => {
    return (
      <figure className="filtersCard fx-cl space">
        <div className="fx-ac space1 fx-jb">
          <span>35iou2io345jkihi32i</span>
          <h6 className="priceAcc">N1,200</h6>
        </div>
        <div className="fx-ac fx-jb space3">
          <div className="fx-cl space1">
            <div className="fx-ac space"><span><AccountBalanceRoundedIcon /></span> <span> Zenith Bank Plc</span></div>
            <div className="fx-ac space"><span><SupervisorAccountIcon /></span> <span>Othman Omar Dikko</span></div>
          </div>

        </div>
        <figure className="fx-ac fx-jc space1">
          <button style={{ color: "#00d285" }}><DoneAllIcon /></button>
          <button style={{ color: "#f35e6a" }}><SubtitlesOffIcon /></button>
          <button style={{ color: "#6693fa" }}><RuleIcon /></button>
          <div>
            <button className="fx-ac space" style={{ backgroundColor: "#00d285", padding: '.6rem 1.4rem', borderRadius: "1.2rem", color: '#fff' }}><span>accept</span></button>
          </div>
        </figure>
      </figure>
    )
  }

  return (
    <div>
      <div className="forceCont ">
        <div className="forceImageSlide fx-cl space2">
          <div className="searchfilter">
            <div className="filterSearchCont fx-ac space2">
              <select name="" id="">
                <option value="By name">By name</option>
                <option value="By location">By location</option>
                <option value="By email">By email</option>
                <option value="By username">By username</option>
                <option value="By phone">By phone</option>
              </select>
              <input type="search" name="filterSearch" id="" />
              <button><TravelExploreRoundedIcon style={{ fontSize: '2.4rem' }} /></button>
            </div>
            <div className="filtersCardsCont g g3 space3">
              <FilterCard />
              <FilterCard />
              <FilterCard />
              <FilterCard />
              <FilterCard />
              <FilterCard />
              <FilterCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 