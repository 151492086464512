import "./terms.css";
import { Link } from "react-router-dom";
 




export const TermsOfService = () => {
  return ( 
    <section className="sectionTerms fx-ac fx-jc">
    <div className="fx-cl space4 mainCont">
    <header>
        <h2 style={{color: "blue", fontSize: "4.5rem", fontWeight: "900"}}>
            Terms of Service
        </h2>
    </header>
  <div className="fx-cl space4 termsCont">
  <div className="fx-cl space1">
      <h1>Company Terms of services</h1>
      <p>
        Jools software provides computer base test training in 60 days after
        which the client have to re-activate the needed categories. every
        category activated would be working for the period of 60 days of  actvie date but your profile and account continue to exist, meaning
        you can login to your dashboard and navigates everywhere in your
        account except taking the computer base test CBT training,
      </p>
    </div> 
    <div className="fx-cl space1">
      <h2>Activation</h2>
      <p>
        The training hub require funding to be in active mode, every cbt
        hube cost N950 only,
        activation is only done via 
        <strong>
          dashboard &rarr; categories &rarr; payment &rarr; confirm
        </strong>
      </p>
      <p>
        please note that We donot accept payment outside our website,
        therefore we are not responsible for any kind of transaction made
        outside this website, we provide account number in the categories
        payment where you gonna transfer only 950 Naira
      </p>
      <p>
        cleints whom made payment of not equal to the amount required, then
        his/her request is not going to be activated until he/she sends the
        remaining amount in lesss than 7 days, <br />
    
          Please note: if client did'nt send the remaining amount within 7
          days, then he is not going to be attended for that particular
          transaction.  
      </p>
    </div> 
    <div className="fx-cl space1">
        <h2>Refunding</h2>
      <p>
      Payment is non refundable, therefore, only clients that needed Our services
        can purchase them.
        Note that clients are not allow to send more than the price of the
        services he/she is in need, nor sending less than the cost of
        services, make sure that you have send exact cost. <br /> don't have an account?
        <Link to="/client_reg_form"> click here to register</Link> <br /> go back to 

    <Link to="/" > home page</Link>
      </p> 
    </div> 
 
  </div>
    </div>
</section>
  );
};
