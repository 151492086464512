import { useReducer, useState } from "react";
import { Authentication } from "../../Authentication.jsx";
import Loading from "../../Loading.jsx";
import { Link } from "react-router-dom";
import "./public_routes_css/login.css"; 
import {useSnackbar} from "notistack" 
// importing logo
import Logo from "./jood.png";
// importing icons
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";

let userData;
export const ClientLogin = () => {

  const {enqueueSnackbar} = useSnackbar()
  // const [clientData, setClientData] = useState('myData'); 
  const { loginClient } = Authentication();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage]= useState('');
  const [networkError, setNetworkError] = useState('')
  const [clientLoginData, setClientLoginData] = useReducer(
    (reqest, response) => {
      return { ...reqest, ...response };
    },
    { userName: "", password: "" }
  );
  const [loading, setloading] = useState(false)

  const executeLogin = () => {
    setloading(true)
    const data = {
      jsonLoginUsername: clientLoginData.userName,
      jsonLoginPassword: clientLoginData.password,
    };
    // /login_state/:id
    axios
      .post(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/client_login`, data)
      .then(async (response) => { 
        await axios.put(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/login_state/${response.data.info._id}`)
        setloading(false)
        userData = response.data.info; 
        loginClient(userData, clientLoginData.password); 
        if(userData.slice(0,1)
         == "*"){
          setErrorMessage(userData) 
          enqueueSnackbar("Error login", {variant: "error"})

        }  else {enqueueSnackbar("Login success!", {variant: "success"}); };
        
        })
      .catch((err) => {
        setloading(true)
        setNetworkError(err.message + ": please check your connection and try again")
        setError(true)
        setloading(false)
      }
      ); 
      
  };

 
  return ( 
    <section className="sectionLogin ">
      <div className="loginCont fx-ac">
        <div className="loginDiscription arm fx-cl space1 ">
          <div className="fx-cl fx-ac">
            <figure>
              <img src={Logo} alt="netclass" />
            </figure>
            <h2>Jood Software Tech.</h2>
          </div>
          <p>
            We are working for you!!
          </p>
           
        </div>
        <div className="loginformCont">
          <div className="loginForm">
            <div
              className="fx-cl fx-ac fx-jc space1"
              style={{ textAlign: "center", marginBottom: "2.4rem" }}
            >
              <span style={{ fontWeight: "600", color: '#333' }}>JOOD SOFTWARE TECH.</span>
              <h4 className="fs6">WELCOME BACK!</h4>
              <p>
                Let's Continue  the training programm, enter your login details in the fields below.
              </p>
            </div>
            <div className="loginInputWrap">
              <Person2OutlinedIcon
                style={{ fontSize: "2.4rem", color: "#999" }}
              />
              <input
                name="jsonLoginUsername"
                value={clientLoginData.userName}
                onChange={(event) =>
                  setClientLoginData({ userName: event.target.value })
                }
                type="text"
                placeholder="Email address..."
              />
            </div>
            <div className="loginInputWrap">
              <LockOutlinedIcon style={{ fontSize: "2.4rem", color: "#999" }} />
              <input
                name="jsonLoginPassword"
                value={clientLoginData.password}
                onChange={(event) =>
                  setClientLoginData({ password: event.target.value })
                }
                type="password"
                placeholder="enter password"
              />
            </div>
            <div>
              <button onClick={()=>executeLogin()}>Login</button>
              {error ? (
                <div style={{ color: "#f35e6a", textAlign: "center", fontSize:'1.1rem', fontWeight:'600' }}>{networkError}</div>
              ) : (
                <div style={{ color: "#f35e6a", textAlign: "center", fontSize:'1.1rem', fontWeight:'600' }}>{errorMessage.slice(0,1) == "*" ? errorMessage: null}</div>
              )}
            </div>
            <span style={{ textAlign: "center" }}>
              By login, you agree to the Terms of Service and Privacy
              Policy, including Cookie Use.
            </span>
            <div className="formButtom">
              <p className="fs1" style={{ textAlign: "center" }}>
                don't have an account? &nbsp;
                <span>
                  <Link to="/client_reg_form" className="lgSnp">
                    <strong>Sigup now</strong>
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
        {loading ? <Loading/> : null}
      </div>
    </section>
  );
};
