export const data = [
  [
    {
      id: 1,
      question: " Which word means - effect; final act or result; consequence",
      options: [" hindsight", "paradox", "upshot."],
    },

    {
      id: 2,
      question:
        "Which word means - person who spends money extravagantly",
      options: ["archetype", "wastrel.", "epitome",],
    },

    {
      id: 3,
      question: "Which word means - disaster",
      options: ["autocracy", "blow", "calamity."],
    },

    {
      id: 4,
      question: "Which word means - to merge; to combine; to unite",
      options: ["amalgamate.", "evaporate", "solidify"],
    },

    {
      id: 5,
      question: ") Which word means - hard work; intense pain; toil",
      options: [" acrimony", "rancour", "travail."],
    },

    {
      id: 6,
      question: "Choose the word most similar in meaning to - striated",
      options: ["forgiving", "lined.", "friendly"],
    },

    {
      id: 7,
      question: "Choose the word most similar in meaning to - applause",
      options: ["evocation", "commendation", "acclaim."],
    },

    {
      id: 8,
      question: "Choose the word most similar in meaning to - unpretentious",
      options: [" Problematic", "modest.", "pragmatic"],
    },

    {
      id: 9,
      question:
        "Choose the word most similar in meaning to - rebuke",
      options: ["censure.", "implore", "denigrate"],
    },

    {
      id: 10,
      question: "Choose the word most similar in meaning to - nullify",
      options: [
        " establish",
        "transform",
        "invalidate.",
    ],
    },

    {
      id: 11,
      question: "Medicine is to illness as Law is to -------",
      options: [
        "express",
        "attend",
        " anarchy.",
    ],
    },

    {
      id: 12,
      question: "square is to cube as circle is to ------",
      options: [
        "round",
        "ball",
        "sphere.",
    ],
    },

    {
      id: 13,
      question: " king is to throne as judge is to -------",
      options: [
        "lawyer",
        "court",
        "bench",
    ],
    },

    {
      id: 14,
      question: "Nib is to pen as lens is to -------",
      options: [
        "seeing",
        "glass",
        "telescope.",
    ],
    },

    {
      id: 15,
      question: "Settlement is to injury as pension is to -------",
      options: [
        "aphorism",
        "retirement.",
        "pensioner",
    ],
    },

    {
      id: 16,
      question: " Which word means - symbol; metaphor",
      options: [
        "aphorism",
        "adage",
        "allegory.",
    ],
    },

    {
      id: 17,
      question:
        "Which word means - fear of foreigners or strangers",
      options: [
        " chauvinism",
        "xenophobia.",
        "magnanimity",
    ],
    },

    {
      id: 18,
      question:
        "Which word means - nobility; fairness; generosity",
      options: [
        "abstinence",
        "magnanimity.",
        "affluence",
      ],
    },

    {
      id: 19,
      question: "Which word means - shackle; hindrance",
      options: [
        "fetter.",
        "parody",
        "chauvinism",
    ],
    },

    {
      id: 20,
      question:
        "Which word means - official paper; deed; certificate",
      options: [
        "document.",
        "action",
        "closure",
    ],
    },

    {
      id: 21,
      question: "Which word is closely opposite to heed",
      options: [
        "ignore.",
        "attend",
        "converse",
    ],
    },

    {
      id: 22,
      question: "Which word is closely opposite to frustrate",
      options: [
        "mollify",
        "encourage.",
        "pacify",
    ],
    },
    {
      id: 23,
      question: "Which word is closely opposite to abandon",
      options: [
        "discover",
        "keep.",
        "recover",
     
      ],
    },

    {
      id: 24,
      question: "Which word is closely opposite to mordant",
      options: [
   
        "serene",
        "penetrating",
        "gentle.",
      ],
    },
    {
      id: 25,
      question: "Which word is closely opposite to dissonance",
      options: [
        "conformist", 
        "harmony.",
        "chord",
      ],
    },

    {
      id: 26,
      question: "Which word is closely opposite to refute",
      options: [
        "believe",
        "allow",
        "prove.",
     
      ],
    },

    {
      id: 27,
      question: "Which word is closely opposite to sedentary",
      options: [
        "exciting",
        "active.", 
        "wearisome",
      ],
    },

    {
      id: 28,
      question: "Which word is closely opposite to compromise",

      options: [
        "allowance", 
        "concession",
        "confrontation.",
      ],
    },

    {
      id: 29,
      question: "Which word is closely opposite to opulence",
      options: ["magnanimity",
      "moderation", 
      "poverty.",],
    },

    {
      id: 30,
      question: "Which word is closely opposite to deliberate",
      options: [
        "accidental.",
        "purposeful", 
        "intentional",
      ],
    },

    {
      id: 31,
      question: "paltry is to substantial as acute is to -------",
      options: [ "obtuse.", "redundant", "sharp"],
    },

    {
      id: 32,
      question: "chapter is to book as color is to -------",
      options: [
    
        " hue",
        " artist",
        "spectrum.",
      ],
    },

    {
      id: 33,
      question: "Liability is to asset as expenditure is to -------",
      options: [
        "payments",
        "benefit",
        "income.",
   
      ],
    },

    {
      id: 34,
      question: "sculptor is to marble as painter is to -------",
      options: [
        "artist",
        "canvas.",
        "easel",
 
      ],
    },
    {
      id: 35,
      question: "hold is to hatch as room is to -------",
      options: [
        "door.",
        "ship", 
        "window",
      ],
    },
    {
      id: 36,
      question: "storm is to calm as clear is to -------",
      options: ["cloudy.", "quiet", "cool"],
    },

    {
      id: 37,
      question: " freeze is to cool as soak is to -------",
      options: ["water", "liquid", "moisten."],
    },

    {
      id: 38,
      question: "volume is to litre as time is to -------",
      options: [
        "minute.", 
        "instant", 
        "period"],
    },

    {
      id: 39,
      question: "degree is to temperature as current is to -------",
      options: ["wire", "amp.", "ohm"],
    },

    {
      id: 40,
      question: "Identify the relation between the group of these words (magazine, armoury, barracks)",
      options: [
        "arsenal.",
        "station",
        "Police ",
    ],
    },

    {
      id:41,
      question: "Identify the relation between the group of these words (Jupiter, Saturn, Neptune)",
      options: [
        "sky",
        "plants",
        "earth.",
    ],
    },

    {
      id: 42,
      question: "Identify the relation between the group of these words ( gate, hatch, door)",
      options: [
        "trap.",
        "floor",
        "fence",
    ],
    },

    {
      id: 43,
      question: "Identify the relation between the group of these words ( wheel, turn, swing)",
      options: [
        "component",
        "veer.",
        "gear",
    ],
    },

    {
      id: 44,
      question: "Identify the relation between the group of these words ( Mormonism, Christianity, Judaism)",
      options: [
        "Islamic.",
        "Hinduism",
        "Atheism",
    ],
    },

    {
      id: 45,
      question: "Identify the relationship between the word pair in the bracket (welt : blow)",
      options: [
        "fall : height",
        "stain : spill.",
        "throw : fly",
    ],
    },

    {
      id: 46,
      question: "Identify the relationship between the word pair in the bracket ( evaporate : vapour)",
      options: [
        " petrify : stone.",
        " saturate : fluid",
        "corrode : acid",
    ],
    },

    {
      id: 47,
      question: "Identify the relationship between the word pair in the bracket (corrode : acid)",
      options: [
        "ocean : wave",
        "pod : dolphin.",
        "pack : cat",
    ],
    },

    {
      id: 48,
      question: "Identify the relationship between the word pair in the bracket ( anaesthetic : numb)",
      options: [
        " disease : drug",
        "sedative : drowsy. ",
        "Nigeri kumi in 1959.",
      ],
    },

    {
      id: 49,
      question: "Identify the relationship between the word pair in the bracket (deplete : decrease)",
      options: [
        "danger : evade",
        "like : detest",
        "shun : avoid.",
    ],
    },
 
    {
      id: 50,
      question: "Medicine is to illness as Law is to -------",
      options: [
        "express",
        "attend",
        " anarchy.",
    ],
    },

    {
      id: 51,
      question: "square is to cube as circle is to ------",
      options: [
        "round",
        "ball",
        "sphere.",
    ],
    },

    {
      id: 52,
      question: " king is to throne as judge is to -------",
      options: [
        "lawyer",
        "court",
        "bench",
    ],
    },

    {
      id: 53,
      question: "Nib is to pen as lens is to -------",
      options: [
        "seeing",
        "glass",
        "telescope.",
    ],
    },

    {
      id: 54,
      question: "Settlement is to injury as pension is to -------",
      options: [
        "aphorism",
        "retirement.",
        "pensioner",
    ],
    },

    {
      id: 55,
      question: " Which word means - symbol; metaphor",
      options: [
        "aphorism",
        "adage",
        "allegory.",
    ],
    },

    {
      id: 56,
      question:
        "Which word means - fear of foreigners or strangers",
      options: [
        " chauvinism",
        "xenophobia.",
        "magnanimity",
    ],
    },

    {
      id: 57,
      question:
        "Which word means - nobility; fairness; generosity",
      options: [
        "abstinence",
        "magnanimity.",
        "affluence",
      ],
    },

    {
      id: 58,
      question: "Which word means - shackle; hindrance",
      options: [
        "fetter.",
        "parody",
        "chauvinism",
    ],
    },

    {
      id: 59,
      question:
        "Which word means - official paper; deed; certificate",
      options: [
        "document.",
        "action",
        "closure",
    ],
    },

    {
      id: 60,
      question: "Which word is closely opposite to heed",
      options: [
        "ignore.",
        "attend",
        "converse",
    ],
    },
  ],
  [
    {
      id: 1,
      question: "Identify the missing number at the end of the series  100, 96, 91, 85, ?",
      options: ["74", "78.", "56",],
    },

    {
      id: 2,
      question:
        " Identify the missing number at the end of the series 11, 16, 26, 41, ?",
      options: ["35", "61.", "43",],
    },

    {
      id: 3,
      question: "Identify the missing number at the end of the series.5, 12, 19, 26, ?",
      options: ["43", "33.", "67"],
    },

    {
      id: 4,
      question: "Othman bought N4,000 of company stock. She sold 75% of it when the value doubled, and the remainder at four times the purchase price. What was her total profit?",
      options: ["N4,087", "N6,000.", "N5,789"],
    },

    {
      id: 5,
      question: " Components X,Y and Z are ordered in the ratio 1:5:4. How many Z components will be in an order for 8000 components?",
      options: ["2,890", "4000", "3,200."],
    },

    {
      id: 6,
      question: "It costs a manufacturer X Naira per component to make the first 500components. All subsequent components cost X÷5 each. When X = N4.50 How much will it cost to manufacture 4,000 components?",
      options: ["7,600", "N5,400.", "N9000"],
    },

    {
      id: 7,
      question: "Identify the missing number.( 2 6 44 8 10  15 19 ? 21 23)",
      options: ["34  ", "61", "57."],
    },

    {
      id: 8,
      question: "  Identify the missing number in (3 17 6 14 10 5 5 25 ? 7)",
      options: ["16", "22", "13."],
    },

    {
      id: 9,
      question:
        "Evaluate  1.7 × 1.5 = ?",
      options: ["2.55.", "1.28", "23.6"],
    },

    {
      id: 10,
      question: "evaluate  0.8 × 0.25 = ?",
      options: [
        "1.8",
        "0.2  .",
        "2.09",
    ],
    },

    {
      id: 11,
       question: "Identify the missing number at the end of the series  100, 96, 91, 85, ?",
      options: ["74", "78.", "56",],
    },

    {
      id: 12,
     question:
        " Identify the missing number at the end of the series 11, 16, 26, 41, ?",
      options: ["35", "61.", "43",],
    },

    {
      id: 13,
     question: "Identify the missing number at the end of the series.5, 12, 19, 26, ?",
      options: ["43", "33.", "67"],
    },

    {
      id: 14,
      question: "Othman bought N4,000 of company stock. She sold 75% of it when the value doubled, and the remainder at four times the purchase price. What was her total profit?",
      options: ["N4,087", "N6,000.", "N5,789"],
    },

    {
      id: 15,
     question: " Components X,Y and Z are ordered in the ratio 1:5:4. How many Z components will be in an order for 8000 components?",
      options: ["2,890", "4000", "3,200."],
    },

    {
      id: 16,
      question: "It costs a manufacturer X Naira per component to make the first 500components. All subsequent components cost X÷5 each. When X = N4.50 How much will it cost to manufacture 4,000 components?",
      options: ["7,600", "N5,400.", "N9000"],
    },

    {
      id: 17,
       question: "Identify the missing number.( 2 6 44 8 10  15 19 ? 21 23)",
      options: ["34  ", "61", "57."],
    },

    {
      id: 18,
     question: "  Identify the missing number in (3 17 6 14 10 5 5 25 ? 7)",
      options: ["16", "22", "13."],
    },

    {
      id: 19,
     question:
        "Evaluate  1.7 × 1.5 = ?",
      options: ["2.55.", "1.28", "23.6"],
    },

    {
      id: 20,
       question: "evaluate  0.8 × 0.25 = ?",
      options: [
        "1.8",
        "0.2  .",
        "2.09",
    ],
    },

    {
      id: 21,
       question: "Identify the missing number at the end of the series  100, 96, 91, 85, ?",
      options: ["74", "78.", "56",],
    },

    {
      id: 22,
      question:
        " Identify the missing number at the end of the series 11, 16, 26, 41, ?",
      options: ["35", "61.", "43",],
    },
    {
      id: 23,
     question: "Identify the missing number at the end of the series.5, 12, 19, 26, ?",
      options: ["43", "33.", "67"],
    },

    {
      id: 24,
      question: "Othman bought N4,000 of company stock. She sold 75% of it when the value doubled, and the remainder at four times the purchase price. What was her total profit?",
      options: ["N4,087", "N6,000.", "N5,789"],
    },
    {
      id: 25,
    question: " Components X,Y and Z are ordered in the ratio 1:5:4. How many Z components will be in an order for 8000 components?",
      options: ["2,890", "4000", "3,200."],
    },

    {
      id: 26,
      question: "It costs a manufacturer X Naira per component to make the first 500components. All subsequent components cost X÷5 each. When X = N4.50 How much will it cost to manufacture 4,000 components?",
      options: ["7,600", "N5,400.", "N9000"],
    },

    {
      id: 27,
      question: "Identify the missing number.( 2 6 44 8 10  15 19 ? 21 23)",
      options: ["34  ", "61", "57."],
    },

    {
      id: 28,
     question: "  Identify the missing number in (3 17 6 14 10 5 5 25 ? 7)",
      options: ["16", "22", "13."],
    },

    {
      id: 29,
     question:
        "Evaluate  1.7 × 1.5 = ?",
      options: ["2.55.", "1.28", "23.6"],
    },

    {
      id: 30,
      question: "evaluate  0.8 × 0.25 = ?",
      options: [
        "1.8",
        "0.2  .",
        "2.09",
    ],
    },

    {
      id: 31,
      question: "Identify the missing number at the end of the series  100, 96, 91, 85, ?",
      options: ["74", "78.", "56",],
    },

    {
      id: 32,
      question:
        " Identify the missing number at the end of the series 11, 16, 26, 41, ?",
      options: ["35", "61.", "43",],
    },

    {
      id: 33,
      question: "Identify the missing number at the end of the series.5, 12, 19, 26, ?",
      options: ["43", "33.", "67"],
    },

    {
      id: 34,
      question: "Othman bought N4,000 of company stock. She sold 75% of it when the value doubled, and the remainder at four times the purchase price. What was her total profit?",
      options: ["N4,087", "N6,000.", "N5,789"],
    },
    {
      id: 35,
      question: " Components X,Y and Z are ordered in the ratio 1:5:4. How many Z components will be in an order for 8000 components?",
      options: ["2,890", "4000", "3,200."],
    },
    {
      id: 36,
       question: "It costs a manufacturer X Naira per component to make the first 500components. All subsequent components cost X÷5 each. When X = N4.50 How much will it cost to manufacture 4,000 components?",
      options: ["7,600", "N5,400.", "N9000"],
    },

    {
      id: 37,
       question: "Identify the missing number.( 2 6 44 8 10  15 19 ? 21 23)",
      options: ["34  ", "61", "57."],
    },

    {
      id: 38,
     question: "  Identify the missing number in (3 17 6 14 10 5 5 25 ? 7)",
      options: ["16", "22", "13."],
    },

    {
      id: 39,
     question:
        "Evaluate  1.7 × 1.5 = ?",
      options: ["2.55.", "1.28", "23.6"],
    },

    {
      id: 40,
       question: "evaluate  0.8 × 0.25 = ?",
      options: [
        "1.8",
        "0.2  .",
        "2.09",
    ],
    },

    {
      id:41,
      question: "Identify the missing number at the end of the series  100, 96, 91, 85, ?",
      options: ["74", "78.", "56",],
    },

    {
      id: 42,
     question:
        " Identify the missing number at the end of the series 11, 16, 26, 41, ?",
      options: ["35", "61.", "43",],
    },

    {
      id: 43,
      question: "Identify the missing number at the end of the series.5, 12, 19, 26, ?",
      options: ["43", "33.", "67"],
    },

    {
      id: 44,
       question: "Othman bought N4,000 of company stock. She sold 75% of it when the value doubled, and the remainder at four times the purchase price. What was her total profit?",
      options: ["N4,087", "N6,000.", "N5,789"],
    },

    {
      id: 45,
      question: " Components X,Y and Z are ordered in the ratio 1:5:4. How many Z components will be in an order for 8000 components?",
      options: ["2,890", "4000", "3,200."],
    },

    {
      id: 46,
       question: "It costs a manufacturer X Naira per component to make the first 500components. All subsequent components cost X÷5 each. When X = N4.50 How much will it cost to manufacture 4,000 components?",
      options: ["7,600", "N5,400.", "N9000"],
    },

    {
      id: 47,
      question: "Identify the missing number.( 2 6 44 8 10  15 19 ? 21 23)",
      options: ["34  ", "61", "57."],
    },

    {
      id: 48,
      question: "  Identify the missing number in (3 17 6 14 10 5 5 25 ? 7)",
      options: ["16", "22", "13."],
    },

    {
      id: 49,
      question:
        "Evaluate  1.7 × 1.5 = ?",
      options: ["2.55.", "1.28", "23.6"],
    },

    {
      id: 50,
       question: "evaluate  0.8 × 0.25 = ?",
      options: [
        "1.8",
        "0.2  .",
        "2.09",
    ],
    },

    {
      id:51,
      question: "Identify the missing number at the end of the series  100, 96, 91, 85, ?",
      options: ["74", "78.", "56",],
    },

    {
      id: 52,
      question:
        " Identify the missing number at the end of the series 11, 16, 26, 41, ?",
      options: ["35", "61.", "43",],
    },
    {
      id: 53,
      question: "Identify the missing number at the end of the series.5, 12, 19, 26, ?",
      options: ["43", "33.", "67"],
    },

    {
      id: 54,
       question: "Othman bought N4,000 of company stock. She sold 75% of it when the value doubled, and the remainder at four times the purchase price. What was her total profit?",
      options: ["N4,087", "N6,000.", "N5,789"],
    },
    {
      id: 55,
      question: " Components X,Y and Z are ordered in the ratio 1:5:4. How many Z components will be in an order for 8000 components?",
      options: ["2,890", "4000", "3,200."],
    },

    {
      id: 56,
      question: "It costs a manufacturer X Naira per component to make the first 500components. All subsequent components cost X÷5 each. When X = N4.50 How much will it cost to manufacture 4,000 components?",
      options: ["7,600", "N5,400.", "N9000"],
    },

    {
      id: 57,
       question: "Identify the missing number.( 2 6 44 8 10  15 19 ? 21 23)",
      options: ["34  ", "61", "57."],
    },

    {
      id: 58,
      question: "  Identify the missing number in (3 17 6 14 10 5 5 25 ? 7)",
      options: ["16", "22", "13."],
    },

    {
      id: 59,
     question:
        "Evaluate  1.7 × 1.5 = ?",
      options: ["2.55.", "1.28", "23.6"],
    },

    {
      id: 60,
       question: "evaluate  0.8 × 0.25 = ?",
      options: [
        "1.8",
        "0.2  .",
        "2.09",
    ],
    }, 
  ],
  [
    {
      id: 1,
      question: " The first Inspector General of the Police Force was ",
      options: [" Teslim Balogun ", " Louis Edet ", " Ademola Adetokunbo ",],
    },

    {
      id: 2,
      question:
        " In order to qualify for the post of President in Nigeria, the person must be _____ and above ",
      options: ["35 years", "20 years", "40 years",],
    },

    {
      id: 3,
      question: " The name Nigeria coined out of _______",
      options: ["Niger Forest", "Niger Area", "Niger River"],
    },

    {
      id: 4,
      question: " The last colonial Governor-General of Nigeria was ",
      options: ["Tulma Goldie", "James Robertson", "Huge Clifford"],
    },

    {
      id: 5,
      question: " The First World War was between",
      options: ["1911-1914", "1914-1916", "1914-1918"],
    },

    {
      id: 6,
      question: " The first African country to introduce e-passport was",
      options: ["South Africa", "Ghana", "Nigeria"],
    },

    {
      id: 7,
      question: " The first secretary General of commonwealth was",
      options: ["George Washington", "Tulma Goldie", "Joseph Garba"],
    },

    {
      id: 8,
      question: " The first military president of Nigeria was",
      options: ["Sanni Abacha", "Ibrahim Babangida", "Yakubu Gowon"],
    },

    {
      id: 9,
      question:
        " Which of the following may be regarded as a regional organization",
      options: ["ECOWAS", "OAU", "UN"],
    },

    {
      id: 10,
      question: " What is the full meaning of NPF?",
      options: [
        "Nigeria Police Force",
        "Niger Police Force",
        "Nigeria Police ",
    ],
    },

    {
      id: 11,
      question: " What is the Nigeria police force motto?",
      options: [
        " The police is your friend.",
        " The police is your friend.",
        " The police is your friend.",
    ],
    },

    {
      id: 12,
      question: " When was Nigeria Police Force(NPF) established?",
      options: [
        "1930",
        "1930",
        "1930",
    ],
    },

    {
      id: 13,
      question: " Who is the present Chief of Defence Staff?",
      options: [
        "Major Gen. Abayomi Gabriel Olonisakin",
        "Major Gen. Abayomi Gabriel Olonisakin",
        "Major Gen. Abayomi Gabriel Olonisakin",
    ],
    },

    {
      id: 14,
      question: " What is the full Meaning of IGP?",
      options: [
        "Inspector General of Police",
        "Inspector General of Police",
        "Inspector General of Police",
    ],
    },

    {
      id: 15,
      question: " What is the name of Nigeria Police Force IGP?",
      options: [
        "IGP Kayode Egbetokun",
        "IGP Kayode Egbetokun",
        "IGP Kayode Egbetokun",
    ],
    },

    {
      id: 16,
      question: " When did Nigeria got her independence?",
      options: [
        "1 October 1960",
        "1 October 1960",
        "1 October 1960",
    ],
    },

    {
      id: 17,
      question:
        " Who is the current Speaker of Nigeria House of Representatives?",
      options: [
        " Tajudeen Abbas",
        " Tajudeen Abbas",
        " Tajudeen Abbas",
    ],
    },

    {
      id: 18,
      question:
        " Which year was the Nigerian flag designed and who designed it?",
      options: [
        "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
        "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
        "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
      ],
    },

    {
      id: 19,
      question: " Who is the first Executive president of Nigeria?",
      options: [
        "Alh. Shehu Shagari.",
        "Alh. Shehu Shagari.",
        "Alh. Shehu Shagari.",
    ],
    },

    {
      id: 20,
      question:
        " Name the government agencies that supervise the affairs of Nigeria police force?",
      options: [
        "PSC,  Ministry of police affairs and NPC",
        "PSC,  Ministry of police affairs and NPC",
        "PSC,  Ministry of police affairs and NPC",
    ],
    },

    {
      id: 21,
      question: "Name the 7 area Commands of Nigeria police force?",
      options: [
        "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
        "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
        "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
    ],
    },

    {
      id: 22,
      question: " When did Nigeria become a Republic?",
      options: [
        "1 October 1963.",
        "1 October 1963.",
        "1 October 1963.",
    ],
    },
    {
      id: 23,
      question: " The first secretary General of common wealth was",
      options: [
        "George Washington",
        "Tulma Goldie",
        "Arnold smith",
     
      ],
    },

    {
      id: 24,
      question:
        "A formal accusation by a grand jury that charges a person with a crime is known as……",
      options: [
   
        " Insanity plea  ",
        "Indictment  ",
        "   Faux Pas.",
      ],
    },
    {
      id: 25,
      question:
        "The police officer-in-charge of contingents in a state is the…",
      options: [
        "Chief Superintendent of Police",
    
        "Commissioner of Police",
        "Divisional Police officer",
      ],
    },

    {
      id: 26,
      question:
        "Which of the following constabularies did not participate in the formation of the Nigerian police?",
      options: [
        "Niger Coast Constabulary",
        "Special Service Constabulary",
        "Royal Niger Company Constabulary",
     
      ],
    },

    {
      id: 27,
      question:
        "List of officers fit for appointment to the office of the Inspector General of Police is compiled by the",
      options: [
        "Police Council",
        "Police Corporative",
     
        "Police Authority",
      ],
    },

    {
      id: 28,
      question: "Katsina Ala is in which state of Nigeria?",

      options: [
        "Cross Rivers State",
        "Benue State",
      
        "Kogi State",
      ],
    },

    {
      id: 29,
      question: "What is the length of tenure of the IGP?",
      options: ["3yrs", "4yrs", "5yrs"],
    },

    {
      id: 30,
      question:
        "Which of these is not part of the formation list of the Nigerian police?",
      options: [
        "Port Authority Police",
        "Police Cooperative",
        
        "Airforce",
      ],
    },

    {
      id: 31,
      question: "How many units does the Nigerian police have?",
      options: [ " 5", " 6", " 7"],
    },

    {
      id: 32,
      question:
        "The position to the office of the Inspector General of police is done through",
      options: [
    
        " Nomination",
        " Appointment",
        " Academic Qualification",
      ],
    },

    {
      id: 33,
      question: "Find the odd one out",
      options: [
        "Hafiz Ringim",
        "Abubakar Zikman",
        "Suleiman Abba",
   
      ],
    },

    {
      id: 34,
      question: "'Who is the first inspector general of police in Nigeria?",
      options: [
        "Kam Salem",
        "Philip Effiong",
        "Muhammadu Dikko Yusufu",
 
      ],
    },
    {
      id: 35,
      question: "cool The first military president of Nigeria was",
      options: [
        " Sanni Abacha",
        " Ibrahim Babangida",
       
        " Yakubu Gowon.",
      ],
    },
    {
      id: 36,
      question: " The first African country to introduce e-passport was",
      options: ["South Africa", "Ghana", "Nigeria"],
    },

    {
      id: 37,
      question: " The first secretary General of commonwealth was",
      options: ["George Washington", "Tulma Goldie", "Joseph Garba"],
    },

    {
      id: 38,
      question: " The first military president of Nigeria was",
      options: ["Sanni Abacha", "Ibrahim Babangida", "Yakubu Gowon"],
    },

    {
      id: 39,
      question:
        " Which of the following may be regarded as a regional organization",
      options: ["ECOWAS", "OAU", "UN"],
    },

    {
      id: 40,
      question: " What is the full meaning of NPF?",
      options: [
        "Nigeria Police Force",
        "Niger Police Force",
        "Nigeria Police ",
    ],
    },

    {
      id:41,
      question: " What is the Nigeria police force motto?",
      options: [
        " The police is your friend.",
        " The police is your friend.",
        " The police is your friend.",
    ],
    },

    {
      id: 42,
      question: " When was Nigeria Police Force(NPF) established?",
      options: [
        "1930",
        "1930",
        "1930",
    ],
    },

    {
      id: 43,
      question: " Who is the present Chief of Defence Staff?",
      options: [
        "Major Gen. Abayomi Gabriel Olonisakin",
        "Major Gen. Abayomi Gabriel Olonisakin",
        "Major Gen. Abayomi Gabriel Olonisakin",
    ],
    },

    {
      id: 44,
      question: " What is the full Meaning of IGP?",
      options: [
        "Inspector General of Police",
        "Inspector General of Police",
        "Inspector General of Police",
    ],
    },

    {
      id: 45,
      question: " What is the name of Nigeria Police Force IGP?",
      options: [
        "IGP Kayode Egbetokun",
        "IGP Kayode Egbetokun",
        "IGP Kayode Egbetokun",
    ],
    },

    {
      id: 46,
      question: " When did Nigeria got her independence?",
      options: [
        "1 October 1960",
        "1 October 1960",
        "1 October 1960",
    ],
    },

    {
      id: 47,
      question:
        " Who is the current Speaker of Nigeria House of Representatives?",
      options: [
        " Tajudeen Abbas",
        " Tajudeen Abbas",
        " Tajudeen Abbas",
    ],
    },

    {
      id: 48,
      question:
        " Which year was the Nigerian flag designed and who designed it?",
      options: [
        "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
        "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
        "Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.",
      ],
    },

    {
      id: 49,
      question: " Who is the first Executive president of Nigeria?",
      options: [
        "Alh. Shehu Shagari.",
        "Alh. Shehu Shagari.",
        "Alh. Shehu Shagari.",
    ],
    },

    {
      id: 50,
      question:
        " Name the government agencies that supervise the affairs of Nigeria police force?",
      options: [
        "PSC,  Ministry of police affairs and NPC",
        "PSC,  Ministry of police affairs and NPC",
        "PSC,  Ministry of police affairs and NPC",
    ],
    },

    {
      id:51,
      question: "Name the 7 area Commands of Nigeria police force?",
      options: [
        "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
        "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
        "(Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun",
    ],
    },

    {
      id: 52,
      question: " When did Nigeria become a Republic?",
      options: [
        "1 October 1963.",
        "1 October 1963.",
        "1 October 1963.",
    ],
    },
    {
      id: 53,
      question: " The first secretary General of common wealth was",
      options: [
        "George Washington",
        "Tulma Goldie",
        "Arnold smith",
     
      ],
    },

    {
      id: 54,
      question:
        "A formal accusation by a grand jury that charges a person with a crime is known as……",
      options: [
   
        " Insanity plea  ",
        "Indictment  ",
        "   Faux Pas.",
      ],
    },
    {
      id: 55,
      question:
        "The police officer-in-charge of contingents in a state is the…",
      options: [
        "Chief Superintendent of Police",
    
        "Commissioner of Police",
        "Divisional Police officer",
      ],
    },

    {
      id: 56,
      question:
        "Which of the following constabularies did not participate in the formation of the Nigerian police?",
      options: [
        "Niger Coast Constabulary",
        "Special Service Constabulary",
        "Royal Niger Company Constabulary",
     
      ],
    },

    {
      id: 57,
      question:
        "List of officers fit for appointment to the office of the Inspector General of Police is compiled by the",
      options: [
        "Police Council",
        "Police Corporative",
     
        "Police Authority",
      ],
    },

    {
      id: 58,
      question: "Katsina Ala is in which state of Nigeria?",

      options: [
        "Cross Rivers State",
        "Benue State",
      
        "Kogi State",
      ],
    },

    {
      id: 59,
      question: "What is the length of tenure of the IGP?",
      options: ["3yrs", "4yrs", "5yrs"],
    },

    {
      id: 60,
      question:
        "Which of these is not part of the formation list of the Nigerian police?",
      options: [
        "Port Authority Police",
        "Police Cooperative",
        
        "Airforce",
      ],
    }, 
  ],
];



// export const data =  [
//      [
//         {
//         id: 1,
//         question : "POLICE DATA is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 2,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 3,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 4,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 5,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 6,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 7,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 8,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 3,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 10,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 11,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 12,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 13,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 14,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 15,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 16,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 17,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 18,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 19,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 20,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 21,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 22,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 23,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 24,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 25,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 26,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 27,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 28,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 29,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 30,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 31,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 32,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 33,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 34,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 35,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 36,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 37,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 38,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 39,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 40,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 41,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 42,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 43,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 44,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 45,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 46,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 47,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 48,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 49,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 50,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 51,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 52,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 53,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 54,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 55,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 56,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 57,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 58,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },

//     {
//         id: 59,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 60,
//         question : "Which function is used to serialize an object into a JSON string?",
//         options : [
//             'stringify()',
//             'parse()',
//             'convert()',
//         ]
//     }],

//      [
//         {
//         id: 1,
//         question : "Mathematics is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 2,
//         question : "Following Mathematics methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 3,
//         question : "When an operator value is NULL, the Mathematics returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 4,
//         question : "What does the Mathematics) method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 5,
//         question : "Mathematics method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 6,
//         question : "What does Mathematics?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 7,
//         question : "Mathematics is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 8,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 3,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 10,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 11,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 12,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 13,
//         question : "Mathematics is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 14,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 15,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 16,
//         question : "What Mathematicsmethod return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 17,
//         question : "What does the Mathematics  method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 18,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 19,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 20,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 21,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 22,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 23,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 24,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 25,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 26,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 27,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 28,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 29,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 30,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 31,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 32,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 33,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 34,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 35,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 36,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 37,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 38,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 39,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 40,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 41,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 42,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 43,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 44,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 45,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 46,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 47,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 48,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 49,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 50,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 51,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 52,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 53,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 54,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 55,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 56,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 57,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 58,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },

//     {
//         id: 59,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 60,
//         question : "Which function is used to serialize an object into a JSON string?",
//         options : [
//             'stringify()',
//             'parse()',
//             'convert()',
//         ]
//     }],

//      [
//         {
//         id: 1,
//         question : "Current Affairs is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 2,
//         question : "Following methods can be used to Current Affairs data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 3,
//         question : "When an operator value is NULL,Current Affairs  the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 4,
//         question : "What does the Current Affairs() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 5,
//         question : "What does the Current Affairs() method return?",
//         options : [
//             'Return Object',
//             'sfadfafd String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 6,
//         question : "What does the Current Affairs() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 7,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 8,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 3,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 10,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 11,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 12,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 13,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 14,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 15,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 16,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 17,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 18,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 19,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 20,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 21,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 22,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 23,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 24,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 25,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 26,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 27,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 28,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 29,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 30,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 31,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 32,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 33,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 34,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 35,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 36,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 37,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 38,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 39,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 40,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 41,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 42,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 43,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 44,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 45,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 46,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 47,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 48,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 49,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 50,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 51,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 52,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 53,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 54,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//         {
//         id: 55,
//         question : "English is an _______ language",
//         options : [
//             'Object-Oriented',
//             'Object-Based',
//             'Procedural',
//         ]
//     },
//     {
//         id: 56,
//         question : "Following methods can be used to display data in some form using Javascript",
//         options : [
//             'document.write()',
//             'console.log()',
//             'window.alert()',
//         ]
//     },
//     {
//         id: 57,
//         question : "When an operator value is NULL, the typeof returned by the unary operator is:",
//         options : [
//             'Boolean',
//             'Undefined',
//             'Object',
//         ]
//     },
//     {
//         id: 58,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },

//     {
//         id: 59,
//         question : "What does the toString() method return?",
//         options : [
//             'Return Object',
//             'Return String',
//             'Return Integer'
//         ]
//     },
//     {
//         id: 60,
//         question : "Which function is used to serialize an object into a JSON string?",
//         options : [
//             'stringify()',
//             'parse()',
//             'convert()',
//         ]
//     }],

// ];

export const answers = [
    0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1,
    2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2,
    1, 0, 0, 1, 2, 2, 1, 0,
  ];
  export const answersX = [
    0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1,
    2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2,
    1, 0, 0, 1, 2, 2, 1, 0,
  ];
  export const answersY = [
    0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1,
    2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2, 1, 0, 0, 1, 2, 2,
    1, 0, 0, 1, 2, 2, 1, 0,
  ];
  
  // [
  //     {
  //         id: 1,
  //         question : " The first Inspector General of the Police Force was " ,
  //         options : [ ' Teslim Balogun ',
  //   ' Louis Edet ',
  //   ' Ademola Adetokunbo ',
  //   ' Mike Okiro ',
  // ,
  //         ]
  //     },
  
  // {
  //         id: 2,
  //         question : " In order to qualify for the post of President in Nigeria, the person must be _____ and above " ,
  //         options : ['35 years',
  // '20 years',
  // '40 years',
  // '55 years',
  // ,
  //         ]
  //     },
  
  // {
  //         id: 3,
  //         question : " The name Nigeria coined out of _______" ,
  //         options : ['Niger Forest',
  // 'Niger Area',
  // 'Niger River',
  // 'Niger Textures',
  //         ]
  //     },
  
  // {
  //         id: 4,
  //         question : " The last colonial Governor-General of Nigeria was " ,
  //         options : ['Tulma Goldie',
  // 'James Robertson',
  // 'Huge Clifford',
  // 'Lord Lugard',
  //         ]
  //     },
  
  // {
  //         id: 5,
  //         question : " The First World War was between" , options : ['1911-1914',
  // '1914-1916',
  // '1916-1918',
  // '1914-1918'
  // ,
  //         ]
  //     },
  
  // {
  //         id: 6,
  //         question : " The first African country to introduce e-passport was" ,
  //          options : ['South Africa',
  // 'Ghana',
  // 'Liberia',
  // 'Nigeria',
  //         ]
  //     },
  
  // {
  //         id: 7,
  //         question : " The first secretary General of commonwealth was" , options : ['George Washington',
  // 'Tulma Goldie',
  // 'Arnold smith',
  // 'Joseph Garba',
  //         ]
  //     },
  
  // {
  //         id: 8,
  //         question : " The first military president of Nigeria was" , options : ['Sanni Abacha',
  // 'Ibrahim Babangida',
  // 'Aguiyi Ironsi',
  // 'Yakubu Gowon'
  // ,
  //         ]
  //     },
  
  // {
  //         id: 9,
  //         question : " Which of the following may be regarded as a regional organization" , options : ['ECOWAS',
  // 'OAU',
  // 'UN',
  // 'OPEC',
  //         ]
  //     },
  
  // {
  //         id: 10,
  //         question : " What is the full meaning of NPF?" ,
  //         options : ['ANSWER: Nigeria Police Force',
  //         ]
  //     },
  
  // {
  //         id:11,
  //         question : " What is the Nigeria police force motto?" , options : ['ANSWER: The police is your friend.',
  //         ]
  //     },
  
  // {
  //         id: 12,
  //         question : " When was Nigeria Police Force(NPF) established?" ,
  //          options : ['ANSWER: 1930',
  //         ]
  //     },
  
  // {
  //         id:13,
  //         question : " Who is the present Chief of Defence Staff?" ,
  //         options : ['ANSWER: Major Gen. Abayomi Gabriel Olonisakin',
  //         ]
  //     },
  
  // {
  //         id:14,
  //         question : " What is the full Meaning of IGP?" ,
  //         options : ['ANSWER: Inspector General of Police',
  //         ]
  //     },
  
  // {
  //         id: 15,
  //         question : " What is the name of Nigeria Police Force IGP?" ,
  //         options : ['ANSWER: IGP Kayode Egbetokun',
  //         ]
  //     },
  
  // {
  //         id: 16,
  //         question : " When did Nigeria got her independence?" ,
  //         options : ['ANSWER: 1 October 1960',
  //         ]
  //     },
  
  // {
  //         id: 17,
  //         question : " Who is the current Speaker of Nigeria House of Representatives?" ,
  //         options : ['ANSWER:  Tajudeen Abbas'] ,
  //     },
  
  // {
  //         id: 18,
  //         question : " Which year was the Nigerian flag designed and who designed it?" ,
  //          options : [
  //             'ANSWER: Nigeria flag was designed by Mr. Taiwo Akinkumi in 1959.',
  //         ]
  //     },
  
  // {
  //         id: 19,
  //         question : " Who is the first Executive president of Nigeria?" ,
  //          options : ['ANSWER: Alh. Shehu Shagari.',
  //         ]
  //     },
  
  // {
  //         id: 20,
  //         question : " Name the government agencies that supervise the affairs of Nigeria police force?" ,
  //          options : ['ANSWER: PSC,  Ministry of police affairs and NPC',
  //         ]
  //     },
  
  // {
  //         id: 21,
  //         question : "Name the 7 area Commands of Nigeria police force?" ,
  //          options : ['ANSWER: (Adamawa, Gombe, Jigawa, Kano, Katsina, Lagos, Ogun'
  //         ]
  //     },
  
  // {
  //         id: 22,
  //         question : " When did Nigeria become a Republic?" ,
  //          options : ['ANSWER: 1 October 1963.',
  //         ]
  //     },
  // {
  //         id: 23,
  //         question : " The first secretary General of common wealth was" ,
  //          options : [
  //             'George Washington',
  //             'Tulma Goldie',
  //             'Arnold smith',
  //             'Joseph Garba.',
  //         ]
  //     },
  
  // {
  //         id: 24,
  //         question : "A formal accusation by a grand jury that charges a person with a crime is known as……" ,
  //          options : [
  //             'Indemnity',
  //             ' Insanity plea  ',
  //             'Indictment  ',
  //             '   Faux Pas.',
  //         ]
  //     },
  //     {
  //         id: 25,
  //         question : "The police officer-in-charge of contingents in a state is the…"
  // ,  options : [
  // 'Chief Superintendent of Police',
  // 'Inspector of Police',
  // 'Commissioner of Police',
  // 'Divisional Police officer',
  //         ]
  //     },
  
  // {
  //         id: 26,
  //         question : "Which of the following constabularies did not participate in the formation of the Nigerian police?"
  // , options : [
  // 'Niger Coast Constabulary',
  // 'Special Service Constabulary',
  // 'Royal Niger Company Constabulary',
  // 'Thirty members consular guard',
  //         ]
  //     },
  
  // {
  //         id: 27,
  //         question : "List of officers fit for appointment to the office of the Inspector General of Police is compiled by the"
  //        , options : [
  // 'Police Council',
  // 'Police Corporative',
  // 'Police Academy',
  // 'Police Authority']
  // },
  
  // {
  //         id: 28,
  //         question : "Katsina Ala is in which state of Nigeria?",
  
  //         options : [
  // 'Cross Rivers State',
  // 'Benue State',
  // 'Katsina State',
  // 'Kogi State',
  // ]
  // },
  
  // {
  //         id: 29,
  //         question : "What is the length of tenure of the IGP?",
  //         options : [
  //         '3yrs',
  //         '4yrs',
  //         '5yrs',
  //         '6yrs',
  //         ]
  //     },
  
  // {
  //         id: 30,
  //         question : "Which of these is not part of the formation list of the Nigerian police?"
  //        , options : [
  // 'Port Authority Police',
  // 'Police Cooperative',
  // 'Police Academy Kano',
  // 'Airforce',
  //         ]
  //     },
  
  // {
  //         id: 31,
  //         question : "How many units does the Nigerian police have?"
  //         , options : [
  // ' 4',
  // ' 5',
  // ' 6',
  // ' 7',
  //         ]
  //     },
  
  // {
  //         id: 32,
  //         question : "The position to the office of the Inspector General of police is done through"
  //         , options : [
  // ' Election',
  // ' Nomination',
  // ' Appointment',
  // ' Academic Qualification',
  //         ]
  //     },
  
  // {
  //         id: 33,
  //         question : "Find the odd one out",
  //         options : [
  // 'Hafiz Ringim',
  // 'Abubakar Zikman',
  // 'Suleiman Abba',
  // 'Mike Okiro',
  //         ]
  //     },
  
  //     {
  //         id: 34,
  //         question : "'Who is the first inspector general of police in Nigeria?"
  // ,  options : ['Kam Salem',
  // 'Philip Effiong',
  // 'Muhammadu Dikko Yusufu',
  // 'Louis Edet',
  //         ]
  //     },
  //     {
  //         id: 35,
  //         question : "cool The first military president of Nigeria was",
  //         options : [
  // ' Sanni Abacha',
  // ' Ibrahim Babangida',
  // ' Aguiyi Ironsi',
  // ' Yakubu Gowon.',
  //         ]
  //     },
  
  // ]
  