import { Link } from "react-router-dom";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

// icons 
import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import { fontSize } from "@mui/system";

function SocialMediaIcons() {
  return (
    <figure className="footerMediaIcons flexcenterbn">
      <Link>
        <TwitterIcon style={{ fontSize: "2.1rem" }} />
      </Link>
      <Link>
        <FacebookIcon style={{ fontSize: "2.1rem" }} />
      </Link>
      <Link>
        <LinkedInIcon style={{ fontSize: "2.1rem" }} />
      </Link>
      <Link>
        <InstagramIcon style={{ fontSize: "2.1rem" }} />
      </Link>
      <Link>
        <WhatsAppIcon style={{ fontSize: "2.1rem" }} />
      </Link>
      <Link>
        <TelegramIcon style={{ fontSize: "2.1rem" }} />
      </Link>
    </figure>
  );
}
 
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="footerStart">
        <div className="headingFooter">
          <h2>Jools</h2>
          <Link to="mng_login_category">
            <button className="langbtn" type="lang">
              <SupervisorAccountIcon style={{ fontSize: "3rem" }} /> Admin
            </button>
          </Link>
        </div>
        <div className="footerItems">
          <ul>
          
            <li>
              <Link to="/help">Guidelines</Link>
            </li>
            <li>
              <Link to="/contact_company" >Contact Us</Link>
            </li>
            <li>
              <Link >Terms of Service</Link>
            </li>
            <li>
              <Link to="/aboutcompany">About company</Link>
            </li>
            <li>
              <Link to="/report_problem">Report a problem</Link>
            </li>
          </ul>
          <ul>
             
            <li>
              <Link to="/coming_soon">Career</Link>
            </li>
            <li>
              <Link  to="/coming_soon">Press & Media</Link>
            </li>
            <li>
              <Link  to="/coming_soon">About affiliation</Link>
            </li>
            <li>
              <Link  to="/coming_soon">Affiliation terms</Link>
            </li>
          </ul>
          <ul>
             
            <li>
              <Link  to="/coming_soon">Categories</Link>
            </li>
            <li>
              <Link  to="/coming_soon">Pricing</Link>
            </li>
            <li>
              <Link  to="/coming_soon">Activation</Link>
            </li>
            <li>
              <Link  to="/coming_soon">Cookie</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footerMiddle">
        <p className="subscribe">Subscribe to our news letter</p>
        <form action="#" name="footerForm" className="flex">
          <input
            type="footerInput"
            name="subscribe"
            placeholder="Email address"
          />
          <button type="footerSubmit">Subscribe</button>
        </form> 
        <SocialMediaIcons /> 
      </div>
      <div className="footerEnd">
        <div className="copyRight">
          <p>Copyright &copy; {currentYear} Joods all rights reserved.</p>
          <ul className="listEnd">
            <li>
              <Link to="/coming_soon">Terms of services</Link>
            </li>
            <li>
              <Link to="/coming_soon">Privacy policy</Link>
            </li>
            <li>
              <Link to="/coming_soon">Cookie setting</Link>
            </li>
            <li>
              <Link to="/coming_soon">Affiliation Terms of services</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;