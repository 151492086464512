

import LogoJamb from "./home_pictures/logos/jamb.png"
import LogoNeco from "./home_pictures/logos/neco.png"
import LogoWaec from "./home_pictures/logos/waec.png"
import LogoArmy from "./home_pictures/logos/army.jpg"
import LogoNavy from "./home_pictures/logos/navy.png"
import LogoAirforce from "./home_pictures/logos/airforce.png"
import LogoPolice from "./home_pictures/logos/police.png"

export default function HomeHeader() {
    return(
        <section className="sectoinHomeheader fx-cl space4">
        {/* <div style={{height: "12rem"}}>&nbsp;</div> */}
            <div className="hHcont fx-cl fx-ac space1">
                <h2>Traditional examination is over. Welcome to the world of Tech.</h2>
                {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure, fugit. Consequuntur, maxime.</p> */}
                <p>We Get you well ready for the future information technology, in Computer Base Test and Examinations  </p>
                <figure className="fx-ac space5 hHLogo">
                    <span><img  src={LogoArmy} alt="army logo" /></span>
                    <span><img src={LogoNavy} alt="navy logo" /></span>
                    <span><img src={LogoAirforce} alt="airforce logo" /></span>
                    <span><img src={LogoPolice} alt="police logo" /></span>
                    <span><img  src={LogoJamb} alt="army logo" /></span>
                    <span><img  src={LogoNeco} alt="army logo" /></span>
                    <span><img  src={LogoWaec} alt="army logo" /></span> 
                </figure>
            </div>

        </section>
        )
}