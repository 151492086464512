import { Link } from "react-router-dom";

import JambLogo from "./home_pictures/jamb.png"
import Facebook from "@mui/icons-material/Facebook";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
export default function CTAComp() {
  return (
    <section className="sectionCTA">
      <div className="fc-ac  ctaOne g g2">
        <div className="ctaDisc fx-cl space2">
          <span className="ctaSubHead">Nigerian Armed Forces</span>
          <h3>
            This is how <br />
            <span className="ctaHeading" style={{ color: "#b3b3d9" }}>
              good applicants <br />
              find good way.
            </span>
          </h3>
       
          <p>
            use the computer base test software that includes mostly asked questions in the 
            screening examinations as you go through.
          </p>
          <div className="ctaList fx-cl space1">
            <figure className="fx-ac space1">
              <LanguageOutlinedIcon style={{ color: "#b3b3d9", fontSize: "2.4rem" }} />
              <span>Signup with <Link to="/client_reg_form" style={{color: "#fff", fontFamily: "serif" }}>Jood Software Tech.</Link> with few click</span>
            </figure>
            <figure className="fx-ac space1">
              <TaskAltOutlinedIcon style={{ color: "#b3b3d9", fontSize: "2.4rem" }} />
              <span>Select the forces you need in profile and active them</span>
            </figure>
            <figure className="fx-ac space1">
              <LiveTvOutlinedIcon style={{ color: "#b3b3d9", fontSize: "2.4rem" }} />
              <span>
                Now start taking your cbt training with the best experience
              </span>
            </figure>
          </div>
          <div style={{paddingTop: "1rem"}}>
            <Link to="/client_reg_form" className="ctaBtn">Start the training</Link>
          </div>
        </div>
        <div className="ctaImg"> </div>
      </div>
    </section>
  );
}
export  function CTAComp2() {
  return (
    <section className="sectionCTA2" id="jamb">
      <div className="fc-ac ctaCont">
        <div className="ctaImg" style={{position: "relative"}}> 
        <div style={{width: "12rem", position: "absolute", bottom: "-2rem", left: "0rem"}}>
          <img src={JambLogo} alt="img" />
        </div>
        </div>
        <div className="ctaDisc fx-cl space1" style={{position: "relative"}} >
        <div style={{width: "12rem", position: "absolute", top: "0rem", right: "0rem"}}>
          <img src={JambLogo} alt="img" />
        </div>
          <span className="ctaSubHead">UTME JAMB</span>
          <h3 style={{lineHeight: "1.2"}}>
            The Gateway <br />
            <span style={{ color: "#023a26" }}>
              to the Tertiarry <br />
              Institutions.
            </span>
          </h3>
       
          <p>
            Joint Admissoin and Matriculation Board CBT Training software, Get prepared for the 
            2024 JAMB and DE now
          </p>
           
          <div style={{paddingTop: "1.5rem"}}>
            <Link to="/client_reg_form" className="ctaBtn">Start the training</Link>
          </div>
        </div>
      </div>
    </section>
  );
}
