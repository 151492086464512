import React from "react"; 
import {Routes, Route, useNavigate, Navigate} from "react-router-dom"; 
import CompanyRoutes from "./CompanyRoutes";
import { Authentication } from "./Authentication.jsx";

export default function SiteRoutes() {
    const redirect = useNavigate()
    const {
        management,
        administration,
        client

        

    } = Authentication();

    // Mapping on the routing elements
    return (
        <Routes>
            {CompanyRoutes.map((req, res) => {
                if (req.isPublic) {
                    return <Route path={req.path} element={req.element} key={res}/>
                } else if (req.isManagement && management.isAuthenticated) {
                    return <Route path={req.path} element={req.element} key={res}/>
                } else if (req.isAdministration && administration.isAuthenticated) {
                    return <Route path={req.path} element={req.element} key={res}/>
                } else if (req.isClient && client.isAuthenticated) {
                    return <Route path={req.path} element={req.element} key={res}/> 
                } else  return false
            })} 
        </Routes>
    )
}

 