import React, { useEffect, useReducer, useState } from "react";
import axios from "axios";
import { Provider } from 'react-redux';
import store from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../../../../../../Loading";

import { useSnackbar } from "notistack";
 
import { Authentication } from "../../../../../../../Authentication";
 
// import actions
import {resetAllAction} from '../redux/question_reducer'
import {resetResultAction} from '../redux/result_reducer'
import { attemps_Number, earnPoints_Number,flagResult } from "../helper/helper";
import { usePublishResult } from "../hooks/setResult";
import { earnPoints_NumberY } from "../../army/helper/helper";


 function Resultcomp(){
  const {enqueueSnackbar} = useSnackbar()
  const [loading, setLoding] = useState(false)
  const redirect = useNavigate();
  const  {queue,answers} = useSelector(state => state.questions)   
  const  {result} = useSelector(state => state.result)  
  const  {queueX,answersX} = useSelector(state => state.questions)   
  const  {resultX, } = useSelector(state => state.result)  
  const  {queueY,answersY} = useSelector(state => state.questions)   
  const  {resultY, } = useSelector(state => state.result)   
  


  const totalPoints = Math.round(queue.length * 1.67)
  const attempts = attemps_Number(result)
  const earnPoints=earnPoints_Number(result,answers,Math.round(1.67))
  // for checking pass or fail
  const flag = flagResult(totalPoints, earnPoints)
 

  const totalPointsY = Math.round(queueY.length * 1.67)
  const attemptsY = attemps_Number(resultY)
  const earnPointsY=earnPoints_Number(resultY,answersY,Math.round(1.67))
  // for checking pass or fail
  const flagY = flagResult(totalPointsY, earnPoints_Number)

  const totalPointsX = Math.round(queueX.length * 1.67)
  const attemptsX = attemps_Number(resultX)
  const earnPointsX=earnPoints_Number(resultX,answersX,Math.round(1.67))
  // for checking pass or fail
  const flagX = flagResult(totalPointsX, earnPoints)



  const [results, setResults] = useReducer((existed, incoming)=>{return {...existed, ...incoming}},
  { 
    jsonCate: "Nigerian Army Computer Base Test",
    jsonscore: earnPoints + earnPointsX +earnPointsY,
    jsonpoint: earnPoints,
    jsonattemps:attempts,
    jsonpointX: earnPointsX,
    jsonattempsX:attemptsX,
    jsonpointY: earnPointsY,
    jsonattempsY: attemptsY,
  })

const {client} = Authentication()
let id = client.bioData._id


  function Result(){
    const dispatch = useDispatch()



    async function onSubmitResult() {
      setLoding(true)
      await axios.post(`https://api-jood-cbt.onrender.com/api/v1/jood_routes/cbt_exams/submt_result/${id}`, results)
      .then(response => {
        resSet();
        redirect("/cbt")
        setLoding(false)
        enqueueSnackbar("Results saved successfully", {variant: "success"}); 
      }).catch(err => console.log(err))
     }

     function resSet(){
      dispatch(resetAllAction())
      dispatch(resetResultAction())
    }

     

 

    // store user result 
    usePublishResult({result,attempts,points : earnPoints,achived : flag ? "Passed" : "Failed"})
  
    // useEffect(()=>{
    //   setResults({jsonpoint: earnPoints, jsonattemps: attempts})
    // },[])
  
    return(
      <div className="quizeCont">
        <div className="result fx-cl space2">
           <h2 className="fx-ac fx-jc" >ENGLISH</h2>
          <div className="fx-ac fx-jb">
            <span>Total Marks: </span>
            <span className="bold">{totalPoints || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Total Questions : </span>
            <span className="bold">{ queue.length || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Total Attempts</span>
            <span className="bold">{attempts || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Total Earn Points : </span>
            <span className="bold">{earnPoints || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Course Result</span>
            <span style={{color : `${flag ? "#2aff95" : "#ff2a66" }`}} className="bold">{flag ? "Passed" :"Failed"}</span>
          </div>
        </div> 
  
        <div>
          <button style={{backgroundColor: "yellowGreen", zIndex: "999999"}} 
        onClick={()=> onSubmitResult()}
        >Save Result</button>
        </div>
      </div> 
    )
  }

  function ResultX(){    
   

    // store user result 
  usePublishResult({resultX ,attemptsX,points : earnPointsX,achived : flagX ? "Passed" : "Failed"})
  
  const dispatch = useDispatch()
    
    // useEffect(()=>{
    //   setResults({
    //     jsonpointX: earnPoints,
    //     jsonattempsX: attempts,
    //   })
    // },[])
  
  
    return(
      <div className="quizeCont">

        <div className="result fx-cl space2">
          <h2 className="fx-ac fx-jc" >MATHEMATICS</h2>
          <div className="fx-ac fx-jb">
            <span>Total Marks : </span>
            <span className="bold">{totalPointsX || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Total Questions : </span>
            <span className="bold">{ queueX.length || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Total Attempts</span>
            <span className="bold">{attemptsX || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Total Earn Points : </span>
            <span className="bold">{earnPointsX || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Result</span>
            <span style={{color : `${flagX ? "#2aff95" : "#ff2a66" }`}} className="bold">{flag ? "Passed" :"Failed"}</span>
          </div>
        </div>
  
       
        
      </div> 
    )
  }
  function ResultY(){
    const dispatch = useDispatch()
    
    // useEffect(()=>{
    //   setResults({
    //     jsonpointY: earnPoints,
    //     jsonattempsY: attempts,
    //   })
    // },[])



    // store user result 
  usePublishResult({resultY ,attemptsY,points : earnPointsY,achived : flagY ? "Passed" : "Failed"})
  
  
  
    return(
      <div className="quizeCont">

        <div className="result fx-cl space2">
          <h2 className="fx-ac fx-jc" >CURRENT AFFAIRS</h2>
          <div className="fx-ac fx-jb">
            <span>Total Marks : </span>
            <span className="bold">{totalPointsY || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Total Questions : </span>
            <span className="bold">{ queueY.length || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Total Attempts</span>
            <span className="bold">{attemptsY || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>Total Earn Points : </span>
            <span className="bold">{earnPointsY || 0}</span>
          </div>
          <div className="fx-ac fx-jb">
            <span>  Result</span>
            <span style={{color : `${flagY ? "#2aff95" : "#ff2a66" }`}} className="bold">{flag ? "Passed" :"Failed"}</span>
          </div>
        </div>
  
     
        
      </div> 
    )
  }

  
  return(
    <Provider store={store}> 
    {loading ? <Loading />: null}
    <div className="resultsCont fx-ac fx-jc">
      <div className="contR g g3">
      <Result/>
      <ResultX/>
      <ResultY/>
      </div>
    </div>
    </Provider>
  )
  
}


export default function Results() {
  return(
    <Provider store={store}>
    <Resultcomp /> 
    </Provider>
  )
}