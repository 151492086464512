import { createSlice } from "@reduxjs/toolkit"   
// createing reducer with intial value
export const questionReducer = createSlice({
    name : 'questions',
    initialState : {
        current_subj: 0,
        submitting: false,
        // store questions and answers
        queue :[],
        queueX :"not found",
        queueY :[],
        queueZ :[],
        answers : [],
        answersX : [],
        answersY : [],
        answersZ : [],
      
        // trace is used to track user questions if user click next it increase trace value to 1
        trace : 0, 
        traceX : 0, 
        traceY : 0, 
        traceZ : 0, 
    },
    // reducers allows you to specfiy and despatch an action and action allows you to change the value of store 
    reducers : {
        // using the action you can acces the user input 
        startExamAction : (state, action) => {
            let {question, answers, answersX, answersY, answersZ} = action.payload; 
            console.log(answers)
            return{
                // update intial state
                ...state,
                current_subj: 9999,
                queue : question.subjectOne[0].queues,
                queueX : question.subjectTwo[0].queues,
                queueY : question.subjectThree[0].queues,
                queueZ : question.subjectFour[0].queues,
                answers : answers,
                answersX : answersX,
                answersY : answersY,
                answersZ : answersZ,
    
            }
        },
        movePaginationAction : (state, action) =>{
            let {e} = action.payload
            console.log(e)
            return{
                ...state,
                trace : e-1
            }
        },
        movePaginationActionX : (state, action) =>{
            let {e} = action.payload
            console.log(e)
            return{
                ...state,
                traceX : e-1
            }
        },
        movePaginationActionY : (state, action) =>{
            let {e} = action.payload
            console.log(e)
            return{
                ...state,
                traceY : e-1
            }
        },
        movePaginationActionZ : (state, action) =>{
            let {e} = action.payload
            console.log(e)
            return{
                ...state,
                traceZ : e-1
            }
        },
        moveNextAction : (state) =>{
            return{
                ...state,
                trace : state.trace +1
            }
        },
        moveNextActionX : (state) =>{
            return{
                ...state,
                traceX : state.traceX +1
            }
        },
        moveNextActionY : (state) =>{
            return{
                ...state,
                traceY : state.traceY + 1
            }
        },
        moveNextActionZ : (state) =>{
            return{
                ...state,
                traceZ : state.traceZ + 1
            }
        },
        movePrevAction : (state) =>{
            return{
                ...state,
                trace : state.trace - 1
            }
        },
        movePrevActionX : (state) =>{
            return{
                ...state,
                traceX : state.traceX - 1
            }
        },
        movePrevActionY : (state) =>{
            return{
                ...state,
                traceY : state.traceY - 1
            }
        },
        movePrevActionZ : (state) =>{
            return{
                ...state,
                traceZ : state.traceZ - 1
            }
        },
        resetAllAction : () =>{
            return{
                queue :[],
                queueX :[],
                queueY :[],
                queueZ :[],
                answers : [],
                answersX : [],
                answersY : [],
                answersZ : [],
                trace : 0, 
                traceX : 0, 
                traceY : 0, 
                traceZ : 0, 
            } 
        } 

    }
})

export const {
    startExamAction,
     moveNextAction,
     movePrevAction,
     moveNextActionX,
     movePrevActionX,
     moveNextActionY,
     movePrevActionY,
     moveNextActionZ,
     movePrevActionZ,
     resetAllAction,
     movePaginationAction,
     movePaginationActionX,
     movePaginationActionY,
     movePaginationActionZ,
    } = questionReducer.actions;

export default questionReducer.reducer;