import { useReducer, useState } from "react";
import { Authentication } from "../../Authentication.jsx"
import { Link, useNavigate } from "react-router-dom";
import './public_routes_css/login.css'

// importing logo
import Logo from "./final-dash-logo.png"
// importing icons
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default function AdminLogin () {
    const currentYear = new Date().getFullYear();
    const {loginAdmin} = Authentication();
    const navigates = useNavigate();
    const [error, setError] = useState(null);
    const [adminLoginData, setAdminLoginData] = useReducer((reqest, response) => { return {...reqest, ...response}}, {userName: "", password: ""})
 
    const executeLogin = async () => {
        try {
            await loginAdmin(adminLoginData.userName, adminLoginData.password)
            navigates("/admin_profile")
        } catch (err) {
            setError(err)
        }

    }


    return(
        <section className="sectionLogin "> 
        <div className="loginCont">
            <div className="loginDiscription flexcentercn">
                <figure>
                    <img src={Logo} alt="netclass" />
                </figure>
            </div>
    <div className="loginformCont"> 
    <h2>Let's continue admin staff</h2> 
        <div className="loginForm">
        <h3>welcome back, login</h3>
        <div className="loginInputWrap">
        <Person2OutlinedIcon style={{fontSize: "2.4rem", color: "#999"}} />
        <input name="jsonUsername" value={adminLoginData.userName} onChange={event => setAdminLoginData({userName: event.target.value})} type="text" placeholder="enter username"/>
        </div>
        <div className="loginInputWrap">
        <LockOutlinedIcon  style={{fontSize: "2.4rem", color: "#999"}}/>
        <input name="jsonPassword" value={adminLoginData.password} onChange={event => setAdminLoginData({password: event.target.value})} type="password" placeholder="enter password"/>
        </div> 
        <div> 
        <button  onClick={executeLogin}>Login</button> 
        {error ? <div style={{color:'red'}}>{error} </div>: <div>&nbsp;</div> } 
        </div>
        <span>By signing up, you agree to the Terms of Service and Privacy Policy, including Cookie Use.</span>
        
        </div>
        </div>
    
    </div>
    <div>
        <ul className="flexcenterbn" style={{gap: "2.4rem", padding: "1.2rem 1.8rem"}} >
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li>
            <li>
                <Link>footer link</Link>
            </li> <br /> 
        </ul>
        <p style={{textAlign: "center", paddingBottom: "1rem"}}>Copyright © {currentYear} Joods all rights reserved.</p>
    </div>
        </section> 
    )
}