import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import axios from 'axios'
// import {data} from "../redux/data.js"
import {data, answers } from "../redux/data"


export function attemps_Number(result){
    return result.filter(r => r !== undefined).length
}
export function attemps_NumberX(resultX){
    return resultX.filter(r => r !== undefined).length
}
export function attemps_NumberY(resultY){
    return resultY.filter(r => r !== undefined).length
}

export function earnPoints_Number(result,answers,point){
    return result.map((element, i) => answers[i] === element).filter(i => i).map(i => point).reduce((prev, curr) => prev + curr, 0);
}
export function earnPoints_NumberX(resultX,answersX,point){
    return resultX.map((element, i) => answersX[i] === element).filter(i => i).map(i => point).reduce((prev, curr) => prev + curr, 0);
}
export function earnPoints_NumberY(resultY,answersY,point){
    return resultY.map((element, i) => answersY[i] === element).filter(i => i).map(i => point).reduce((prev, curr) => prev + curr, 0);
}
export function flagResult(totalPoints, earnPoints){
    return (totalPoints * 50 / 100) < earnPoints; /** earn 50% marks */
}
export function flagResultX(totalPoints, earnPoints){
    return (totalPoints * 50 / 100) < earnPoints; /** earn 50% marks */
}
export function flagResultY(totalPoints, earnPoints){
    return (totalPoints * 50 / 100) < earnPoints; /** earn 50% marks */
}

// check user authentication
export function CheckUserExist({children}){
    const auth =useSelector(state => state.result.userId)
    return auth ? children : <Navigate to={'/'} replace={true}></Navigate>
}

// get server data
// export async function getServerData(url, callback){
// const data = await(await axios.get(url))?.data
// return callback ? callback(data) :data 
// }
export async function getServerData(url, callback){
// const data = await(await axios.get(url))?.data
return callback ? callback(data) :data 
}

// post request 
export async function postServerData(url,result, callback){
// const data = await(await axios.post(url,result))?.data
return callback ? callback(data) : data 
}
