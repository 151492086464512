export const englishExamsEntry = [
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "*July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "*Student of computer"}, 
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: "*Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
         
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "*yes sir"},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "*Police man"},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "*July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "*Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "*Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "*Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "*July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "What is the meaning of JOOD??",
        option: [
            {list: "a", contents: "July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
    {
        question: "--- is the meaning of JOOD??",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
]
export const mathematicsExamsEntry = [
    
 
  
    {
        question: "this is the Mathematics subjects contents",
        option: [
            {list: "a", contents: " Sokoto"},
            {list: "b", contents: " Nigeria"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    {
        question: "dis you know thwe the lorem ipsum is teh programming language",
        option: [
            {list: "a", contents: " no"},
            {list: "b", contents: " yes no"},
            {list: "c", contents: "yes sir "},
            {list: "d", contents: "All of the  yes"},
        ] 
       
    },
    {
        question: "The Umar is one of the follownig",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
]
export const currentAffairsExamsEntry = [
    {
        question: "Nigerian curernt affairs staffulll",
        answer: "c",
        option: [
            {list: "a", contents: "July Othman"},
            {list: "b", contents: " Othman"},
            {list: "c", contents: "Omar Othman"},
            {list: "d", contents: "All of the  Othman"},
        ] 
       
    },
    
    {
        question: "The current affairs is one of the follownig",
        answer: "a",
        option: [
            {list: "a", contents: " Programmer"},
            {list: "b", contents: " Mai Shayi"},
            {list: "c", contents: "Police man  "},
            {list: "d", contents: "Student of computer"},
        ] 
       
    },
]


